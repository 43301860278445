import {
  ConfiguratorState,
  StepInfo,
  StepItemInfoList,
  StepOpenedInfo,
  Steps,
} from "../@types/configurator";
import {
  PayloadAction,
  createAction,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import {
  defaultStepInfoList,
  defaultStepItemInfoOverview,
} from "../data/configurator";
import { ProductData } from "../@types/product";
import { store } from "./store";
import { updateStepInfoList } from "../utils/stepConfigurator";

const initialState: ConfiguratorState = {
  openTexturesModal: false,
  openFilterLocationModal: false,
  showOverviewScreen: false,
  stepInfoList: defaultStepInfoList,
  stepItemInfoOverview: defaultStepItemInfoOverview,
  openStep: null,
  openProductInfoModal: null,
};
export const resetConfigurator = createAction("REVERT_ALL");

const configuratorSlice = createSlice({
  name: "configurator",
  initialState,
  reducers: {
    setOpenTexturesModal: (state, action: PayloadAction<boolean>) => {
      state.openTexturesModal = action.payload;
    },
    setOpenFilterLocationModal: (state, action: PayloadAction<boolean>) => {
      state.openFilterLocationModal = action.payload;
    },
    setShowOverviewScreen: (state, action: PayloadAction<boolean>) => {
      state.showOverviewScreen = action.payload;
    },
    setStepInfoList: (state, action: PayloadAction<StepInfo[]>) => {
      state.stepInfoList = action.payload;
    },
    setStepItemInfoOverview: (
      state,
      action: PayloadAction<StepItemInfoList>
    ) => {
      state.stepItemInfoOverview = action.payload;
    },
    setOpenStep: (state, action: PayloadAction<Steps | null>) => {
      state.openStep = action.payload;
    },
    setOpenProductInfoModal: (
      state,
      action: PayloadAction<ProductData | null>
    ) => {
      state.openProductInfoModal = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(resetConfigurator, () => initialState),
});

export const {
  setOpenTexturesModal,
  setOpenFilterLocationModal,
  setShowOverviewScreen,
  setStepInfoList,
  setStepItemInfoOverview,
  setOpenStep,
  setOpenProductInfoModal,
} = configuratorSlice.actions;

export default configuratorSlice.reducer;
