import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../components/organisms/Header";
import Footer from "../components/organisms/Footer";
import landing_animation from "../images/landing-coral-gif.gif";
import { AllTextObject } from "../@types/language";
import AquariumDrawing, {
  AquariumChangeRef,
} from "../components/Aquariumdrawing";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import ConfiguratorHeader from "../components/organisms/configurator/ConfiguratorHeader";
import TextInput from "../components/molecules/TextInput";
import OverviewActionButton from "../components/molecules/OverviewActionButton";
import { ArrowLeft } from "@phosphor-icons/react";

import ConfiguratorMenuFooter from "../components/molecules/ConfiguratorMenuFooter";
import { setShowOverviewScreen } from "../redux/configuratorSlice";
import { DeliveryOption } from "../@types/aquarium";
import OptionMenu from "../components/molecules/OptionMenu";
import { countryOptions, deliveryOptions } from "../data/aquarium";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/types.cjs";
import "react-phone-number-input/style.css";
import RadioSelectionMenu from "../components/molecules/RadioSelectionMenu";
import OverviewTable from "../components/molecules/OverviewTable";
// require("dotenv").config();
import axios from "axios";
import TextArea from "../components/molecules/TextArea";
import {
  GradientSubTitle,
  SmallText,
} from "../components/atoms/StyledComponents";
import { setUserFirstname } from "../redux/user/userSlice";

type Props = { allText: AllTextObject };

function OverviewScreen({ allText }: Props) {
  const user = useSelector((state: RootState) => state.user);
  const showOverviewScreen = useSelector(
    (state: RootState) => state.configurator.showOverviewScreen
  );
  const aquariumChangeRef = useRef<AquariumChangeRef>(null);
  const dispatch = useDispatch();
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>(user.email || "");
  const [phone, setPhone] = useState<E164Number>();
  const [postalcode, setPostalcode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [houseNumber, setHouseNumber] = useState<string>("");
  const [additionalAdressInfo, setAdditionalAdressInfo] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [delivery, setDelivery] = useState<DeliveryOption | null>(
    aquarium.delivery
  );
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [clientRemarks, setClientRemarks] = useState<string>("");

  const [offerteSend, setOfferteSend] = useState<boolean>(false);

  const getDrawingWidth = () => {
    const innerWidth = window.innerWidth;
    const itemWidth = document
      .getElementById("overview-content-container")
      ?.getBoundingClientRect().width;
    const containerWidth = itemWidth ? itemWidth : 1200;
    const containerRatio = (containerWidth * 0.48) / innerWidth;
    return containerRatio;
  };

  const handleSendQuote = () => {
    dispatch(setUserFirstname(firstname));
    //TODO DAAN: hier de offerte verzenden naar het emailadres en opslaan in de database
  };
  const handlePlaceOrder = () => {
    dispatch(setUserFirstname(firstname));
    //TODO DAAN: hier de bestelbon verzenden naar het emailadres en opslaan in de database
    // ook molly hier starten
  };
  const handleCloseOverview = () => {
    dispatch(setShowOverviewScreen(false));
  };
  const getButtonsDisabled = () => {
    if (
      !firstname ||
      !lastname ||
      !email ||
      !phone ||
      !postalcode ||
      !city ||
      !street ||
      !houseNumber ||
      !country ||
      !delivery
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (street && houseNumber && postalcode && city && country) {
      calculateDistance();
    }
  }, [street, houseNumber, postalcode, city, country]);

  const calculateDistance = async () => {
    // hier een call doen naar onze eigen rest api
    // deze code hierbeneden geeft cors fouten, via een eigen rest api lossen we deze op
    // const apiKey = process.env.GOOGLE_API_KEY;
    // const origin = "Europalaan 9, 3910 Pelt, België";
    // const destination = `${street} ${houseNumber}, ${postalcode} ${city}, ${country}`;
    // const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${apiKey}`;
    // try {
    //   const response = await axios.get(url);
    //   const result = response.data;
    //   console.log(result);
    //   const distanceInMeters = result.rows[0].elements[0].distance.value;
    // } catch (error) {
    //   console.error("Error fetching data: ", error);
    // }
  };

  return (
    <section
      className="landing-banner-container overview-container"
      style={showOverviewScreen ? { display: "flex" } : { display: "none" }}
    >
      <div className="landing-banner-animation-container overview-animation-container">
        <figure>
          <img src={landing_animation} alt="coral animation" />
        </figure>
      </div>
      <div
        className="overview-content-container"
        id="overview-content-container"
      >
        <div
          className="overview-goback-arrow"
          onClick={() => handleCloseOverview()}
        >
          <ArrowLeft color="var(--primary-700)" size={32} weight="bold" />
        </div>
        <div className="overview-container-item">
          {/* {drawingWidth && (
            <AquariumDrawing
              ref={aquariumChangeRef}
              showButtons={false}
              drawingWidth={drawingWidth}
              lowHeight={true}
            />
          )} */}
          <section>
            <OverviewTable />
          </section>
        </div>
        <div className="overview-form-container overview-container-item">
          <ConfiguratorHeader
            text="Vul uw gegevens aan om verder te gaan"
            title="uw droomaquarium is klaar!"
          />
          <form action="" className="overview-personal-data-form">
            <div className="overview-form-row-1-3">
              <TextInput
                title="voornaam"
                value={firstname}
                setValue={setFirstname}
                column
                color="var(--primary-200)"
                required={true}
              />
            </div>
            <div className="overview-form-row-4-3">
              <TextInput
                title="achternaam"
                value={lastname}
                setValue={setLastname}
                column
                color="var(--primary-200)"
                required={true}
              />
            </div>
            <div className="overview-form-row-1-3">
              <TextInput
                title="email"
                value={email}
                setValue={setEmail}
                column
                color="var(--primary-200)"
                required={true}
              />
            </div>
            <div className="overview-form-row-4-3">
              <div
                className="text-input-container"
                style={{
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <label
                  htmlFor={"phonenumber-input"}
                  className="text-input-label"
                  style={{ color: "var(--primary-200)" }}
                >
                  Telefoonnummer*
                </label>
                <PhoneInputWithCountrySelect
                  defaultCountry="BE"
                  placeholder="0470 12 34 56"
                  value={phone}
                  onChange={setPhone}
                />
              </div>
            </div>
            <div className="overview-form-row-1-2">
              <TextInput
                title="postcode"
                value={postalcode}
                setValue={setPostalcode}
                column
                color="var(--primary-200)"
                required={true}
              />
            </div>
            <div className="overview-form-row-3-4">
              <TextInput
                title="stad/gemeente"
                value={city}
                setValue={setCity}
                column
                color="var(--primary-200)"
                required={true}
              />
            </div>
            <div className="overview-form-row-1-3">
              <TextInput
                title="straatnaam"
                value={street}
                setValue={setStreet}
                column
                color="var(--primary-200)"
                required={true}
              />
            </div>
            <div className="overview-form-row-3-1">
              <TextInput
                title="huisnummer"
                value={houseNumber}
                setValue={setHouseNumber}
                column
                color="var(--primary-200)"
                required={true}
              />
            </div>
            <div className="overview-form-row-4-2">
              <TextInput
                title="toev."
                value={additionalAdressInfo}
                setValue={setAdditionalAdressInfo}
                column
                color="var(--primary-200)"
              />
            </div>
            <div className="overview-form-row-1-3">
              <RadioSelectionMenu
                title="land*"
                value={country}
                setValue={setCountry}
                options={countryOptions}
                column
                color="var(--primary-200)"
              />
            </div>
            <div className="overview-delivery-container">
              <OptionMenu
                title="Levering en installatie*"
                value={delivery}
                setValue={setDelivery}
                options={deliveryOptions}
              />
            </div>
            <div className="overview-form-row-1-6 client-remarks-input">
              <TextArea
                title="Opmerkingen"
                value={clientRemarks}
                setValue={setClientRemarks}
                column
                color="var(--neutral-100)"
              />
            </div>
          </form>
          <div style={{ minHeight: "25px" }}></div>
          <div className="overview-buttons-container">
            {!offerteSend ? (
              <OverviewActionButton
                action={handleSendQuote}
                text="Verstuur offerte"
                subtext="Krijg een offerte toegestuurd in je mailbox"
                color="var(--ocean-gradient-500)"
                disabled={getButtonsDisabled()}
              />
            ) : (
              <div className="overview-offerte-send-message-container">
                <GradientSubTitle
                  color="var(--neutral-gradient-100)"
                  size="var(--font-sm)"
                >
                  Offerte verzonden
                </GradientSubTitle>
                <SmallText
                  className="configurator-overview-button-text"
                  color="var(--neutral-100)"
                >
                  De offerte is succesvul verstuurd naar uw inbox
                </SmallText>
              </div>
            )}
            <OverviewActionButton
              action={handlePlaceOrder}
              text="Plaats bestelling"
              subtext="Bestel en betaal jouw droomaquarium"
              color="var(--green-gradient-500)"
              disabled={getButtonsDisabled()}
            />
          </div>
          <ConfiguratorMenuFooter />
        </div>
      </div>
    </section>
  );
}

export default OverviewScreen;
