import React, { useEffect, useState } from "react";
import TextInput from "../../molecules/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setAgreedToConditions,
  setUserEmail as setUserEmailRedux,
} from "../../../redux/user/userSlice";
import * as EmailValidator from "email-validator";
import { SmallText } from "../../atoms/StyledComponents";
import PrimaryButton from "../../atoms/PrimaryButton";

type Props = {};

function UserDisclaimer({}: Props) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState<string | null>(user.email);
  const [validUserEmail, setValidUserEmail] = useState<boolean | null>(null);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);

  useEffect(() => {
    if (userEmail) {
      dispatch(setUserEmailRedux(userEmail));
    }
  }, [userEmail]);

  useEffect(() => {
    setUserEmail(user.email);
    setValidUserEmail(userEmail ? EmailValidator.validate(userEmail) : null);
    // setAgreedToTerms(user.agreedToConditions);
  }, [user]);

  const checkValidEmail = () => {
    setValidUserEmail(userEmail ? EmailValidator.validate(userEmail) : null);
    if (userEmail) {
      dispatch(setUserEmailRedux(userEmail));
    }
  };

  const [showScreen, setShowScreen] = useState<boolean>(true);
  const handleOpenConfigurator = () => {
    dispatch(setAgreedToConditions(agreedToTerms));
    setShowScreen(false);
  };

  return (
    <div
      className="user-disclaimer-container"
      style={{ display: showScreen ? "flex" : "none" }}
    >
      <div className="user-disclaimer-content-container">
        <TextInput
          title="Email"
          value={userEmail ? userEmail : ""}
          setValue={setUserEmail}
          onBlur={() => checkValidEmail()}
          showError={validUserEmail === false ? true : false}
          errorMessage="Dit emailadres is ongeldig"
        />
        <label
          htmlFor="user-agrees-to-terms-input"
          className={"window-paint-side-option user-agrees-to-terms-input"}
        >
          Je moet de algemene voorwaarden en de privacyverklaring accepteren
          vooraleer je verder kan gaan naar de volgende stap.{" "}
          <a
            href="https://www.aqualife-aquarium.com/algemene-voorwaarden"
            target="_blank"
          >
            Klik hier voor de algemene voorwaarden.
          </a>{" "}
          Dit is enkel een simmulatie voor de meest voorkomende aquariums.
          Aqualife bouwt alle aquariums op maat, dit wil zeggen dat we een
          aquarium kunnen bouwen die volledig aan je noden kan voldoen
          (hoekaquarium, deels geverfd,...). Neem hiervoor contact op met
          aqualife.
          <input
            checked={agreedToTerms}
            type="checkbox"
            id="user-agrees-to-terms-input"
            onChange={() => setAgreedToTerms(!agreedToTerms)}
          />
          <span className="checkmark"></span>
        </label>
        <PrimaryButton
          title="Start de configurator"
          action={() => handleOpenConfigurator()}
          disabled={!validUserEmail || !agreedToTerms ? true : false}
        />
      </div>
    </div>
  );
}

export default UserDisclaimer;
