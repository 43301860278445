import React, { useState } from "react";
import { UserRole } from "../../@types/user";
import { Text } from "../atoms/StyledComponents";
import TextArea from "./TextArea";
import TextInput from "./TextInput";
import RadioSelectionMenu from "./RadioSelectionMenu";
import { userRoleOptions } from "../../data/user";
import PrimaryButton from "../atoms/PrimaryButton";
import SecondaryButton from "../atoms/SecondaryButton";

type Props = {};

function AddUser({}: Props) {
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState<UserRole>();

  function handleSaveUser() {
    //TODO DAAN: sla hier de nieuwe user op in de database
    //Het json bestand ziet eruit zoals het hele SuperUser type
  }

  return (
    <form className="add-product-modal-container">
      <Text weight={700}>Voeg nieuwe gebruiker toe</Text>
      <div className="add-product-form-container">
        <div className="add-product-column">
          <TextInput
            title="voornaam"
            value={firstname}
            setValue={setFirstname}
            column
            color="var(--neutral-100)"
          />
          <TextInput
            title="achternaam"
            value={lastname}
            setValue={setLastname}
            column
            color="var(--neutral-100)"
          />
        </div>
        <div className="add-product-column">
          <TextInput
            title="email"
            value={email}
            setValue={setEmail}
            column
            color="var(--neutral-100)"
          />
          <TextInput
            title="wachtwoord"
            value={password}
            setValue={setPassword}
            column
            color="var(--neutral-100)"
          />
        </div>
        <div className="add-product-column">
          <TextInput
            title="bedrijf"
            value={company}
            setValue={setCompany}
            column
            color="var(--neutral-100)"
          />
          <RadioSelectionMenu
            title="rol"
            value={role}
            setValue={setRole}
            options={userRoleOptions}
            column
            color="var(--neutral-100)"
          />
          <div className="add-product-buttons-container">
            <SecondaryButton title="annuleren" action={() => {}} />
            <PrimaryButton title="opslaan" action={() => handleSaveUser} />
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddUser;
