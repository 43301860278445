import * as THREE from 'three';
import { makePlank, makeRectangle } from './helper';
import { BoreHole, ElectraRuimte, FilterLocation, FilterOptions } from '../../@types/aquarium';
import { changeBiologicalDepth, changeBiologicalHeight, changeBiologicalLocation, changeBiologicalWidth, makeBiologicalFilter } from './biologicalFilter';
import { changeBioLandingDepth, changeBioLandingHeight, changeBioLandingWidth, changeBioLocation, setCoverPanels, makeBioFilter, makeBioMarineConform, changeBioMarineConformAquariumWidth, changeBioMarineConformAquariumHeight, changeBioFilterHeightDiff, changeBioWidthOffset, changeBioFilterDepth, bioChangeWaterProofPlateHeight} from './bioFilter';
import { changeExternalDepth, changeExternalHeight, changeExternalLocation, changeExternalWidth, makeExternalFilter, makeLeftBore, makeRightBore } from './externalFilter';
import { changeOsmoseCompartiment, changeSumpFilterDepth, changeSumpFilterHeightDiff, changeSumpLandingDepth, changeSumpLandingHeight, changeSumpLandingWidth, changeSumpLocation, changeSumpMarineConformAquariumHeight, changeSumpMarineConformAquariumWidth, changeSumpWidthOffset, makeSumpFilter, makeSumpMarineConform, sumpChangeWaterProofPlateHeight} from './sumpFilter';
import { changeElectraRuimte } from './closetOptions';
import { FiltersToolPanelModule } from 'ag-grid-enterprise';

let filterPlanks: THREE.Mesh[][] = [[]];
let currFilterType: FilterOptions;

let currWidth: number;
let currHeight: number;
let currDepth: number;
let currLowerClosetHeight = 0;
let currFilterInAquariumHeight: number;
let filterMarineConform = false;
let currElectraSide = ElectraRuimte.none;
let currWaterProofPlateHeight = 0

export function makeFilter(scene: THREE.Scene, width: number, height: number, depth: number, waterproofPlateHeight: number, glassWidth: number, filterType: FilterOptions){
    currWidth = width;
    currHeight = height;
    currDepth = depth;
    if(currFilterType !== filterType){
        for(let j = 0; j < filterPlanks.length; j++){
            for(let i = filterPlanks[j].length-1; i >= 0; i--){
                scene.remove(filterPlanks[j][i])
                filterPlanks[j].splice(i)
            }
        }
        
    }
    switch(filterType){
        case(FilterOptions.bio):{
            if(!filterMarineConform){
                makeBioFilter(scene, width, height, currLowerClosetHeight, depth, waterproofPlateHeight, filterPlanks)
            }
            break;
        }
        case(FilterOptions.biological):{
            makeBiologicalFilter(scene, width, height, depth, filterPlanks)
            break;
        }
        case(FilterOptions.external):{
            makeExternalFilter(scene, width, height, depth, glassWidth, filterPlanks)
            break;
        }
        case(FilterOptions.sump):{
            makeSumpFilter(scene, width, height, currLowerClosetHeight, depth, waterproofPlateHeight, filterPlanks)
            break;
        }
    }
  currFilterType = filterType;
  currWaterProofPlateHeight = waterproofPlateHeight
  setElectraruimte(scene, currElectraSide);
}

export function setFilterLocation(
  scene: THREE.Scene,
  width: number,
  height: number,
  filterHeight: number,
  depth: number,
  glassWidth: number,
  filterLocation: FilterLocation
) {
  switch(currFilterType){
    case(FilterOptions.bio):{
        changeBioLocation(scene, width, height, filterHeight, depth, glassWidth, filterPlanks, filterLocation)
        break;
    }
    case(FilterOptions.biological):{
        changeBiologicalLocation(scene, width, height, filterHeight, depth, glassWidth, filterPlanks, filterLocation)
        break;
    }
    case(FilterOptions.external):{
        changeExternalLocation(scene, width, height, filterHeight, depth, glassWidth, filterPlanks, filterLocation)
        break;
    }
    case(FilterOptions.sump):{
        changeSumpLocation(scene, width, height, filterHeight, depth, glassWidth, filterPlanks, filterLocation)
        break;
    }
}
currFilterInAquariumHeight = height;
}

export function changeFilterWidth(scene: THREE.Scene, newWidth: number){
    switch(currFilterType){
        case(FilterOptions.bio):{
            changeBioLandingWidth(scene, newWidth-currWidth, filterPlanks);
            if(filterMarineConform){
                changeBioMarineConformAquariumWidth(currWidth, newWidth, filterPlanks)
            }else{
                makeBioFilter(scene, newWidth, currHeight, currLowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks);
            }
            break;
        }
        case(FilterOptions.biological):{
            changeBiologicalWidth(scene, newWidth-currWidth, filterPlanks)
            break;
        }
        case(FilterOptions.external):{
            changeExternalWidth(newWidth-currWidth, filterPlanks)
            break;
        }
        case(FilterOptions.sump):{
            changeSumpLandingWidth(scene, newWidth-currWidth, filterPlanks);
            if(filterMarineConform){
                changeSumpMarineConformAquariumWidth(currWidth, newWidth, filterPlanks);
            }else{
                makeSumpFilter(scene, newWidth, currHeight, currLowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks);
            }
            
            break;
        }
    }
    currWidth = newWidth;
    setElectraruimte(scene, currElectraSide)
}


export function changeFilterHeight(
  scene: THREE.Scene,
  newHeight: number,
  filterInAquariumHeight: number
) {
  switch (currFilterType) {
    case FilterOptions.bio: {
      changeBioLandingHeight(scene, currHeight, newHeight, filterPlanks);
      if (filterMarineConform) {
        changeBioMarineConformAquariumHeight(
          currHeight,
          newHeight,
          filterPlanks
        );
      } else {
        makeBioFilter(scene, currWidth, newHeight,currLowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks);
      }
      break;
    }
    case FilterOptions.biological: {
      changeBiologicalHeight(scene, currHeight, newHeight, filterPlanks);
      break;
    }
    case FilterOptions.external: {
      changeExternalHeight(scene, currHeight, newHeight, filterPlanks);
      break;
    }
    case FilterOptions.sump: {
      changeSumpLandingHeight(scene, currHeight, newHeight, filterPlanks);
      if (filterMarineConform) {
        changeSumpMarineConformAquariumHeight(
          currHeight,
          newHeight,
          filterPlanks
        );
      } else {
        makeSumpFilter(scene, currWidth, newHeight,currLowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks);
      }

      break;
    }
  }
  currHeight = newHeight;
  currFilterInAquariumHeight = filterInAquariumHeight;
}

export function changeFilterDepth(scene: THREE.Scene, newDepth: number) {
  switch (currFilterType) {
    case FilterOptions.bio: {
      changeBioLandingDepth(scene, newDepth - currDepth, filterPlanks);
      //makeBioFilter(scene, currWidth, currHeight, currDepth, filterPlanks)
      break;
    }
    case FilterOptions.biological: {
      changeBiologicalDepth(scene, newDepth - currDepth, filterPlanks);
      break;
    }
    case FilterOptions.external: {
      changeExternalDepth(newDepth - currDepth, filterPlanks);
      break;
    }
    case FilterOptions.sump: {
      changeSumpLandingDepth(scene, newDepth - currDepth, filterPlanks);
      break;
    }
  }
  currDepth = newDepth;
}

export function MakeBoreHole(
  scene: THREE.Scene,
  boreHole: BoreHole,
  glassWidth: number,
  drawHole: boolean,
) {
  if (currFilterType === FilterOptions.external) {
    if (boreHole === BoreHole.links) {
      makeLeftBore(scene, filterPlanks, drawHole);
    } else {
      makeRightBore(scene, filterPlanks, drawHole);
    }
  }
}

export function toggleCoverPanels(
  scene: THREE.Scene,
  drawBioCoverPanels: boolean
) {
  setCoverPanels(scene, filterPlanks, drawBioCoverPanels);
}

export function toggleOsmoseComp(
  scene: THREE.Scene,
  drawOsmoseCompartiment: boolean
) {
  changeOsmoseCompartiment(scene, filterPlanks, drawOsmoseCompartiment);
}

//marine conform variable means wether the filter should or should not be marineconform
//filterheight is where the lowest plank should be
export function makeFilterMarineConform(scene: THREE.Scene, marineConform: boolean, filterHeight: number){
    if(currFilterType === FilterOptions.bio){
        makeBioMarineConform(scene, marineConform,currWidth, currHeight, currLowerClosetHeight, filterHeight, currDepth, filterPlanks)
    }else if(currFilterType === FilterOptions.sump){
        makeSumpMarineConform(scene, marineConform, currWidth, currHeight, filterHeight, currDepth, filterPlanks)
    }
    filterMarineConform = marineConform
}

export function ChangeFilterHeightDiff(scene: THREE.Scene, filterHeight: number, lowerClosetHeight: number){
    if(!filterMarineConform){
        if(currFilterType === FilterOptions.bio){
            if(changeBioFilterHeightDiff(scene, filterHeight, lowerClosetHeight, filterPlanks)){
                makeBioFilter(scene, currWidth, currHeight, lowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks)
            }
        }else if(currFilterType === FilterOptions.sump){
            changeSumpFilterHeightDiff(scene, filterHeight, lowerClosetHeight, filterPlanks)
        }
    }
    
    currLowerClosetHeight = lowerClosetHeight
}

export function setElectraruimte(scene: THREE.Scene, side: ElectraRuimte){
    if(side === ElectraRuimte.none || side === ElectraRuimte.right){
        if(currFilterType === FilterOptions.bio){
            changeBioWidthOffset(0)
            makeBioFilter(scene, currWidth, currHeight, currLowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks)
        }else if(currFilterType === FilterOptions.sump){
            changeSumpWidthOffset(0)
            makeSumpFilter(scene, currWidth, currHeight, currLowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks)
        }
    }else{
        if(currFilterType === FilterOptions.bio){
            changeBioWidthOffset(currWidth)
            makeBioFilter(scene, currWidth, currHeight, currLowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks)
        }else if(currFilterType === FilterOptions.sump){
            changeSumpWidthOffset(currWidth)
            makeSumpFilter(scene, currWidth, currHeight, currLowerClosetHeight, currDepth, currWaterProofPlateHeight, filterPlanks)
        }
    }
  currElectraSide = side;
}


export function changeFilterWaterproofPlateHeight(newWaterProofPlateHeight: number){
  if(currFilterType === FilterOptions.bio){
    bioChangeWaterProofPlateHeight(newWaterProofPlateHeight, filterPlanks)
  }else if(currFilterType === FilterOptions.sump){
    sumpChangeWaterProofPlateHeight(newWaterProofPlateHeight, filterPlanks)
  }
  currWaterProofPlateHeight = newWaterProofPlateHeight
}