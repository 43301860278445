import * as THREE from 'three';
import { colorPlankSide, makeCircle, makePlank, makeRectangle } from './helper';
import { FilterLocation } from '../../@types/aquarium';
import filterWidth from './jsonFiles/filterWidthInfo.json';
import { maxHeaderSize } from 'http';
import { changeAluminiumRearWall } from './aluminiumFinish';

const scale = 25;
const PI = 3.14159265359;
export const sumpFilterHeight = 40;
const closetHeightdisappear = 60;// if closetheight is smaller than this, the filter should disappear
export let sumpFilterDepth = 43;
export const maxSumpFilterDepth = 60;//max depth of the filter
export const sumpFilterDepthDiff = 10;// difference between depth of aquarium and filter
const filterGlassWidth = 1;
//const filterWidthDiff = 40; // this is how much smaller the filter is than the whole aquarium
//const sideCompWidth = 12; // the width of the 2 sidecompartiments
const compDist = 4; // how far the first 3 filtercompartiments are away from each other, last compartiment is against the 3rd
let heightDiff = 40; //how far down the aquarium the filter stands
const landingWidth = 28; //overloop lengte
const landingDepth = 11; //overloop breedte
const landingHoleDiameter = 3.2; //diameter boorgaten op bodem van overloop
const landingthickness = 0.6;//glassdikte van overloop
export const bottomPlanksHeightDiff = 19 //filterplanks that stand on bottom have to be 10cm under the roof of the filter
const floatingPlanksHeightDiff = 14 // filterplanks that do not stand on the bottom have to bo 3cm under the roof
const osmoseWidth = 10;
const marineConformFilterWidth = 85;//length of a filter that is placed in a marine conform closet
const closetThickness = 1.8
const leftSeperatorHeightDiff = 2; // how much the most left filterplank (plank with incaping) should be from the ceiling
const backVoorfilterHeightDiff = 6; // how much the plank should be from the ceiling that seperates the voorfilter from the waterentrance
let widthOffset = 0;//how far the filter should be from the left side

let currFilterLocation: FilterLocation;
let osmoseComp = false;
let currLowerClosetHeight = 0;
let filterMarineConform = false;
let currWaterProofPlateHeight = 0

export function makeSumpFilter(scene: THREE.Scene, width: number, height: number, lowerClosetHeight: number, depth: number, waterproofPlateHeight: number, filterPlanks: THREE.Mesh[][]){
    currLowerClosetHeight = lowerClosetHeight
    if(filterMarineConform){
        return
    }
    changeSumpFilterDepth(depth)
    
    let filterWidthDiff = getSumpFilterWidthDiff(width);
    let sideCompWidth = getSideCompWidth(width);
    let osmoseWidthOffset = 0;
    if(osmoseComp){
        osmoseWidthOffset = osmoseWidth;
    }

    for(let i = 0; i < 10; i++){
        scene.remove(filterPlanks[0][i])
    }
//[RIGHT, LEFT, TOP, BOTTOM, FRONT, BACK]
    //left plank
    filterPlanks[0][0] = colorPlankSide(scene, -width/scale/2+filterGlassWidth/scale/2+widthOffset/scale, -height/scale/2-heightDiff/scale-waterproofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale, sumpFilterDepth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,0x000000], [0.4,0.4,0.4,1,1,1]);
    //back plank
    filterPlanks[0][1] = colorPlankSide(scene, -filterWidthDiff/scale/2+widthOffset/scale, -height/scale/2-heightDiff/scale-waterproofPlateHeight/scale, sumpFilterDepth/scale/2, width/scale-filterWidthDiff/scale, sumpFilterHeight/scale, filterGlassWidth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0xd9d9d9,0xd9d9d9,], [0.4,0.4,0.4,1,0.4,0.4,]);
    //right plank
    filterPlanks[0][2] = colorPlankSide(scene, -width/scale/2+(width/scale-filterWidthDiff/scale)+widthOffset/scale, -height/scale/2-heightDiff/scale-waterproofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale, sumpFilterDepth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,0x000000], [0.4,0.4,0.4,1,1,1]);
    //front plank
    filterPlanks[0][3] = colorPlankSide(scene, -filterWidthDiff/scale/2+widthOffset/scale, -height/scale/2-heightDiff/scale-waterproofPlateHeight/scale, -sumpFilterDepth/scale/2, width/scale-filterWidthDiff/scale, sumpFilterHeight/scale, filterGlassWidth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0xd9d9d9,0xd9d9d9,], [0.4,0.4,0.4,1,0.4,0.4,]);
    //lower plank
    filterPlanks[0][4] = colorPlankSide(scene, -filterWidthDiff/scale/2+widthOffset/scale, -height/scale/2-heightDiff/scale-sumpFilterHeight/scale/2-waterproofPlateHeight/scale, 0, width/scale-filterWidthDiff/scale, filterGlassWidth/scale, sumpFilterDepth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0xd9d9d9,0xd9d9d9,0xd9d9d9,], [0.4,0.4,0.4,0.4,0.4,0.4,])
    
    //planks from left to right
    //voorfilter back plank
    filterPlanks[0][5] = colorPlankSide(scene, -width/scale/2+sideCompWidth/scale/2+filterGlassWidth/scale/2+widthOffset/scale, -height/scale/2-heightDiff/scale-backVoorfilterHeightDiff/scale/2-waterproofPlateHeight/scale, -sumpFilterDepth/scale/2+compDist/scale, sideCompWidth/scale-filterGlassWidth/scale, sumpFilterHeight/scale-backVoorfilterHeightDiff/scale, filterGlassWidth/scale, [0x000000,0x000000,0xd9d9d9,0x000000,0xd9d9d9,0xd9d9d9,], [1,1,0.4,1,0.4,0.4,]);
    //voorfilter right plank
    filterPlanks[0][6] = makeSidepanel(scene, [
        [-sumpFilterDepth/scale/2+filterGlassWidth/scale/2, -height/scale/2-heightDiff/scale-sumpFilterHeight/scale/2+filterGlassWidth/scale/2-waterproofPlateHeight/scale],
        [sumpFilterDepth/scale/2-filterGlassWidth/scale/2-compDist/scale,-height/scale/2-heightDiff/scale-sumpFilterHeight/scale/2+filterGlassWidth/scale/2-waterproofPlateHeight/scale],
        [sumpFilterDepth/scale/2-filterGlassWidth/scale/2,-height/scale/2-heightDiff/scale-sumpFilterHeight/scale/2+filterGlassWidth/scale/2+compDist/scale-waterproofPlateHeight/scale],
        [sumpFilterDepth/scale/2-filterGlassWidth/scale/2,-height/scale/2-heightDiff/scale+sumpFilterHeight/scale/2+filterGlassWidth/scale/2-leftSeperatorHeightDiff/scale-waterproofPlateHeight/scale],
        [-sumpFilterDepth/scale/2+filterGlassWidth/scale/2,-height/scale/2-heightDiff/scale+sumpFilterHeight/scale/2+filterGlassWidth/scale/2-leftSeperatorHeightDiff/scale-waterproofPlateHeight/scale],
    ], filterGlassWidth/scale, 0xd9d9d9, 0.4);
    filterPlanks[0][6].rotateY(PI*270/180);
    filterPlanks[0][6].position.x = -width/scale/2+sideCompWidth/scale+widthOffset/scale;
    //compartiment 2 right plank
    filterPlanks[0][7] = colorPlankSide(scene, -width/scale/2+(width/scale-filterWidthDiff/scale)-sideCompWidth/scale-compDist/scale-osmoseWidthOffset/scale+widthOffset/scale, -height/scale/2-heightDiff/scale-bottomPlanksHeightDiff/scale/2-waterproofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale-bottomPlanksHeightDiff/scale, sumpFilterDepth/scale-filterGlassWidth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,0x000000,], [0.4,0.4,0.4,1,1,1,]);
    //compartiment 3 left plank
    filterPlanks[0][8] = colorPlankSide(scene, -width/scale/2+(width/scale-filterWidthDiff/scale)-sideCompWidth/scale-osmoseWidthOffset/scale+widthOffset/scale, -height/scale/2-heightDiff/scale-floatingPlanksHeightDiff/scale/2+compDist/scale/2-waterproofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale-floatingPlanksHeightDiff/scale-+compDist/scale, sumpFilterDepth/scale-filterGlassWidth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,], [0.4,0.4,0.4,0.4,1,1,]);
    //osmoseplank
    filterPlanks[0][9] = colorPlankSide(scene, -width/scale/2+(width/scale-filterWidthDiff/scale)-osmoseWidth/scale+widthOffset/scale, -height/scale/2-heightDiff/scale-waterproofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale, sumpFilterDepth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,0x000000,], [0.4,0.4,0.4,1,1,1,])
    if(!osmoseComp){
        scene.remove(filterPlanks[0][9])
    }

    currWaterProofPlateHeight = waterproofPlateHeight
}

export function getSumpFilterWidthDiff(aquariumWidth: number){
    for(let i = 0; i < filterWidth['aquariumWidth'].length; i++){
        if(aquariumWidth < Number(filterWidth['aquariumWidth'][i])){
            return Number(filterWidth['filterWidthDiff'][i-1])
        }
    }
    return 0;
}

export function getSideCompWidth(aquariumWidth: number){
    for(let i = 0; i < filterWidth['aquariumWidth'].length; i++){
        if(aquariumWidth > Number(filterWidth['aquariumWidth'][i])){
            return Number(filterWidth['sideCompWidth'][i])
        }
    }
    return 0;
}

export function changeSumpLocation(scene: THREE.Scene, width: number, height: number, filterHeight: number, depth: number, glassWidth: number, filterPlanks: THREE.Mesh[][], filterLocation: FilterLocation){
    currFilterLocation = filterLocation
    let amountPlanks = filterPlanks[0].length
    for(let i = amountPlanks-1; i >= 10; i--){
        scene.remove(filterPlanks[0][i])
        filterPlanks[0].splice(i);
    }

    switch(filterLocation){
        case(FilterLocation.leftBack):{
            //front landingplank
            filterPlanks[0].push(makePlank(scene, -width/scale/2+landingWidth/scale/2+glassWidth, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000))
            //right landingplank
            filterPlanks[0].push(makePlank(scene, -width/scale/2+landingWidth/scale+glassWidth-landingthickness/scale/2, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //left hole
            let lhole = makeCircle(scene, -width/scale/2+landingWidth/scale/4+glassWidth, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000);
            lhole.rotateX(PI*270/180)
            //middle hole
            let mhole = makeCircle(scene, -width/scale/2+landingWidth/scale/2+glassWidth, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000);
            mhole.rotateX(PI*270/180)
            //right hole
            let rhole = makeCircle(scene, -width/scale/2+3*landingWidth/scale/4+glassWidth, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000);
            rhole.rotateX(PI*270/180)
            filterPlanks[0].push(lhole);
            filterPlanks[0].push(mhole);
            filterPlanks[0].push(rhole)
            break;
        }
        case(FilterLocation.leftMid):{
            //back
            filterPlanks[0].push(makePlank(scene,-width/scale/2+landingWidth/scale/2+glassWidth, -(height-filterHeight)/scale/2, -landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //right
            filterPlanks[0].push(makePlank(scene,-width/scale/2+landingWidth/scale+glassWidth-landingthickness/scale/2,-(height-filterHeight)/scale/2,0, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //front
            filterPlanks[0].push(makePlank(scene,-width/scale/2+landingWidth/scale/2+glassWidth,-(height-filterHeight)/scale/2, landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //left hole
            let lhole = makeCircle(scene, -width/scale/2+landingWidth/scale/4+glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000);
            lhole.rotateX(PI*270/180)
            //middle hole
            let mhole = makeCircle(scene, -width/scale/2+landingWidth/scale/2+glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000);
            mhole.rotateX(PI*270/180)
            //right hole
            let rhole = makeCircle(scene, -width/scale/2+3*landingWidth/scale/4+glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000);
            rhole.rotateX(PI*270/180)
            filterPlanks[0].push(lhole);
            filterPlanks[0].push(mhole);
            filterPlanks[0].push(rhole)
            break;
        }
        case(FilterLocation.midBack):{
            //left
            filterPlanks[0].push(makePlank(scene, -landingWidth/scale/2+landingthickness/scale/2, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //front
            filterPlanks[0].push(makePlank(scene, 0, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //right
            filterPlanks[0].push(makePlank(scene, landingWidth/scale/2-landingthickness/scale/2, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //left hole
            let lhole = makeCircle(scene, -landingWidth/scale/4, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000)
            lhole.rotateX(PI*270/180)
            //middle hole
            let mhole = makeCircle(scene, 0, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000)
            mhole.rotateX(PI*270/180)
            //right hole
            let rhole = makeCircle(scene, landingWidth/scale/4, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000)
            rhole.rotateX(PI*270/180)
            filterPlanks[0].push(lhole);
            filterPlanks[0].push(mhole);
            filterPlanks[0].push(rhole)
            break;
        }
        case(FilterLocation.midMid):{
            //left
            filterPlanks[0].push(makePlank(scene, -landingWidth/scale/2+landingthickness/scale/2, -(height-filterHeight)/scale/2,0, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //front
            filterPlanks[0].push(makePlank(scene, 0,-(height-filterHeight)/scale/2, landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //right
            filterPlanks[0].push(makePlank(scene, landingWidth/scale/2-landingthickness/scale/2, -(height-filterHeight)/scale/2, 0, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //back
            filterPlanks[0].push(makePlank(scene, 0,-(height-filterHeight)/scale/2, -landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //left hole
            let lhole = makeCircle(scene, -landingWidth/scale/4, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
            lhole.rotateX(PI*270/180)
            //middle hole
            let mhole = makeCircle(scene, 0, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
            mhole.rotateX(PI*270/180)
            //right hole
            let rhole = makeCircle(scene, landingWidth/scale/4, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
            rhole.rotateX(PI*270/180)
            filterPlanks[0].push(lhole);
            filterPlanks[0].push(mhole);
            filterPlanks[0].push(rhole)
            break;
        }
        case(FilterLocation.rightBack):{
            //front landingplank
            filterPlanks[0].push(makePlank(scene, width/scale/2-landingWidth/scale/2-glassWidth, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000))
            //left landingplank
            filterPlanks[0].push(makePlank(scene, width/scale/2-landingWidth/scale-glassWidth+landingthickness/scale/2, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //left hole
            let lhole = makeCircle(scene, width/scale/2-3*landingWidth/scale/4-glassWidth, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000)
            lhole.rotateX(PI*270/180)
            //middle hole
            let mhole = makeCircle(scene, width/scale/2-landingWidth/scale/2-glassWidth, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000)
            mhole.rotateX(PI*270/180)
            //right hole
            let rhole = makeCircle(scene, width/scale/2-landingWidth/scale/4-glassWidth, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000)
            rhole.rotateX(PI*270/180)
            filterPlanks[0].push(lhole);
            filterPlanks[0].push(mhole);
            filterPlanks[0].push(rhole)
            break;
        }
        case(FilterLocation.rightMid):{
            //back
            filterPlanks[0].push(makePlank(scene,width/scale/2-landingWidth/scale/2-glassWidth, -(height-filterHeight)/scale/2, -landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //left
            filterPlanks[0].push(makePlank(scene,width/scale/2-landingWidth/scale-glassWidth+landingthickness/scale/2,-(height-filterHeight)/scale/2,0, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //front
            filterPlanks[0].push(makePlank(scene,width/scale/2-landingWidth/scale/2-glassWidth,-(height-filterHeight)/scale/2 , landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //left hole
            let lhole = makeCircle(scene, width/scale/2-3*landingWidth/scale/4-glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
            lhole.rotateX(PI*270/180)
            //middle hole
            let mhole = makeCircle(scene, width/scale/2-landingWidth/scale/2-glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
            mhole.rotateX(PI*270/180)
            //right hole
            let rhole = makeCircle(scene, width/scale/2-landingWidth/scale/4-glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
            rhole.rotateX(PI*270/180)
            filterPlanks[0].push(lhole);
            filterPlanks[0].push(mhole);
            filterPlanks[0].push(rhole)
            break;
        }
    }
   
}

export function changeSumpLandingWidth(scene: THREE.Scene, widthDiff: number, filterPlanks: THREE.Mesh[][]){
    if(currFilterLocation !== FilterLocation.midBack && currFilterLocation !== FilterLocation.midMid){
        for(let i = 10; i < filterPlanks[0].length; i++){
            if(currFilterLocation === FilterLocation.leftBack || currFilterLocation === FilterLocation.leftMid){
                filterPlanks[0][i].position.x -= widthDiff/scale/2;
            }else{
                filterPlanks[0][i].position.x += widthDiff/scale/2;
            }
        }
    }
  }

export function changeSumpLandingHeight(scene: THREE.Scene, currHeight: number, newHeight: number, filterPlanks: THREE.Mesh[][]){
    for(let i = 10; i < filterPlanks[0].length; i++){
        if(i >= filterPlanks[0].length-3){
            filterPlanks[0][i].position.y -= (newHeight-currHeight)/scale/2
        }else{
            filterPlanks[0][i].geometry.scale(1,newHeight/currHeight,1)
        }
    }
  }

export function changeSumpLandingDepth(scene: THREE.Scene, depthDiff: number, filterPlanks: THREE.Mesh[][]){
    if(currFilterLocation !== FilterLocation.leftMid && currFilterLocation !== FilterLocation.midMid && currFilterLocation !== FilterLocation.rightMid){
        for(let i = 10; i < filterPlanks[0].length; i++){
            filterPlanks[0][i].position.z -= depthDiff/scale/2;
        }
    }
  }

//changes the depth of the filter but you have to still call the make filter function
export function changeSumpFilterDepth(newDepth: number){
    if(newDepth - sumpFilterDepthDiff < maxSumpFilterDepth){
        sumpFilterDepth = newDepth-sumpFilterDepthDiff
    }else{
        sumpFilterDepth = maxSumpFilterDepth
    }
}

export function changeOsmoseCompartiment(scene: THREE.Scene, filterPlanks: THREE.Mesh[][], drawOsmoseCompartiment: boolean){

    if(drawOsmoseCompartiment === osmoseComp){
        return
    }
    osmoseComp = drawOsmoseCompartiment;
    if(osmoseComp){
        filterPlanks[0][7].position.x -= osmoseWidth/scale;
        filterPlanks[0][8].position.x -= osmoseWidth/scale;
        scene.add(filterPlanks[0][9])
    }else{
        filterPlanks[0][7].position.x += osmoseWidth/scale;
        filterPlanks[0][8].position.x += osmoseWidth/scale;
        scene.remove(filterPlanks[0][9])
    }
  }

// makes a panel with a shape defined by path, so path contains coordinates and the panelshape follows these coordinates
function makeSidepanel(scene: THREE.Scene, path: number[][], depth: number, color: THREE.ColorRepresentation, opacity=1){
    let shape = new THREE.Shape();
    shape.moveTo(path[0][0], path[0][1]);
    for(let i = 1; i < path.length; i++){
        shape.lineTo(path[i][0], path[i][1])
    }
    shape.lineTo(path[0][0], path[0][1])
    
    let shapeGeo = new THREE.ExtrudeGeometry(shape, {depth: depth, bevelEnabled: false});
    let cubeMaterials = [
        new THREE.MeshPhongMaterial( {color: 0xd9d9d9, shininess: 50, emissive:0xd9d9d9, transparent:true, opacity:0.4} ),
        new THREE.MeshPhongMaterial( {color: 0xd9d9d9, shininess: 50, emissive:0xd9d9d9, transparent:true, opacity:0.4} ),
        new THREE.MeshPhongMaterial( {color: 0xd9d9d9, shininess: 50, emissive:0xd9d9d9, transparent:true, opacity:0.4} ),
        new THREE.MeshPhongMaterial( {color: 0x000000, shininess: 50, emissive:0x000000} ),
        new THREE.MeshPhongMaterial( {color: 0x000000, shininess: 50, emissive:0x000000} ),
        new THREE.MeshPhongMaterial( {color: 0x000000, shininess: 50, emissive:0x000000} ),
    ];
    let shapeMesh = new THREE.Mesh(shapeGeo, cubeMaterials);
    
    scene.add(shapeMesh);
    return shapeMesh;
}

export function makeSumpMarineConform(scene: THREE.Scene, marineConform: boolean, width: number, height: number, filterBottomHeight: number, depth: number, filterPlanks: THREE.Mesh[][]){
    filterMarineConform = marineConform
    if(!marineConform){
        makeSumpFilter(scene, width, height, currLowerClosetHeight, depth, currWaterProofPlateHeight, filterPlanks);
        return
    }
    let filterWidthDiff = width-marineConformFilterWidth
    let sideCompWidth = getSideCompWidth(width)
    let marineConformHeightDiff = -height/2-(filterBottomHeight+sumpFilterHeight/2)
    let osmoseWidthOffset = 0;
    if(osmoseComp){
        osmoseWidthOffset = osmoseWidth;
    }

    for(let i = 0; i < 10; i++){
        scene.remove(filterPlanks[0][i])
    }

    //left plank
    filterPlanks[0][0] = colorPlankSide(scene, -width/scale/2+closetThickness/scale, -height/scale/2-marineConformHeightDiff/scale-currWaterProofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale, sumpFilterDepth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,0x000000], [0.4,0.4,0.4,1,1,1]);
    //back plank
    filterPlanks[0][1] = colorPlankSide(scene, -filterWidthDiff/scale/2, -height/scale/2-marineConformHeightDiff/scale-currWaterProofPlateHeight/scale, sumpFilterDepth/scale/2, width/scale-filterWidthDiff/scale, sumpFilterHeight/scale, filterGlassWidth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0xd9d9d9,0xd9d9d9,], [0.4,0.4,0.4,1,0.4,0.4,]);
    //right plank
    filterPlanks[0][2] = colorPlankSide(scene, -width/scale/2+(width/scale-filterWidthDiff/scale), -height/scale/2-marineConformHeightDiff/scale-currWaterProofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale, sumpFilterDepth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,0x000000], [0.4,0.4,0.4,1,1,1]);
    //front plank
    filterPlanks[0][3] = colorPlankSide(scene, -filterWidthDiff/scale/2, -height/scale/2-marineConformHeightDiff/scale-currWaterProofPlateHeight/scale, -sumpFilterDepth/scale/2, width/scale-filterWidthDiff/scale, sumpFilterHeight/scale, filterGlassWidth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0xd9d9d9,0xd9d9d9,], [0.4,0.4,0.4,1,0.4,0.4,]);
    //lower plank
    filterPlanks[0][4] = colorPlankSide(scene, -filterWidthDiff/scale/2, -height/scale/2-marineConformHeightDiff/scale-sumpFilterHeight/scale/2-currWaterProofPlateHeight/scale, 0, width/scale-filterWidthDiff/scale, filterGlassWidth/scale, sumpFilterDepth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0xd9d9d9,0xd9d9d9,0xd9d9d9,], [0.4,0.4,0.4,0.4,0.4,0.4,])
    
    //planks from left to right
    //voorfilter back plank
    filterPlanks[0][5] = colorPlankSide(scene, -width/scale/2+sideCompWidth/scale/2+filterGlassWidth/scale/2, -height/scale/2-marineConformHeightDiff/scale-bottomPlanksHeightDiff/scale/2-currWaterProofPlateHeight/scale, -sumpFilterDepth/scale/2+compDist/scale, sideCompWidth/scale-filterGlassWidth/scale, sumpFilterHeight/scale-bottomPlanksHeightDiff/scale, filterGlassWidth/scale, [0x000000,0x000000,0xd9d9d9,0x000000,0xd9d9d9,0xd9d9d9,], [1,1,0.4,1,0.4,0.4,]);
    //voorfilter right plank
    filterPlanks[0][6] = makeSidepanel(scene, [
        [-sumpFilterDepth/scale/2+filterGlassWidth/scale/2, -height/scale/2-marineConformHeightDiff/scale-sumpFilterHeight/scale/2+filterGlassWidth/scale/2-currWaterProofPlateHeight/scale],
        [sumpFilterDepth/scale/2-filterGlassWidth/scale/2-compDist/scale,-height/scale/2-marineConformHeightDiff/scale-sumpFilterHeight/scale/2+filterGlassWidth/scale/2-currWaterProofPlateHeight/scale],
        [sumpFilterDepth/scale/2-filterGlassWidth/scale/2,-height/scale/2-marineConformHeightDiff/scale-sumpFilterHeight/scale/2+filterGlassWidth/scale/2+compDist/scale-currWaterProofPlateHeight/scale],
        [sumpFilterDepth/scale/2-filterGlassWidth/scale/2,-height/scale/2-marineConformHeightDiff/scale+sumpFilterHeight/scale/2+filterGlassWidth/scale/2-floatingPlanksHeightDiff/scale-currWaterProofPlateHeight/scale],
        [-sumpFilterDepth/scale/2+filterGlassWidth/scale/2,-height/scale/2-marineConformHeightDiff/scale+sumpFilterHeight/scale/2+filterGlassWidth/scale/2-floatingPlanksHeightDiff/scale-currWaterProofPlateHeight/scale],
    ], filterGlassWidth/scale, 0xd9d9d9, 0.4);
    filterPlanks[0][6].rotateY(PI*270/180);
    filterPlanks[0][6].position.x = -width/scale/2+sideCompWidth/scale;
    //compartiment 2 right plank
    filterPlanks[0][7] = colorPlankSide(scene, -width/scale/2+(width/scale-filterWidthDiff/scale)-sideCompWidth/scale-compDist/scale-osmoseWidthOffset/scale, -height/scale/2-marineConformHeightDiff/scale-bottomPlanksHeightDiff/scale/2-currWaterProofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale-bottomPlanksHeightDiff/scale, sumpFilterDepth/scale-filterGlassWidth/scale,  [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,0x000000,], [0.4,0.4,0.4,1,1,1,]);
    //compartiment 3 left plank
    filterPlanks[0][8] = colorPlankSide(scene, -width/scale/2+(width/scale-filterWidthDiff/scale)-sideCompWidth/scale-osmoseWidthOffset/scale, -height/scale/2-marineConformHeightDiff/scale-floatingPlanksHeightDiff/scale/2+compDist/scale/2-currWaterProofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale-floatingPlanksHeightDiff/scale-+compDist/scale, sumpFilterDepth/scale-filterGlassWidth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,], [0.4,0.4,0.4,0.4,1,1,]);
    //osmoseplank
    filterPlanks[0][9] = colorPlankSide(scene, -width/scale/2+(width/scale-filterWidthDiff/scale)-osmoseWidth/scale, -height/scale/2-marineConformHeightDiff/scale-currWaterProofPlateHeight/scale, 0, filterGlassWidth/scale, sumpFilterHeight/scale, sumpFilterDepth/scale, [0xd9d9d9,0xd9d9d9,0xd9d9d9,0x000000,0x000000,0x000000,], [0.4,0.4,0.4,1,1,1,])
    if(!osmoseComp){
        scene.remove(filterPlanks[0][9])
    }
}

export function changeSumpMarineConformAquariumWidth(oldWidth: number, newWidth: number, filterPlanks: THREE.Mesh[][]){
    for(let i = 0; i < 10; i++){
        filterPlanks[0][i].position.x -= (newWidth-oldWidth)/scale/2
    }
}

export function changeSumpMarineConformAquariumHeight(currHeight: number, newHeight: number, filterPlanks: THREE.Mesh[][]){
    for(let i = 0; i < 10; i++){
        filterPlanks[0][i].position.y -= (newHeight-currHeight)/scale/2
    }
}

export function changeSumpFilterHeightDiff(scene: THREE.Scene, newFilterHeight: number, lowerClosetHeight: number, filterPlanks: THREE.Mesh[][]){
    if(newFilterHeight === 0){
        return
    }
    if(lowerClosetHeight < closetHeightdisappear && lowerClosetHeight !== 0){
        for(let i = 0; i < 10; i++){
            scene.remove(filterPlanks[0][i])
        }
    }else{
        for(let i = 0; i < 10; i++){
            scene.add(filterPlanks[0][i])
        }
        if(!osmoseComp){
            scene.remove(filterPlanks[0][9])
        }
    }

    let newFilterHeightDiff = -(newFilterHeight+sumpFilterHeight/2)-filterGlassWidth/2
    for(let i = 0; i < 10; i++){
        filterPlanks[0][i].position.y -= (newFilterHeightDiff - heightDiff)/scale
    }
    heightDiff = newFilterHeightDiff
}

//only used if electraruimte is chosen on left side
//width should be current width of aquarium to calculate the width of the electraruimte
export function changeSumpWidthOffset(width: number) {
  if (width === 0) {
    widthOffset = 0;
  } else {
    widthOffset = getElectraWidth(width);
  }
}

function getElectraWidth(width: number) {
  for (let i = 0; i < filterWidth["aquariumWidth"].length; i++) {
    if (width < Number(filterWidth["aquariumWidth"][i])) {
      return Number(filterWidth["electrabreedte"][i - 1]);
    }
  }
  return 0;
}

export function sumpChangeWaterProofPlateHeight(newWaterProofPlateHeight: number, filterPlanks: THREE.Mesh[][]){
    for(let i = 0; i < 10; i++){
        filterPlanks[0][i].position.y += currWaterProofPlateHeight-newWaterProofPlateHeight
    }
    currWaterProofPlateHeight = newWaterProofPlateHeight
}

