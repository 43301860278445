import Header from "../components/organisms/Header";
import { AllTextObject } from "../@types/language";
import "../styles/landing.css";
import { BigText, Text, Title } from "../components/atoms/StyledComponents";
import { CaretRight, Cube, Gear, Info } from "@phosphor-icons/react";
import landing_animation from "../images/landing-coral-gif.gif";
import Footer from "../components/organisms/Footer";
import { Link } from "react-router-dom";

type Props = { allText: AllTextObject };

function Landing({ allText }: Props) {
  return (
    <div>
      <Header allText={allText} />
      <main className="landing-main-container">
        <div className="landing-banner-container">
          <div className="landing-banner-animation-container">
            <figure>
              <img src={landing_animation} alt="coral animation" />
            </figure>
          </div>
          <section className="landing-banner-section">
            <div className="landing-grid-item landing-grid-item-2">
              <Title>Aquarium Configurator</Title>
              <Text>
                Vanaf nu kan u zelf de prijs online berekenen van uw toekomstig
                droomaquarium.
              </Text>
              <div>
                <div className="landing-banner-info-item">
                  <CaretRight size={32} color="var(--neutral-100)" />
                  <BigText>
                    Zie uw configuratie in een live 3D simulatie!
                  </BigText>
                </div>
                <div className="landing-banner-info-item">
                  <CaretRight size={32} color="var(--neutral-100)" />
                  <BigText>Krijg meteen een offerte!</BigText>
                </div>
                <div className="landing-banner-info-item">
                  <CaretRight size={32} color="var(--neutral-100)" />
                  <BigText>24/7 beschikbaar op deze website!</BigText>
                </div>
              </div>
              <div className="landing-banner-icon">
                <Cube size={196} color="var(--primary-700)" weight="thin" />
              </div>
            </div>
            <Link to="configurator" className="landing-grid-item landing-grid-item-3">
              <Title>Aan de slag!</Title>
              <Text>
                Ga aan de slag met de vernieuwde aquarium configurator! Ontwerp
                zelf uw droom aquarium!
              </Text>
              <div className="landing-banner-icon">
                <Gear size={196} color="var(--green-600)" weight="thin" />
              </div>
            </Link>
            <div className="landing-grid-item landing-grid-item-4">
              <Title>Webshop</Title>
              <Text>Bereken hier de prijs van een individueel onderdeel</Text>
              <div className="landing-banner-icon">
                <Gear size={196} color="var(--ocean-600)" weight="thin" />
              </div>
            </div>
            <div className="landing-grid-item landing-grid-item-5">
              <Text>
                Heb jij onze nieuwe configurator al ontdekt? Stel je
                droomaquarium samen in enkele klikken!
              </Text>
              <div className="landing-banner-icon">
                <Info size={196} color="var(--primary-700)" weight="thin" />
              </div>
            </div>
            <div
              className="landing-grid-item landing-grid-item-6"
              style={{ padding: 0 }}
            >
              <iframe
                title="Bedrijfsvideo Aqualife"
                src="https://www.youtube.com/embed/6jvojEDlWL8"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
                height={"100%"}
              ></iframe>
            </div>
          </section>
        </div>
        <section style={{ height: "200px" }}></section>
        <Footer />
      </main>
    </div>
  );
}

export default Landing;
