import React, { useEffect, useState } from "react";
import { ImageOption, Steps } from "../../../@types/configurator";
import ConfiguratorStep from "../../templates/ConfiguratorStep";
import { Funnel } from "@phosphor-icons/react";
import StepItem from "../../templates/StepItem";
import {
  extraPvcPipeworkPackageOptions,
  filterLocationOptions,
  filterLocationPlaceholder,
  filterOptions,
} from "../../../data/aquarium";
import ConfiguratorImageOption from "../../molecules/ConfiguratorImageOption";
import {
  Aquarium,
  FilterLocation,
  FilterOptions,
  PvcPipeworkPackage,
} from "../../../@types/aquarium";
import { useSelector, useDispatch } from "react-redux";
import { setFilterType } from "../../../redux/aquariumSlice";
import { RootState } from "../../../redux/store";
import IconToggle from "../../molecules/IconToggle";
import { setExternalFilterDryroom as setExternalFilterDryroomRedux } from "../../../redux/aquariumSlice";
import ImageSelectionButton from "../../molecules/ImageSelectionButton";
import { setOpenFilterLocationModal } from "../../../redux/configuratorSlice";
import { defaultAquarium } from "../../../utils/defaultAquarium";
import { setFilterLocation as setFilterLocationRedux } from "../../../redux/aquariumSlice";
import { setBioCoverPanels as setBioCoverPanelsRedux } from "../../../redux/aquariumSlice";
import { setOsmoseCompartiment as setOsmoseCompartimentRedux } from "../../../redux/aquariumSlice";
import { setBoreholeLeft as setBoreholeLeftRedux } from "../../../redux/aquariumSlice";
import { setBoreholeRight as setBoreholeRightRedux } from "../../../redux/aquariumSlice";
import { setPvcPipeworkPackage as setPvcPipeworkPackageRedux } from "../../../redux/aquariumSlice";
import { checkFilterType } from "../../../utils/configuratorRules";

type Props = {
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function FilterStep({ setOpenStep }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const stepItemInfoOverview = useSelector(
    (state: RootState) => state.configurator.stepItemInfoOverview
  );
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState<FilterOptions | null>(
    aquarium.filter.filterType
  );
  const [referenceFilter, setReferenceFilter] = useState<FilterOptions | null>(
    aquarium.filter.filterType
  );

  const [externalFilterDryroom, setExternalFilterDryroom] = useState<boolean>(
    aquarium.filter.externalDryroom
  );

  const [bioCoverPanels, setBioCoverPanels] = useState<boolean>(
    aquarium.filter.bioCoverPanels
  );

  const [osmoseCompartiment, setOsmoseCompartiment] = useState<boolean>(
    aquarium.filter.osmoseCompartiment
  );

  const [boreholeLeft, setBoreholeLeft] = useState<boolean>(
    aquarium.filter.boreholeLeft
  );

  const [boreholeRight, setBoreholeRight] = useState<boolean>(
    aquarium.filter.boreholeRight
  );

  const getLocationObj = (locationID: FilterLocation): ImageOption => {
    const filterLocation = filterLocationOptions.find(
      (item) => item.id === locationID
    );
    return filterLocation ? filterLocation : filterLocationPlaceholder;
  };

  const [selectedFilterLocation, setSelectedFilterLocation] =
    useState<ImageOption>(
      getLocationObj(
        aquarium.filter.filterLocation
          ? aquarium.filter.filterLocation
          : FilterLocation.placeholder
      )
    );
  const [filterLocation, setFilterLocation] = useState<FilterLocation | null>(
    aquarium.filter.filterLocation
  );

  const [pvcPipeworkPackage, setPvcPipeworkPackage] =
    useState<PvcPipeworkPackage | null>(aquarium.extra.pvcPipeworkPackage);

  function updateFilterOptions(filter: FilterOptions | null) {
    if (filter !== FilterOptions.external) {
      dispatch(
        setExternalFilterDryroomRedux(defaultAquarium.filter.externalDryroom)
      );

      dispatch(setBoreholeLeftRedux(defaultAquarium.filter.boreholeLeft));
      dispatch(setBoreholeRightRedux(defaultAquarium.filter.boreholeRight));
    }
    if (filter !== FilterOptions.bio) {
      dispatch(setBioCoverPanelsRedux(defaultAquarium.filter.externalDryroom));
    }
    if (filter !== FilterOptions.sump) {
      dispatch(
        setOsmoseCompartimentRedux(defaultAquarium.filter.osmoseCompartiment)
      );
    }
    if (referenceFilter !== selectedFilter) {
      dispatch(setFilterLocationRedux(defaultAquarium.filter.filterLocation));
      setReferenceFilter(selectedFilter);
    }
  }

  useEffect(() => {
    dispatch(setFilterType(selectedFilter));
    updateFilterOptions(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    dispatch(setExternalFilterDryroomRedux(externalFilterDryroom));
    updateFilterOptions(selectedFilter);
  }, [externalFilterDryroom]);

  useEffect(() => {
    setSelectedFilterLocation(
      filterLocation
        ? getLocationObj(filterLocation)
        : filterLocationPlaceholder
    );
  }, [filterLocation]);

  useEffect(() => {
    dispatch(setBioCoverPanelsRedux(bioCoverPanels));
  }, [bioCoverPanels]);

  useEffect(() => {
    dispatch(setOsmoseCompartimentRedux(osmoseCompartiment));
  }, [osmoseCompartiment]);

  useEffect(() => {
    dispatch(setBoreholeLeftRedux(boreholeLeft));
  }, [boreholeLeft]);
  useEffect(() => {
    dispatch(setBoreholeRightRedux(boreholeRight));
  }, [boreholeRight]);

  useEffect(() => {
    dispatch(setPvcPipeworkPackageRedux(pvcPipeworkPackage));
  }, [pvcPipeworkPackage]);

  useEffect(() => {
    setSelectedFilter(aquarium.filter.filterType);
    setExternalFilterDryroom(aquarium.filter.externalDryroom);
    setFilterLocation(aquarium.filter.filterLocation);
    setBioCoverPanels(aquarium.filter.bioCoverPanels);
    setOsmoseCompartiment(aquarium.filter.osmoseCompartiment);
    setBoreholeLeft(aquarium.filter.boreholeLeft);
    setBoreholeRight(aquarium.filter.boreholeRight);
    setPvcPipeworkPackage(aquarium.extra.pvcPipeworkPackage);
  }, [aquarium]);

  const openFilterLocationMenu = () => {
    dispatch(setOpenFilterLocationModal(true));
  };

  const isFilterType = (item: FilterOptions | any): item is FilterOptions => {
    return typeof item !== "undefined";
  };

  return (
    <ConfiguratorStep
      title="Filtersysteem"
      step={4}
      stepId={Steps.filter}
      symbol={
        <Funnel
          className="configurator-step-header-symbol"
          size={80}
          color="var(--primary-300)"
        />
      }
      setOpenStep={setOpenStep}
    >
      <>
        <StepItem
          title="Kies filtersysteem"
          helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
          errortext="Uw huidige configuratie klopt niet meer met uw aangeduiden filtertype. Duid een van de onderstaande opties aan om deze fout op te lossen. Indien u een bioloog filter wilt kiezen, moet het onderstel minstens 60cm hoog zijn."
          hasIssue={stepItemInfoOverview.filter.filterType.hasIssue}
        >
          <div className="optionlist-container">
            {filterOptions
              .filter((option) => {
                if (isFilterType(option.id)) {
                  const checkObj: Aquarium = {
                    ...aquarium,
                    filter: { ...aquarium.filter, filterType: option.id },
                  };
                  return !checkFilterType(checkObj);
                }
              })
              .map((option) => (
                <ConfiguratorImageOption
                  name="filter-type-option"
                  option={option}
                  value={selectedFilter}
                  setValue={setSelectedFilter}
                  key={"filter-type-option-" + option.id}
                />
              ))}
          </div>
        </StepItem>
        {selectedFilter === FilterOptions.external && (
          <>
            <StepItem
              title="Droogkamer"
              helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
              errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
              hasIssue={false}
            >
              <IconToggle
                checked={externalFilterDryroom}
                setValue={setExternalFilterDryroom}
                text="Wilt u een externe droogkamer in uw aquarium?"
              />
            </StepItem>
          </>
        )}
        {((selectedFilter === FilterOptions.external &&
          externalFilterDryroom) ||
          selectedFilter === FilterOptions.biological ||
          selectedFilter === FilterOptions.bio ||
          selectedFilter === FilterOptions.sump) && (
          <StepItem
            title={
              selectedFilter === FilterOptions.external
                ? "Droogkamer locatie"
                : "Filter locatie"
            }
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="De locatie van de filter in het aquarium is niet langer compatibel met de huidige configuratie. Gelieve een van onderstaande opties aan te duiden."
            hasIssue={stepItemInfoOverview.filter.filterLocation.hasIssue}
          >
            <div className="optionlist-container">
              <div
                className="optionlist-button-container"
                onClick={() => openFilterLocationMenu()}
              >
                <ImageSelectionButton
                  name="filter-location-option"
                  option={
                    stepItemInfoOverview.filter.filterLocation.hasIssue
                      ? getLocationObj(FilterLocation.placeholder)
                      : selectedFilterLocation
                  }
                  value={
                    stepItemInfoOverview.filter.filterLocation.hasIssue
                      ? null
                      : filterLocation
                  }
                  setValue={setFilterLocation}
                  key={"filter-location-option-" + selectedFilterLocation.id}
                />
              </div>
            </div>
          </StepItem>
        )}
        {selectedFilter === FilterOptions.external && (
          <>
            <StepItem
              title="Boorgaten"
              helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
              errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
              hasIssue={false}
            >
              <div className="window-paint-side-options-container">
                <label
                  htmlFor="borehole-option-left"
                  className={
                    "window-paint-side-option " +
                    (boreholeLeft ? "window-paint-side-option-active" : "")
                  }
                >
                  Boorgat links
                  <input
                    checked={boreholeLeft}
                    type="checkbox"
                    id="borehole-option-left"
                    onChange={() => setBoreholeLeft(!boreholeLeft)}
                  />
                  <span className="checkmark"></span>
                </label>
                <label
                  htmlFor="borehole-option-right"
                  className={
                    "window-paint-side-option " +
                    (boreholeRight ? "window-paint-side-option-active" : "")
                  }
                >
                  Boorgat rechts
                  <input
                    checked={boreholeRight}
                    type="checkbox"
                    id="borehole-option-right"
                    onChange={() => setBoreholeRight(!boreholeRight)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </StepItem>
          </>
        )}
        {(selectedFilter === FilterOptions.bio ||
          selectedFilter === FilterOptions.sump) && (
          <StepItem
            title="PVC Package"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <div className="optionlist-container">
              {extraPvcPipeworkPackageOptions.map((option) => (
                <ConfiguratorImageOption
                  name="filter-type-option"
                  option={option}
                  value={pvcPipeworkPackage}
                  setValue={setPvcPipeworkPackage}
                  key={"filter-type-option-" + option.id}
                />
              ))}
            </div>
          </StepItem>
        )}
        {selectedFilter === FilterOptions.bio && (
          <>
            <StepItem
              title="Afdeksysteem"
              helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
              errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
              hasIssue={false}
            >
              <IconToggle
                checked={bioCoverPanels}
                setValue={setBioCoverPanels}
                text="Wilt u een afdeksysteem op uw filter?"
              />
            </StepItem>
          </>
        )}
        {selectedFilter === FilterOptions.sump && (
          <>
            <StepItem
              title="Osmosevak"
              helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
              errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
              hasIssue={false}
            >
              <IconToggle
                checked={osmoseCompartiment}
                setValue={setOsmoseCompartiment}
                text="Wilt u een osmosevak in uw filter?"
              />
            </StepItem>
          </>
        )}
      </>
    </ConfiguratorStep>
  );
}

export default FilterStep;
