import { ProductData } from "./product";
import { DecorTexture } from "./textures";

export type Aquarium = {
  aquariumDimensions: AquariumDimensions;
  sharpenGlass: boolean;
  waterType: WaterType | null;
  aquariumType: AquariumType | null;
  aquariumTexture: DecorTexture | null;
  coverPanels: boolean;
  coverPanelsColor: CoverPanelsColor;
  fullGlassCoverPanels: FullGlassCoverPanels | null;
  turtleIsland: TurtleIsland | null;
  lightCover: boolean;
  grindlist: boolean;
  windowPaintColor: WindowPaintOption;
  aquariumSides: SidesInfo;
  filter: {
    filterType: FilterOptions | null;
    externalDryroom: boolean;
    filterLocation: FilterLocation | null;
    bioCoverPanels: boolean;
    osmoseCompartiment: boolean;
    boreholeLeft: boolean;
    boreholeRight: boolean;
  };
  furniture: Furniture;
  technics: {
    boosterPump: ProductData[];
    led: ProductData[];
    heating: ProductData[];
    filter: ProductData[];
    circulationPump: ProductData[];
    proteinSkimmer: ProductData[];
    automaticRefillSystem: ProductData[];
    externalExteriorFilter: ProductData[];
    co2System: ProductData[];
    interior: ProductData[];
  };
  extra: {
    siliconeColor: SiliconColor;
    waterproofPlate: boolean;
    pvcPipeworkPackage: PvcPipeworkPackage | null;
  };
  delivery: DeliveryOption | null;
};

export type Furniture = {
  furnitureType: FurnitureType | null;
  dimensions: FurnitureDimensions;
  betonPlex: boolean;
  seeThrough: boolean;
  backWall34: boolean;
  electraRuimte: ElectraRuimte | null;
  removableSideLeft: boolean;
  removableSideRight: boolean;
  doorType: DoorType | null;
  aquaswitch: boolean;
};

export type AquariumCalculatorNeeds = {
  aquariumDimensions: AquariumDimensions;
  sideInfo: SideInfo;

  aquariumType: AquariumType;
  rimless: Rimless;
  lightCover: LightCover;
  fullGlass: FullGlass;
  turtle: Turtle;

  filterOptions: FilterOptions;
  externalFilter: ExternalFilter;
  biologicalFilter: BiologicalFilter;
  biofilter: BioFilter;
  sumpFilter: SumpFilter;

  furnitureDimensions: FurnitureDimensions;
  furniturtype: FurnitureType;
  // meubelOptions: MeubelOpties;
};

type Rimless = {};

export type LightCover = {
  lightBoardFinish: LightboardFinish;
  coverPanels: boolean;
};

type FullGlass = {
  fullGlassCoverPanels: FullGlassCoverPanels;
};

type Turtle = {
  turtleIsland: TurtleIsland;
  lightboardPresent: boolean;
  lightBoardFinish: LightboardFinish;
  coverPanels: boolean;
};

export interface AquariumInterface extends Aquarium {}

export enum WaterType {
  fresh = "zoetwater",
  salty = "zoutwater",
}

export enum LightboardFinish {
  aluminium = "aluminium",
  decorpanel = "decorpanel",
}

export enum FullGlassCoverPanels {
  noPanels = "noPanels",
  sliding = "sliding",
  nonSliding = "nonSliding",
}

export enum AquariumType {
  rimless = "rimless",
  lightCover = "lightcover",
  fullGlass = "fullglass",
  turtle = "turtle",
}

export enum TurtleIsland {
  noIsland = "noIsland",
  left = "left",
  right = "right",
  twosided = "twosided",
}

export type AquariumDimensions = {
  width: number;
  height: number;
  depth: number;
};

// export type AquariumDimension = {
//   waarde: number;
//   min: number;
//   max: number;
// };

export type SidesInfo = {
  left: SideInfo;
  right: SideInfo;
  front: SideInfo;
  back: SideInfo;
};

export type SideInfo = {
  sideName: Side;
  colorPainted: boolean;
  optiWhite: boolean;
  // optiWhitesides: Side[];
  // paintedSides: Side[];
  // aquariumDimensions: AquariumDimensions; //why is this here, dimensions are way more important, is should be used on more places than only here
  // sideGlassColor: SideGlassColor;
  // sharpenGlass: boolean;
};

export enum Side {
  links = "links",
  rechts = "rechts",
  achter = "achter",
  voor = "voor",
}

export type SideConfiguration = {
  side: Side;
  checked: boolean;
};

export enum SideGlassColor {
  zwart = "zwart",
  blauw = "blauw",
  doorzichtig = "doorzichtig",
}

export type BiologicalFilter = {
  filterLocation: FilterLocation;
  verwarmElement: boolean;
  circulatiePomp: boolean;
  filterMassa: boolean;
};

export type ExternalFilter = {
  filterLocation: FilterLocation;
  dryingRoom: boolean;
  purchaseFilter: boolean;
  holeLeft: boolean;
  holeRight: boolean;
};

export type BioFilter = {
  filterLocation: FilterLocation;
  verwarmElement: boolean;
  circulatiePomp: boolean;
  filterMassa: boolean;
  coveringSystem: boolean;
};

export type SumpFilter = {
  filterLocation: FilterLocation;
  osmosisCompartiment: boolean;
  eiwitAfschuimer: boolean;
  stromingsPomp: boolean;
  opvoerPomp: boolean;
};

export type InternalSump = {
  filterLocation: FilterLocation;
  technicalPackage: boolean;
};

export enum FilterOptions {
  none = "geen",
  biological = "Biologisch",
  bio = "Bioloog",
  external = "Extern",
  sump = "SUMP",
}

export enum FilterLocation {
  placeholder = "placeholder",
  leftBack = "Links achter",
  leftMid = "Links midden",
  midBack = "Midden achter",
  midMid = "Centraal",
  rightBack = "Rechts achter",
  rightMid = "Rechts midden",
}

export type FurnitureDimensions = {
  baseHeight: number;
  topHeight: number;
};

export enum FurniturePart {
  base = "base",
  top = "top",
}

export type FurnitureOptions = {
  seeThrough: boolean;
  doorType: DoorType;
  electraRuimte: ElectraRuimte;
  removableSides: RemovableSides;
  betonPlexBottom: boolean;
  betonPlexBack: boolean;
};

export enum DoorType {
  slidingDoors = "slidingDoors",
  pushDoors = "pushDoors",
  removablePanels = "removablePanels",
}

export enum ElectraRuimte {
  none = "geen",
  left = "links",
  right = "rechts",
}

export enum RemovableSides {
  links = "links",
  rechts = "rechts",
}

export enum BoreHole {
  niet = "niet",
  links = "links",
  rechts = "rechts",
  beide = "beide",
}

export enum SiliconColor {
  black = "black",
  transparent = "transparent",
}

export enum CoverPanelsColor {
  black = "black",
  gray = "gray",
  white = "white",
  antraciet = "antraciet",
}

export enum WaterVastePlaat {
  geen = "geen",
  betonplex = "betonplex",
  pvc = "pvc",
}

export enum MeubelKleur {
  antracietGrijs = "antracietGrijs",
  beuk = "beuk",
  chocolatOak = "chocolatOak",
  darkWenge = "darkWenge",
  dSignOak = "dSignOak",
  fModern = "fModern",
  aluminiumGrijs = "aluminiumGrijs",
  steigerHout = "steigerHout",
  concreteDecor = "concreteDecor",
  wit = "wit",
  zwart = "zwart",
  bearnwoodOld = "bearnwoodOld",
  ferroBrons = "ferroBrons",
  houtnerfWit = "houtnerfWit",
  houtnerfZwart = "houtnerfZwart",
  kansasOak = "kansasOak",
  leisteenAntraciet = "leisteenAntraciet",
  marmerWit = "marmerWit",
  marmerZwart = "marmerZwart",
  minnesotaOak = "minnesotaOak",
  pasadenaEik = "pasadenaEik",
}

export enum LacobelKleur {
  natuurlijkBruin = "natuurlijkBruin",
  grijs = "grijs",
  mokka = "mokka",
  antraciet = "antraciet",
  wit = "wit",
  zwart = "zwart",
}

export enum FurnitureType {
  none = "none",
  frameOnly = "base only",
  decor = "decor furniture",
  aluminium = "aluminium furniture",
  marine = "marine furniture",
}

export enum WindowPaintOption {
  black = "black",
  blue = "blue",
}

export enum ExtraLEDFreshWaterOption {
  none = "none",
  companionAquarium = "companion aquarium",
  manyPlants = "many plants",
  cichlids = "cichlids",
  discusAltumsAmazone = "discus altums amazone",
}

export enum ExtraLEDSaltWaterOption {
  none = "none",
  onlyFish = "only fish",
  fishSoftLpsCoral = "fish soft lps coral",
  fishSoftLpsStoneCoral = "fish soft lps stone coral",
}

export enum DeliveryOption {
  pickup = "pickup",
  onlyDeliver = "only deliver",
  deliverHalfInstallation = "deliver half installation",
  deliverCompleteInstallation = "deliver complete installation",
}

export enum PvcPipeworkPackage {
  diy = "do it yourself",
  byAqualife = "made by Aqualife",
}
