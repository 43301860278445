import { FacebookLogo, Question } from "@phosphor-icons/react";
import React from "react";
import { Text } from "../atoms/StyledComponents";
import HIB_logo from "../../images/handmade-in-belgium.png";

type Props = {};

function ConfiguratorMenuFooter({}: Props) {
  return (
    <div className="configurator-footer-container">
      <a href="">
        <Question size={24} />
        <Text color="var(--neutral-500)">Help</Text>
      </a>
      <div>
        <figure className="configurator-footer-figure">
          <img
            className="configurator-footer-img"
            src={HIB_logo}
            alt="Handmade in Belgium logo"
          />
        </figure>
        <Text color="var(--neutral-500)">Handmade in Belgium</Text>
      </div>
      <a href="https://www.facebook.com/AqualifeBelgium/" target="_blank">
        <FacebookLogo size={24} />
      </a>
    </div>
  );
}

export default ConfiguratorMenuFooter;
