import React from "react";
import { SiliconColor } from "../../@types/aquarium";

type Props = {
  checked: SiliconColor;
  setValue: React.Dispatch<React.SetStateAction<SiliconColor>>;
};

function SiliconColorMenu({ checked, setValue }: Props) {
  return (
    <div className="window-paint-menu-container">
      <div
        className={
          "paint-option-container " +
          (checked === SiliconColor.black ? "paint-option-active" : "")
        }
        onClick={() => setValue(SiliconColor.black)}
      >
        <input
          type="radio"
          name="silicon-color-option"
          className="window-paint-option"
          id="silicon-color-option-black"
        />
        <label htmlFor="silicon-color-option-black">Zwart</label>
        <div className="window-paint-preview-black"></div>
      </div>
      <div
        className={
          "paint-option-container " +
          (checked === SiliconColor.transparent ? "paint-option-active" : "")
        }
        onClick={() => setValue(SiliconColor.transparent)}
      >
        <input
          type="radio"
          name="silicon-color-option"
          className="window-paint-option"
          id="silicon-color-option-transparent"
        />
        <label htmlFor="silicon-color-option-transparent">Transparant</label>
        <div className="window-paint-preview-transparent"></div>
      </div>
    </div>
  );
}

export default SiliconColorMenu;
