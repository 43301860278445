import * as THREE from 'three';
import { makeGeometryMaterials, makePlank } from './helper';
import { makeSkeletonCloset } from './skeleton';
import { Angle } from '@phosphor-icons/react/dist/ssr';
import { changeWaterProofPlateHeight } from './closetOptions';
import { DecorTexture } from '../../@types/textures';

const scale = 25;
const PI = 3.14159265359;

const closetThickness = 0.3;// thicness of aluminium
const doorLeftRightSideDiff = 5;// how much the doors are from the sides
const doorUpperBottomDiff = 9;// how much the doors are from the bottom and the top
const outerPlankOverlap = 4;// how far the plates are over the aquarium
const widthDistribution = [139,241,321,361]//when there should be more doors, so first 2 doors, if the width is more than the first one, 4 doors...

let currWidth: number;
let currHeight: number;
let currDepth: number;
let currLowerClosetHeight: number;
let currUpperClosetHeight = 0;
let currAngle = 0;// how much the doors are currently turned
let currWaterproofPlateHeight = 0
let currTexture: THREE.Texture[] = []

export function makeAluminiumDoors(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, waterproofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
    closetPlanks.push([])
    closetPlanks.push([])
    
    let amountComp = getAmountCompartiments(width)
    let compWidth = width/amountComp;
    for(let i = 0; i < amountComp; i++){
        //left door
        closetPlanks[0].push(makePlank(scene, -width/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+(compWidth-2*doorLeftRightSideDiff)/scale/4, -height/scale/2-closetHeight/scale/2+outerPlankOverlap/scale/2-waterproofPlateHeight/scale, depth/scale/2+closetThickness/scale/2, (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, closetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        //right door
        closetPlanks[0].push(makePlank(scene, -width/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+3*(compWidth-2*doorLeftRightSideDiff)/scale/4, -height/scale/2-closetHeight/scale/2+outerPlankOverlap/scale/2-waterproofPlateHeight/scale, depth/scale/2+closetThickness/scale/2, (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, closetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
    }
    // door frame
    makeFrontPlank(scene, width, height, closetHeight, depth, waterproofPlateHeight, closetPlanks)
    
    currWidth = width;
    currHeight = height;
    currDepth = depth;
    currLowerClosetHeight = closetHeight;
    currWaterproofPlateHeight = waterproofPlateHeight
    //everything is now on an angle of 0 degrees and here we make sure the angle is right
    let tempAngle = currAngle
    currAngle = 0
    turnAluminiumDoors(tempAngle, closetPlanks);
    changeAluminiumDoorsTexture("", closetPlanks)
}

// door frame
function  makeFrontPlank(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, waterproofPlateHeight: number, closetPlanks: THREE.Mesh[][], index = 0){
    let amountComp = getAmountCompartiments(width)
    let compWidth = width/amountComp;
    for(let i = 0; i < amountComp; i++){// for every 2 doors there is a doorframe made because you can only make 1 hole in a shape
        let shape = new THREE.Shape();

        shape.moveTo(-width/scale/2+i*compWidth/scale-closetThickness/scale, -height/scale/2+outerPlankOverlap/scale-waterproofPlateHeight/scale);
        shape.lineTo(-width/scale/2+i*compWidth/scale-closetThickness/scale, -height/scale/2-closetHeight/scale-waterproofPlateHeight/scale);
        shape.lineTo(-width/scale/2+(i+1)*compWidth/scale+closetThickness/scale, -height/scale/2-closetHeight/scale-waterproofPlateHeight/scale);
        shape.lineTo(-width/scale/2+(i+1)*compWidth/scale+closetThickness/scale, -height/scale/2+outerPlankOverlap/scale-waterproofPlateHeight/scale);
        shape.lineTo(-width/scale/2+i*compWidth/scale-closetThickness/scale, -height/scale/2+outerPlankOverlap/scale-waterproofPlateHeight/scale);

        shape.moveTo(-width/scale/2+doorLeftRightSideDiff/scale+i*compWidth/scale, -height/scale/2+outerPlankOverlap/scale-doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        shape.lineTo(-width/scale/2-doorLeftRightSideDiff/scale+(i+1)*compWidth/scale, -height/scale/2+outerPlankOverlap/scale-doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        shape.lineTo(-width/scale/2-doorLeftRightSideDiff/scale+(i+1)*compWidth/scale, -height/scale/2-closetHeight/scale+doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        shape.lineTo(-width/scale/2+doorLeftRightSideDiff/scale+i*compWidth/scale, -height/scale/2-closetHeight/scale+doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        shape.lineTo(-width/scale/2+doorLeftRightSideDiff/scale+i*compWidth/scale, -height/scale/2+outerPlankOverlap/scale-doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        
        let shapeGeo = new THREE.ExtrudeGeometry(shape, {depth: closetThickness/scale, bevelEnabled: false});
        let shapeMat = new THREE.MeshPhongMaterial({color: 0x3d3d3d, shininess: 50, emissive:0x3d3d3d});

        let shapeMesh = new THREE.Mesh(shapeGeo, shapeMat);
        shapeMesh.position.z = depth/scale/2+closetThickness/scale/2
        scene.add(shapeMesh);
        closetPlanks[index].push(shapeMesh)
    }
}

// makes upper doors
export function makeUpperAluminiumDoors(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, closetPlanks: THREE.Mesh[][]){
    
    if(closetHeight === 0){
        for(let i = 0; i < closetPlanks[1].length; i++){
            scene.remove(closetPlanks[1][i])
        }
        currUpperClosetHeight = closetHeight
        return
    }
    if(closetPlanks.length < 2){
        closetPlanks.push([])
        closetPlanks.push([])
    }
    
    let amountComp = getAmountCompartiments(width)
    let compWidth = width/amountComp;
    for(let i = 0; i < amountComp; i++){
        // left door
        closetPlanks[1].push(makePlank(scene, -width/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+(compWidth-2*doorLeftRightSideDiff)/scale/4, height/scale/2+closetHeight/scale/2-outerPlankOverlap/scale/2, depth/scale/2+closetThickness/scale/2, (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, closetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        // right door
        closetPlanks[1].push(makePlank(scene, -width/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+3*(compWidth-2*doorLeftRightSideDiff)/scale/4, height/scale/2+closetHeight/scale/2-outerPlankOverlap/scale/2, depth/scale/2+closetThickness/scale/2, (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, closetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
    }
    // doorframe, the height doesn't really make sense, but the makefrontplanks was made before i thought about the upper planks, so you have to give a height so that it goes up in stead of down 
    makeFrontPlank(scene, width, -(height+2*closetHeight)+2*outerPlankOverlap, closetHeight, depth,0, closetPlanks,1);

    currWidth = width;
    currHeight = height;
    currDepth = depth;
    currUpperClosetHeight = closetHeight;
    //everything is now on an angle of 0 degrees and here we make sure the angle is right
    let tempAngle = currAngle
    currAngle = 0
    turnAluminiumDoors(tempAngle, closetPlanks);
    changeAluminiumDoorsTexture("", closetPlanks)
}

//if the width of the aquarium is changed, the doors should be changed as well
export function changeAluminiumDoorWidth(scene: THREE.Scene, newWidth: number, closetPlanks: THREE.Mesh[][]){
    // first remove all the doors
    for(let i = 0; i < 2; i++){
        for(let j = closetPlanks[i].length-1; j >= 0 ; j--){
            scene.remove(closetPlanks[i][j])
            closetPlanks[i].splice(j,1)
        }
    }
    let amountComp = getAmountCompartiments(newWidth)
    let compWidth = newWidth/amountComp;
    for(let i = 0; i < amountComp; i++){
        // left lower door
        closetPlanks[0].push(makePlank(scene, -newWidth/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+(compWidth-2*doorLeftRightSideDiff)/scale/4, -currHeight/scale/2-currLowerClosetHeight/scale/2+outerPlankOverlap/scale/2-currWaterproofPlateHeight/scale, currDepth/scale/2+closetThickness/scale/2, (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, currLowerClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        // right lower door
        closetPlanks[0].push(makePlank(scene, -newWidth/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+3*(compWidth-2*doorLeftRightSideDiff)/scale/4, -currHeight/scale/2-currLowerClosetHeight/scale/2+outerPlankOverlap/scale/2-currWaterproofPlateHeight/scale, currDepth/scale/2+closetThickness/scale/2, (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, currLowerClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
    
        if(currUpperClosetHeight > 0){
            //left upper door
            closetPlanks[1].push(makePlank(scene, -newWidth/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+(compWidth-2*doorLeftRightSideDiff)/scale/4, currHeight/scale/2+currUpperClosetHeight/scale/2-outerPlankOverlap/scale/2, currDepth/scale/2+closetThickness/scale/2, (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, currUpperClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
            // right upper door
            closetPlanks[1].push(makePlank(scene, -newWidth/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+3*(compWidth-2*doorLeftRightSideDiff)/scale/4, currHeight/scale/2+currUpperClosetHeight/scale/2-outerPlankOverlap/scale/2, currDepth/scale/2+closetThickness/scale/2, (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, currUpperClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        }
    }
    //door frame
    makeFrontPlank(scene, newWidth, currHeight, currLowerClosetHeight, currDepth, currWaterproofPlateHeight, closetPlanks)
    if(currUpperClosetHeight > 0){
        makeFrontPlank(scene, newWidth, -(currHeight+2*currUpperClosetHeight)+2*outerPlankOverlap, currUpperClosetHeight, currDepth, 0, closetPlanks,1);
    }
    currWidth = newWidth
    //everything is now on an angle of 0 degrees and here we make sure the angle is right
    let tempAngle = currAngle
    currAngle = 0
    turnAluminiumDoors(tempAngle, closetPlanks);
    changeAluminiumDoorsTexture("", closetPlanks)
}

// if height of aquarium is changed
export function changeAluminiumDoorHeight(scene: THREE.Scene, newHeight: number, closetPlanks: THREE.Mesh[][]){
    if(newHeight === currHeight){
        return
    }
    // lift the upper doors up
    for(let j = 0; j < closetPlanks[0].length ; j++){
        closetPlanks[0][j].position.y -= (newHeight-currHeight)/scale/2
    }

    // put the lower doors down
    for(let j = 0; j < closetPlanks[1].length ; j++){
        closetPlanks[1][j].position.y += (newHeight-currHeight)/scale/2
    }

    currHeight = newHeight;
    changeAluminiumDoorsTexture("", closetPlanks)
}

// if depth of aquarium is changed
export function changeAluminiumDoorDepth(scene: THREE.Scene, newDepth: number, closetPlanks: THREE.Mesh[][]){
    if(currDepth === newDepth){
        return
    }
    // put everything further to the front
    for(let i = 0; i < 2; i++){
        for(let j = 0; j < closetPlanks[i].length; j++){
            closetPlanks[i][j].position.z += (newDepth-currDepth)/scale/2
        }
    }

    currDepth = newDepth
    changeAluminiumDoorsTexture("", closetPlanks)
}

// if the height of the lower furniture is changed
export function changeAluminiumLowerDoorHeight(scene: THREE.Scene, newClosetHeight: number, closetPlanks: THREE.Mesh[][]){
    if(currLowerClosetHeight === newClosetHeight){
        return;
    }
    // remove everything from the lower doors
    for(let i = closetPlanks[0].length-1; i >= 0; i--){
        scene.remove(closetPlanks[0][i])
        closetPlanks[0].splice(i,1);
    }
    let tempAngle = currAngle
    // set the upper doors in angle 0
    turnAluminiumDoors(-currAngle, closetPlanks)
    // make lower doors
    makeAluminiumDoors(scene, currWidth, currHeight, newClosetHeight, currDepth, currWaterproofPlateHeight, closetPlanks);
    // set everything in right angle
    turnAluminiumDoors(tempAngle, closetPlanks)
    changeAluminiumDoorsTexture("", closetPlanks)
}

// if height of upper furniture is changed
export function changeAluminiumUpperDoorHeight(scene: THREE.Scene, newClosetHeight: number, closetPlanks: THREE.Mesh[][]){
    // remove all the upperdoors
    for(let i = closetPlanks[1].length-1; i >= 0; i--){
        scene.remove(closetPlanks[1][i])
        closetPlanks[1].splice(i,1);
    }
    // if the upper doors should be made
    if(newClosetHeight > 0){
        let tempAngle = currAngle
        turnAluminiumDoors(-currAngle, closetPlanks)// put lower doors on angle 0
        makeUpperAluminiumDoors(scene, currWidth, currHeight, newClosetHeight, currDepth, closetPlanks)// make upper doors
        turnAluminiumDoors(tempAngle, closetPlanks)// put everything in right angle
    }
    changeAluminiumDoorsTexture("", closetPlanks)
    currUpperClosetHeight = newClosetHeight
}

// calculates how many compartiments there are, so how many doors should be made
// widthdistribution should contain the maximum width of the aquarium + 1
function getAmountCompartiments(width: number){
    // take widthdistribution and look at the first number where width is smaller and return that index + 2
    for(let i = widthDistribution.length-1; i >= 0; i--){
        if(width > widthDistribution[i]){
            return i+2
        }
    }
    return 1;
}

// turn the doors
// angle is the angle the door has to turn in current configuration
export function turnAluminiumDoors(angle: number, closetPlanks: THREE.Mesh[][]){
    if(closetPlanks.length === 0){
        return;
    }
    // make sure currAngle isn't bigger than 2*PI radians (=360°)
    currAngle=(currAngle+angle)%(2*PI);

    let amount_comp = getAmountCompartiments(currWidth);
    let compWidth = currWidth/amount_comp;
    for(let j = 0; j < 2; j++){//turn both lower and upper doors
        //the first 2/3 of closetplanks are the doors, the last 1/3 is the doorframe
        // in this for loop we do 2 doors at the same time, that's why it is closetPlanks.lenght/3
        for(let i = 0; i < closetPlanks[j].length/3; i++){
            // planks coordinates are the middle points of the planks, so that is what we are looking for here in new_x and new_z
            //most left point of the door
            let old_x = -currWidth/scale/2+doorLeftRightSideDiff/scale+i*compWidth/scale
            let old_z = currDepth/scale/2+closetThickness/scale/2
            //new middle point
            let new_x = old_x+((compWidth-2*doorLeftRightSideDiff)/scale/2-0.01)*Math.cos(currAngle)/2
            let new_z = old_z+((compWidth-2*doorLeftRightSideDiff)/scale/2-0.01)*Math.sin(currAngle)/2
            //put door in right position
            closetPlanks[j][2*i].position.x = new_x;
            closetPlanks[j][2*i].position.z = new_z;
            closetPlanks[j][2*i].rotateY(-angle)
            
            //most right point of the door
            old_x = -currWidth/scale/2-doorLeftRightSideDiff/scale+(i+1)*compWidth/scale
            //new middle point
            new_x = old_x+((compWidth-2*doorLeftRightSideDiff)/scale/2-0.01)*Math.cos(currAngle)/2*-1
            new_z = old_z+((compWidth-2*doorLeftRightSideDiff)/scale/2-0.01)*Math.sin(currAngle)/2
            closetPlanks[j][2*i+1].position.x = new_x;
            closetPlanks[j][2*i+1].position.z = new_z;
            closetPlanks[j][2*i+1].rotateY(angle)
        }
    }
}

// returns the angle the doors are in now, tho close them or something like that
export function getAluminiumDoorAngle(){
    return currAngle;
}

export function aluminiumDoorsChangeWaterProofPlateHeight(newWaterProofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
    for(let i = 0; i < closetPlanks[0].length; i++){
        closetPlanks[0][i].position.y += (currWaterproofPlateHeight-newWaterProofPlateHeight)/scale
    }
    currWaterproofPlateHeight = newWaterProofPlateHeight
}

export function changeAluminiumDoorsTexture(texture: String, closetPlanks: THREE.Mesh[][]){
    if(texture !== ""){
        currTexture = [new THREE.TextureLoader().load(String(texture)),new THREE.TextureLoader().load(String(texture))]
    }
    let amountComp = getAmountCompartiments(currWidth)
    let compWidth = currWidth/amountComp;
    if(currTexture.length !== 0){
        for(let i = 0; i < amountComp*2; i++){
            //lower doors
            closetPlanks[0][i].material = makeGeometryMaterials(currTexture[0], (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, closetThickness/scale, currLowerClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01)
        }
        for(let i = amountComp*2; i < amountComp*3; i++){
            //doorframe
            closetPlanks[0][i].material = makeGeometryMaterials(currTexture[0], compWidth/scale, closetThickness/scale, currLowerClosetHeight/scale+outerPlankOverlap/scale)
        }
        if(currUpperClosetHeight > 0){
            for(let i = 0; i < amountComp*2; i++){
                //upper doors
                closetPlanks[1][i].material = makeGeometryMaterials(currTexture[1], (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, closetThickness/scale, currUpperClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01)
            }
            for(let i = amountComp*2; i < amountComp*3; i++){
                //doorframe
                closetPlanks[1][i].material = makeGeometryMaterials(currTexture[0], compWidth/scale, closetThickness/scale, currUpperClosetHeight/scale+outerPlankOverlap/scale)
            }
        }
    }
}