import * as THREE from "three";
import { makeGeometryMaterials, makePlank } from "./helper";
import {
  changeSkeletonRearWall,
  changeSkeletonBetonPlex,
  changeSkeletonDepth,
  changeSkeletonHeight,
  changeSkeletonLowerClosetHeight,
  changeSkeletonWidth,
  getSkeletonFilterHeight,
  makeSkeletonCloset,
  makeElectraRoom,
  skeletonChangeWaterProofPlateHeight,
  changeElectraSwitchVisibility,
} from "./skeleton";
import { ElectraRuimte } from "../../@types/aquarium";
import { DecorTexture } from "../../@types/textures";
import { ClosetDimensions } from "../../@types/configurator";

const scale = 25;
const PI = 3.14159265359;

const closetThickness = 1.8;
const widthDistribution = [70, 139, 241, 321, 361];
const upperBottomPlankStripDepth = 4; //depth of front plank of the upper closet that is on the aquarium
const outerPlankOverlap = 4; //overlap between planks and aquarium, so the planks are this much above or below the bottom or ceiling of the aquarium

let currWidth: number;
let currHeight: number;
let currDepth: number;
let currLowerClosetHeight: number;
let currUpperClosetHeight = 0;
let seeThroughAqua = false;
let currWaterproofPlateHeight = 0
let currTexture: THREE.Texture[] = [];

export function makeDecorFinishCloset(
  scene: THREE.Scene,
  width: number,
  height: number,
  closetHeight: number,
  depth: number,
  waterproofPlateHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  makeSkeletonCloset(scene, width, height, closetHeight, depth, waterproofPlateHeight, closetPlanks);
  if(currTexture.length !== 2)
    currTexture.push()
  closetPlanks.push([]);
  //back outer plank
  closetPlanks[5].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 -
        closetHeight / scale / 2 +
        closetThickness / scale / 2 +
        outerPlankOverlap / scale / 2 -
        waterproofPlateHeight/scale,
      -depth / scale / 2 - closetThickness / scale / 2,
      width / scale + (2 * closetThickness) / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      closetThickness / scale,
      0x3d3d3d
    )
  );
  //left outer plank
  closetPlanks[5].push(
    makePlank(
      scene,
      -width / scale / 2 - closetThickness / scale / 2,
      -height / scale / 2 -
        closetHeight / scale / 2 +
        closetThickness / scale / 2 +
        outerPlankOverlap / scale / 2 -
        waterproofPlateHeight/scale,
      0,
      closetThickness / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //right outer plank
  closetPlanks[5].push(
    makePlank(
      scene,
      width / scale / 2 + closetThickness / scale / 2,
      -height / scale / 2 -
        closetHeight / scale / 2 +
        closetThickness / scale / 2 +
        outerPlankOverlap / scale / 2 -
        waterproofPlateHeight/scale,
      0,
      closetThickness / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  if (!seeThroughAqua) {
    scene.remove(closetPlanks[5][0]);
  }

  currWidth = width;
  currHeight = height;
  currLowerClosetHeight = closetHeight;
  currDepth = depth;
  changeDecorTexture("", closetPlanks)
}

function makeDecorFinishUpperCloset(
  scene: THREE.Scene,
  width: number,
  height: number,
  closetHeight: number,
  depth: number,
  closetPlanks: THREE.Mesh[][]
) {
  closetPlanks[6] = [];
  let amountComp = getAmountCompartiments(width);
  //back
  closetPlanks[6].push(
    makePlank(
      scene,
      0,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2,
      -depth / scale / 2 + closetThickness / scale / 2,
      width / scale - (2 * closetThickness) / scale,
      closetHeight / scale - closetThickness / scale,
      closetThickness / scale,
      0x3d3d3d
    )
  );
  //left
  closetPlanks[6].push(
    makePlank(
      scene,
      -width / scale / 2 + closetThickness / scale / 2,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2,
      0,
      closetThickness / scale,
      closetHeight / scale - closetThickness / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //right
  closetPlanks[6].push(
    makePlank(
      scene,
      width / scale / 2 - closetThickness / scale / 2,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2,
      0,
      closetThickness / scale,
      closetHeight / scale - closetThickness / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //bottomstrip in front
  closetPlanks[6].push(
    makePlank(
      scene,
      0,
      height / scale / 2 + closetThickness / scale / 2,
      depth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
      width / scale - (2 * closetThickness) / scale,
      closetThickness / scale,
      upperBottomPlankStripDepth / scale,
      0x3d3d3d
    )
  );
  //ceiling
  closetPlanks[6].push(
    makePlank(
      scene,
      0,
      height / scale / 2 + closetHeight / scale - closetThickness / scale / 2,
      0,
      width / scale,
      closetThickness / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //back outer plank
  closetPlanks[6].push(
    makePlank(
      scene,
      0,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2 -
        outerPlankOverlap / scale / 2,
      -depth / scale / 2 - closetThickness / scale / 2,
      width / scale + (2 * closetThickness) / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      closetThickness / scale,
      0x3d3d3d
    )
  );
  //left outer plank
  closetPlanks[6].push(
    makePlank(
      scene,
      -width / scale / 2 - closetThickness / scale / 2,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2 -
        outerPlankOverlap / scale / 2,
      0,
      closetThickness / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  //right outer plank
  closetPlanks[6].push(
    makePlank(
      scene,
      width / scale / 2 + closetThickness / scale / 2,
      height / scale / 2 +
        closetHeight / scale / 2 -
        closetThickness / scale / 2 -
        outerPlankOverlap / scale / 2,
      0,
      closetThickness / scale,
      closetHeight / scale + outerPlankOverlap / scale,
      depth / scale,
      0x3d3d3d
    )
  );
  if (!seeThroughAqua) {
    scene.remove(closetPlanks[6][5]);
  }

  closetPlanks.push([]);
  closetPlanks.push([]);

  if (closetHeight >= 75) {
    let compWidth = width / amountComp;
    //compartiment planks
    for (let i = 1; i < amountComp; i++) {
      //vertical plank
      closetPlanks[7].push(
        makePlank(
          scene,
          -width / scale / 2 + (i * compWidth) / scale,
          height / scale / 2 + (3 * closetHeight) / scale / 4 - closetThickness/scale/2,
          closetThickness / scale / 2,
          closetThickness / scale,
          closetHeight / scale / 2 - (2 * closetThickness) / scale,
          depth / scale - closetThickness / scale,
          0x3d3d3d
        )
      );
      let lowerDoorHangPlank = makePlank(
        scene,
        -width / scale / 2 + (i * compWidth) / scale,
        height / scale / 2 + closetHeight / scale / 4,
        depth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
        closetThickness / scale,
        closetHeight / scale / 2 - (2 * closetThickness) / scale,
        upperBottomPlankStripDepth / scale,
        0x3d3d3d
      );
      closetPlanks[7].push(lowerDoorHangPlank);
      if (i % 2 === 1) {
        scene.remove(lowerDoorHangPlank);
      }
    }
    closetPlanks[8].push(
      makePlank(
        scene,
        0,
        height / scale / 2 + closetHeight / scale / 2,
        closetThickness / scale / 2,
        width / scale - (2 * closetThickness) / scale,
        closetThickness / scale,
        depth / scale - closetThickness / scale,
        0x3d3d3d
      )
    );
  } else {
    let compWidth = width / amountComp;
    //compartiment planks
    for (let i = 2; i < amountComp; i += 2) {
      //vertical plank
      closetPlanks[7].push(
        makePlank(
          scene,
          -width / scale / 2 + (i * compWidth) / scale,
          height / scale / 2 + closetHeight / scale / 2,
          currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
          closetThickness / scale,
          closetHeight / scale - (2 * closetThickness) / scale,
          upperBottomPlankStripDepth / scale,
          0x3d3d3d
        )
      );
    }
  }
  currWidth = width;
  currHeight = height;
  currUpperClosetHeight = closetHeight;
  currDepth = depth;
  changeDecorTexture("", closetPlanks)
}

export function changeDecorWidth(
  scene: THREE.Scene,
  newWidth: number,
  closetPlanks: THREE.Mesh[][]
) {
  closetPlanks[5][0].geometry.scale(
    (newWidth / scale + (2 * closetThickness) / scale) /
      (currWidth / scale + (2 * closetThickness) / scale),
    1,
    1
  );
  closetPlanks[5][1].position.x -= (newWidth - currWidth) / scale / 2;
  closetPlanks[5][2].position.x += (newWidth - currWidth) / scale / 2;

  if (closetPlanks.length > 6 && closetPlanks[6].length > 0) {
    closetPlanks[6][0].geometry.scale(
      (newWidth / scale - (2 * closetThickness) / scale) /
        (currWidth / scale - (2 * closetThickness) / scale),
      1,
      1
    );
    closetPlanks[6][1].position.x -= (newWidth - currWidth) / scale / 2;
    closetPlanks[6][2].position.x += (newWidth - currWidth) / scale / 2;
    closetPlanks[6][3].geometry.scale(
      (newWidth / scale - (2 * closetThickness) / scale) /
        (currWidth / scale - (2 * closetThickness) / scale),
      1,
      1
    );
    closetPlanks[6][4].geometry.scale(newWidth / currWidth, 1, 1);
    closetPlanks[6][5].geometry.scale(
      (newWidth / scale + (2 * closetThickness) / scale) /
        (currWidth / scale + (2 * closetThickness) / scale),
      1,
      1
    );
    closetPlanks[6][6].position.x -= (newWidth - currWidth) / scale / 2;
    closetPlanks[6][7].position.x += (newWidth - currWidth) / scale / 2;

    let amountComp = getAmountCompartiments(newWidth);
    if (currUpperClosetHeight >= 75) {
      let compWidth = newWidth / amountComp;
      //compartiment planks
      for (let i = closetPlanks[7].length - 1; i >= 0; i--) {
        scene.remove(closetPlanks[7][i]);
        closetPlanks[7].splice(i, 1);
      }

      for (let i = 1; i < amountComp; i++) {
        //vertical plank
        closetPlanks[7].push(
          makePlank(
            scene,
            -newWidth / scale / 2 + (i * compWidth) / scale,
            currHeight / scale / 2 + (3 * currUpperClosetHeight) / scale / 4-closetThickness/scale/2,
            closetThickness / scale / 2,
            closetThickness / scale,
            currUpperClosetHeight / scale / 2 - (2 * closetThickness) / scale,
            currDepth / scale - closetThickness / scale,
            0x3d3d3d
          )
        );
        let lowerDoorHangPlank = makePlank(
          scene,
          -newWidth / scale / 2 + (i * compWidth) / scale,
          currHeight / scale / 2 + currUpperClosetHeight / scale / 4,
          currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
          closetThickness / scale,
          currUpperClosetHeight / scale / 2 - (2 * closetThickness) / scale,
          upperBottomPlankStripDepth / scale,
          0x3d3d3d
        );
        closetPlanks[7].push(lowerDoorHangPlank);
        if (i % 2 === 1) {
          scene.remove(lowerDoorHangPlank);
        }
      }
      scene.remove(closetPlanks[8][0]);
      closetPlanks[8][0] = makePlank(
        scene,
        0,
        currHeight / scale / 2 + currUpperClosetHeight / scale / 2,
        closetThickness / scale / 2,
        newWidth / scale - (2 * closetThickness) / scale,
        closetThickness / scale,
        currDepth / scale - closetThickness / scale,
        0x3d3d3d
      );
    } else {
      let compWidth = newWidth / amountComp;
      //compartiment planks
      for (let i = closetPlanks[7].length - 1; i >= 0; i--) {
        scene.remove(closetPlanks[7][i]);
        closetPlanks[7].splice(i, 1);
      }

      for (let i = 2; i < amountComp; i += 2) {
        //vertical plank
        closetPlanks[7].push(
          makePlank(
            scene,
            -newWidth / scale / 2 + (i * compWidth) / scale,
            currHeight / scale / 2 + currUpperClosetHeight / scale / 2,
            currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
            closetThickness / scale,
            currUpperClosetHeight / scale - (2 * closetThickness) / scale,
            upperBottomPlankStripDepth / scale,
            0x3d3d3d
          )
        );
      }
    }
  }

  changeSkeletonWidth(scene, newWidth, closetPlanks);
  currWidth = newWidth;
  changeDecorTexture("", closetPlanks)
}

export function changeDecorHeight(
  scene: THREE.Scene,
  newHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  changeSkeletonHeight(scene, newHeight, closetPlanks);
  for (let i = 0; i < 3; i++) {
    closetPlanks[5][i].position.y -= (newHeight - currHeight) / scale / 2;
  }
  if (currUpperClosetHeight > 0 && closetPlanks.length > 6) {
    for (let i = 6; i < 9; i++) {
      for (let j = 0; j < closetPlanks[i].length; j++) {
        closetPlanks[i][j].position.y += (newHeight - currHeight) / scale / 2;
      }
    }
  }

  currHeight = newHeight;
  changeDecorTexture("", closetPlanks)
}

export function changeDecorLowerClosetHeight(
  scene: THREE.Scene,
  newClosetHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  changeSkeletonLowerClosetHeight(scene, newClosetHeight, closetPlanks);
  for (let i = 0; i < 3; i++) {
    closetPlanks[5][i].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale / 2;
    closetPlanks[5][i].geometry.scale(
      1,
      (newClosetHeight / scale + outerPlankOverlap / scale) /
        (currLowerClosetHeight / scale + outerPlankOverlap / scale),
      1
    );
  }
  currLowerClosetHeight = newClosetHeight;
  changeDecorTexture("", closetPlanks)
}

export function changeDecorUpperClosetHeight(
  scene: THREE.Scene,
  newClosetHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  if (newClosetHeight === 0 && closetPlanks.length >= 9) {
    for (let j = 6; j < 9; j++) {
      for (let i = closetPlanks[j].length - 1; i >= 0; i--) {
        scene.remove(closetPlanks[j][i]);
        closetPlanks[j].splice(i, 1);
      }
    }
    currUpperClosetHeight = newClosetHeight;
    changeDecorTexture("", closetPlanks)
    return;
  }

  if (
    currUpperClosetHeight === 0 ||
    closetPlanks.length < 6 ||
    closetPlanks[6].length === 0
  ) {
    if(newClosetHeight !== 0)
      makeDecorFinishUpperCloset(
        scene,
        currWidth,
        currHeight,
        newClosetHeight,
        currDepth,
        closetPlanks
      );
    currUpperClosetHeight = newClosetHeight;
    changeDecorTexture("", closetPlanks)
    return;
  }
  closetPlanks[6][0].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][1].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][2].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][4].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale;
  closetPlanks[6][0].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currUpperClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[6][1].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currUpperClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[6][2].geometry.scale(
    1,
    (newClosetHeight / scale - (2 * closetThickness) / scale) /
      (currUpperClosetHeight / scale - (2 * closetThickness) / scale),
    1
  );
  closetPlanks[6][5].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][6].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][7].position.y +=
    (newClosetHeight - currUpperClosetHeight) / scale / 2;
  closetPlanks[6][5].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currUpperClosetHeight / scale + outerPlankOverlap / scale),
    1
  );
  closetPlanks[6][6].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currUpperClosetHeight / scale + outerPlankOverlap / scale),
    1
  );
  closetPlanks[6][7].geometry.scale(
    1,
    (newClosetHeight / scale + outerPlankOverlap / scale) /
      (currUpperClosetHeight / scale + outerPlankOverlap / scale),
    1
  );

  let amountComp = getAmountCompartiments(currWidth);

  if (closetPlanks[7].length < amountComp && newClosetHeight >= 75) {
    let compWidth = currWidth / amountComp;

    for (let i = closetPlanks[7].length - 1; i >= 0; i--) {
      scene.remove(closetPlanks[7][i]);
      closetPlanks[7].splice(i, 1);
    }

    //compartiment planks
    for (let i = 1; i < amountComp; i++) {
      //vertical plank
      closetPlanks[7].push(
        makePlank(
          scene,
          -currWidth / scale / 2 + (i * compWidth) / scale,
          currHeight / scale / 2 + (3 * newClosetHeight) / scale / 4-closetThickness/scale/2,
          closetThickness / scale / 2,
          closetThickness / scale,
          newClosetHeight / scale / 2 - (2 * closetThickness) / scale,
          currDepth / scale - closetThickness / scale,
          0x3d3d3d
        )
      );
      let lowerDoorHangPlank = makePlank(
        scene,
        -currWidth / scale / 2 + (i * compWidth) / scale,
        currHeight / scale / 2 + newClosetHeight / scale / 4,
        currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
        closetThickness / scale,
        newClosetHeight / scale / 2 - (2 * closetThickness) / scale,
        upperBottomPlankStripDepth / scale,
        0x3d3d3d
      );
      closetPlanks[7].push(lowerDoorHangPlank);
      if (i % 2 === 1) {
        scene.remove(lowerDoorHangPlank);
      }
    }
    if (closetPlanks[8].length === 0)
      closetPlanks[8][0] = makePlank(
        scene,
        0,
        currHeight / scale / 2 + newClosetHeight / scale / 2,
        closetThickness / scale / 2,
        currWidth / scale - (2 * closetThickness) / scale,
        closetThickness / scale,
        currDepth / scale - closetThickness / scale,
        0x3d3d3d
      );
    else
      closetPlanks[8][0].position.y +=
        (newClosetHeight - currUpperClosetHeight) / scale / 2;
  } else if (newClosetHeight >= 75) {
    for (let i = 0; i < amountComp - 1; i++) {
      closetPlanks[7][2 * i].geometry.scale(
        1,
        (newClosetHeight / scale / 2 - (2 * closetThickness) / scale) /
          (currUpperClosetHeight / scale / 2 - (2 * closetThickness) / scale),
        1
      );
      closetPlanks[7][2 * i].position.y +=
        (newClosetHeight - currUpperClosetHeight) / scale / 2;
      closetPlanks[7][2 * i + 1].geometry.scale(
        1,
        (newClosetHeight / scale / 2 - (2 * closetThickness) / scale) /
          (currUpperClosetHeight / scale / 2 - (2 * closetThickness) / scale),
        1
      );
      closetPlanks[7][2 * i + 1].position.y +=
        (newClosetHeight - currUpperClosetHeight) / scale / 4;
    }
    closetPlanks[8][0].position.y +=
      (newClosetHeight - currUpperClosetHeight) / scale / 2;
  } else {
    for (let i = closetPlanks[7].length - 1; i >= 0; i--) {
      scene.remove(closetPlanks[7][i]);
      closetPlanks[7].splice(i, 1);
    }

    let compWidth = currWidth / amountComp;
    for (let i = 2; i < amountComp; i += 2) {
      //vertical plank
      closetPlanks[7].push(
        makePlank(
          scene,
          -currWidth / scale / 2 + (i * compWidth) / scale,
          currHeight / scale / 2 + newClosetHeight / scale / 2,
          currDepth / scale / 2 - upperBottomPlankStripDepth / scale / 2,
          closetThickness / scale,
          newClosetHeight / scale - (2 * closetThickness) / scale,
          upperBottomPlankStripDepth / scale,
          0x3d3d3d
        )
      );
    }
    if (closetPlanks[8].length > 0) {
      scene.remove(closetPlanks[8][0]);
      closetPlanks[8].splice(0, 1);
    }
  }
  currUpperClosetHeight = newClosetHeight;
  changeDecorTexture("", closetPlanks)
}

export function changeDecorDepth(
  scene: THREE.Scene,
  newDepth: number,
  closetPlanks: THREE.Mesh[][]
) {
  changeSkeletonDepth(scene, newDepth, closetPlanks);
  closetPlanks[5][0].position.z -= (newDepth - currDepth) / scale / 2;
  closetPlanks[5][1].geometry.scale(1, 1, newDepth / currDepth);
  closetPlanks[5][2].geometry.scale(1, 1, newDepth / currDepth);

  if (closetPlanks.length > 6 && closetPlanks[6].length > 0) {
    closetPlanks[6][0].position.z -= (newDepth - currDepth) / scale / 2;
    closetPlanks[6][1].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][2].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][3].position.z += (newDepth - currDepth) / scale / 2;
    closetPlanks[6][4].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][5].position.z -= (newDepth - currDepth) / scale / 2;
    closetPlanks[6][6].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][7].geometry.scale(1, 1, newDepth / currDepth);

    if (currUpperClosetHeight >= 75) {
      for (let j = 0; j < closetPlanks[7].length; j += 2) {
        closetPlanks[7][j].geometry.scale(
          1,
          1,
          (newDepth / scale - closetThickness / scale) /
            (currDepth / scale - closetThickness / scale)
        );
        closetPlanks[7][j + 1].position.z += (newDepth - currDepth) / scale / 2;
      }
      for (let j = 0; j < closetPlanks[8].length; j++) {
        closetPlanks[8][j].geometry.scale(
          1,
          1,
          (newDepth / scale - closetThickness / scale) /
            (currDepth / scale - closetThickness / scale)
        );
      }
    }
  }
  currDepth = newDepth;
  changeDecorTexture("", closetPlanks)
}

export function makeDecorSeethrough(
  scene: THREE.Scene,
  seeThrough: boolean,
  closetPlanks: THREE.Mesh[][]
) {
  if (seeThrough) {
    scene.add(closetPlanks[5][0]);
    if (currUpperClosetHeight > 0) {
      scene.add(closetPlanks[6][5]);
    }
  } else {
    scene.remove(closetPlanks[5][0]);
    if (currUpperClosetHeight > 0) {
      scene.remove(closetPlanks[6][5]);
    }
  }
  seeThroughAqua = seeThrough;
}

function getAmountCompartiments(width: number) {
  for (let i = widthDistribution.length - 1; i >= 0; i--) {
    if (width > widthDistribution[i]) {
      return (i + 1) * 2;
    }
  }
  return 1;
}

export function getDecorFilterHeight(): number {
  return getSkeletonFilterHeight();
}

export function changeDecorBetonPlex(
  scene: THREE.Scene,
  newBetonPlexVisible: boolean,
  closetPlanks: THREE.Mesh[][]
) {
  changeSkeletonBetonPlex(scene, newBetonPlexVisible, closetPlanks);
}

export function changeDecorRearWall(
  scene: THREE.Scene,
  newRearWallVisible: boolean,
  closetPlanks: THREE.Mesh[][]
) {
  changeSkeletonRearWall(scene, newRearWallVisible, closetPlanks);
}

export function changeDecorElectraRuimte(
  scene: THREE.Scene,
  electraRuimte: ElectraRuimte,
  closetPlanks: THREE.Mesh[][]
) {
  makeElectraRoom(scene, electraRuimte, closetPlanks);
}

export function decorFinishChangeWaterProofPlateHeight(newWaterProofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
  for(let i = 0; i < closetPlanks[5].length; i++){
      closetPlanks[5][i].position.y += (currWaterproofPlateHeight-newWaterProofPlateHeight)/scale
  }
  currWaterproofPlateHeight = newWaterProofPlateHeight
  skeletonChangeWaterProofPlateHeight(newWaterProofPlateHeight, closetPlanks)
}

export function changeDecorTexture(texture: String, closetPlanks: THREE.Mesh[][]){
  if(texture !== "")
    currTexture = [new THREE.TextureLoader().load(String(texture)), new THREE.TextureLoader().load(String(texture)),new THREE.TextureLoader().load(String(texture)), new THREE.TextureLoader().load(String(texture))]

  if(currTexture.length === 4){
    closetPlanks[5][0].material = makeGeometryMaterials(currTexture[0], currWidth/scale  + (2 * closetThickness/scale) ,  closetThickness/scale,  currLowerClosetHeight/scale + outerPlankOverlap/scale)
    closetPlanks[5][1].material = makeGeometryMaterials(currTexture[1], currDepth/scale, closetThickness/scale, currLowerClosetHeight/scale + outerPlankOverlap/scale)
    closetPlanks[5][2].material = makeGeometryMaterials(currTexture[1], currDepth/scale, closetThickness/scale, currLowerClosetHeight/scale + outerPlankOverlap/scale)
    if(currUpperClosetHeight > 0){
      closetPlanks[6][5].material = makeGeometryMaterials(currTexture[2], currWidth/scale  + (2 * closetThickness/scale) ,  closetThickness/scale,  currUpperClosetHeight/scale + outerPlankOverlap/scale)
      closetPlanks[6][6].material = makeGeometryMaterials(currTexture[3], currDepth/scale, closetThickness/scale, currUpperClosetHeight/scale + outerPlankOverlap/scale)
      closetPlanks[6][7].material = makeGeometryMaterials(currTexture[3], currDepth/scale, closetThickness/scale, currUpperClosetHeight/scale + outerPlankOverlap/scale)
    }
  }
}

export function changeDecorElectraSwitchVisibility(scene: THREE.Scene, visibility: boolean, closetPlanks: THREE.Mesh[][]){
  changeElectraSwitchVisibility(scene, visibility, closetPlanks)
}

export function totalDecorDimensions(): ClosetDimensions {
  return {
    width: currWidth + 2 * closetThickness,
    depth: currDepth + 2 * closetThickness,
    height: currLowerClosetHeight,
  };
}