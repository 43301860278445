import React from "react";
import {
  Aquarium,
  AquariumType,
  WaterType,
  SidesInfo,
  WindowPaintOption,
  SiliconColor,
  FilterOptions,
  PvcPipeworkPackage,
  FurnitureType,
  CoverPanelsColor,
} from "../../@types/aquarium";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Check, X } from "@phosphor-icons/react";
import { aquariumTypeOptions } from "../../data/aquariumTypes";
import { ImageOption } from "../../@types/configurator";
import { decorTextures } from "../../data/textures";
import {
  doorTypeOptions,
  electraRuimteOptions,
  extraPvcPipeworkPackageOptions,
  filterLocationOptions,
  filterOptions,
  fullGlassCoverOptions,
  furnitureTypeOptions,
  turtleIslandOptions,
} from "../../data/aquarium";
import { info } from "console";
import { ProductData } from "../../@types/product";
import { getGlassWidthNumber } from "../aquariumComponents/tank";
import { getAmountWidthStripsAquarium } from "../../utils/aquarium";
import { totalMarineDimensions } from "../aquariumComponents/marineConform";
import { totalAluminiumDimensions } from "../aquariumComponents/aluminiumFinish";
import { totalDecorDimensions } from "../aquariumComponents/decorFinish";

type Props = {};

function OverviewTable({}: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const dispatch = useDispatch();

  function isInStringEnum(value: string, enumeration: any): boolean {
    return Object.values(enumeration).includes(value);
  }

  function getImageOptionTitle(value: string, options: any) {
    const item = options.find((item: ImageOption) =>
      item.id === value ? item.title : null
    );
    return item ? item.title : null;
  }
  const getSidesPaintedFormat = (title: string, sidesInfo: SidesInfo) => {
    let text = "";
    if (sidesInfo.left.colorPainted) {
      text += "links, ";
    }
    if (sidesInfo.right.colorPainted) {
      text += "rechts, ";
    }
    if (sidesInfo.back.colorPainted) {
      text += "achteraan ";
    }
    return getTableFormat(title, text || false);
  };
  const getSidesOptiwhiteFormat = (title: string, sidesInfo: SidesInfo) => {
    let text = "";
    if (sidesInfo.left.optiWhite) {
      text += "links, ";
    }
    if (sidesInfo.right.optiWhite) {
      text += "rechts, ";
    }
    if (sidesInfo.front.optiWhite) {
      text += "vooraan, ";
    }
    if (sidesInfo.back.optiWhite) {
      text += "achteraan ";
    }
    return getTableFormat(title, text || false);
  };

  const hasPaintedWindows = (sidesInfo: SidesInfo) => {
    return sidesInfo.left.colorPainted ||
      sidesInfo.right.colorPainted ||
      sidesInfo.back.colorPainted
      ? true
      : false;
  };
  const hasOptiwhiteWindows = (sidesInfo: SidesInfo) => {
    return sidesInfo.left.optiWhite ||
      sidesInfo.right.optiWhite ||
      sidesInfo.front.optiWhite ||
      sidesInfo.back.optiWhite
      ? true
      : false;
  };

  const getClosetDimensions = (type: FurnitureType) => {
    switch (type) {
      case FurnitureType.marine:
        return totalMarineDimensions();
      case FurnitureType.aluminium:
        return totalAluminiumDimensions();
      case FurnitureType.decor:
        return totalDecorDimensions();
    }
  };

  const getTechnicsProduct = (title: string, items: ProductData[]) => {
    const countDuplicates = (items: ProductData[]) => {
      const counts: any = {};
      items.forEach((value: ProductData) => {
        if (!counts[value.name]) {
          counts[value.name] = 1;
        } else {
          counts[value.name]++;
        }
      });
      return counts;
    };
    const result = countDuplicates(items);
    let showedTitle = 0;

    const tableData = Object.entries(result).map((value) => {
      showedTitle += 1;
      return getTableFormat(
        showedTitle === 1 ? title : "",
        `${value[1]}x ${value[0]}`
      );
    });
    return tableData;
  };

  const checkSpecialValue = (value: any) => {
    if (typeof value === "boolean") {
      return value === false ? <X /> : value === true ? <Check /> : null;
    } else if (isInStringEnum(value, WaterType)) {
      return value === WaterType.fresh ? "zoetwater" : "zeewater";
    } else if (isInStringEnum(value, WindowPaintOption)) {
      return value === WindowPaintOption.black ? "zwart" : "blauw";
    } else if (isInStringEnum(value, SiliconColor)) {
      return value === SiliconColor.black ? "zwart" : "transparant";
    } else if (isInStringEnum(value, CoverPanelsColor)) {
      return value === CoverPanelsColor.black
        ? "zwart"
        : value === CoverPanelsColor.white
        ? "Wit"
        : CoverPanelsColor.gray
        ? "Grijs"
        : "Antraciet";
    } else {
      return null;
    }
  };

  const getTableFormat = (title: string, value: any) => {
    const specialValue = checkSpecialValue(value);

    return (
      <tr className={title ? "" : "overview-table-td-no-border"}>
        <td>{title}</td>
        <td>{specialValue || value}</td>
      </tr>
    );
  };
  const getExtraTableFormat = (title: string) => {
    return (
      <tr className="overview-table-extra-info">
        <td>{title}</td>
        <td></td>
      </tr>
    );
  };
  const getTableSubtitle = (title: string) => {
    return (
      <tr className="overview-table-subtitle">
        <th>{title}</th>
        <th></th>
      </tr>
    );
  };

  return (
    <table className="overview-table" cellSpacing={0}>
      <thead>
        <tr className="overview-table-title">
          <th>Algemeen overzicht</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="overview-table-tbody">
        {getTableSubtitle("Afmetingen")}
        {getTableFormat("Lengte", `${aquarium.aquariumDimensions.width} cm`)}
        {getTableFormat("Breedte", `${aquarium.aquariumDimensions.depth} cm`)}
        {getTableFormat("Hoogte", `${aquarium.aquariumDimensions.height} cm`)}
        {aquarium.aquariumType &&
          getAmountWidthStripsAquarium(aquarium.aquariumType) > 0 &&
          getTableFormat(
            "Aantal verstevigingsstrippen",
            `${getAmountWidthStripsAquarium(aquarium.aquariumType)}`
          )}
        {getTableFormat("Glas dikte", `${getGlassWidthNumber() * 1000} mm`)}

        {getTableSubtitle("Eigenschappen")}
        {getTableFormat("Biotoop", aquarium.waterType)}
        {aquarium.aquariumType &&
          getTableFormat(
            "Type aquarium",
            getImageOptionTitle(aquarium.aquariumType, aquariumTypeOptions)
          )}
        {aquarium.turtleIsland &&
          getTableFormat(
            "Plaatsing schildpadden eiland",
            getImageOptionTitle(aquarium.turtleIsland, turtleIslandOptions)
          )}
        {aquarium.fullGlassCoverPanels &&
          getTableFormat(
            "Afdekruiten",
            getImageOptionTitle(
              aquarium.fullGlassCoverPanels,
              fullGlassCoverOptions
            )
          )}
        {aquarium.aquariumTexture &&
          getTableFormat(
            "Afwerkingskleur",
            getImageOptionTitle(aquarium.aquariumTexture, decorTextures)
          )}
        {aquarium.extra.siliconeColor &&
          getTableFormat("Siliconen kleur", aquarium.extra.siliconeColor)}
        {aquarium.sharpenGlass &&
          getTableFormat("Geslepen glas", aquarium.sharpenGlass)}
        {aquarium.lightCover &&
          getTableFormat("Lichtboord", aquarium.lightCover)}
        {aquarium.grindlist && getTableFormat("Grindlijst", aquarium.grindlist)}
        {aquarium.coverPanels &&
          getTableFormat(
            "Afdekplaten met verluchtingsrooster",
            aquarium.coverPanels
          )}
        {aquarium.coverPanelsColor &&
          aquarium.coverPanels &&
          getTableFormat("Afdekplaten kleur", aquarium.coverPanelsColor)}
        {aquarium.extra.waterproofPlate &&
          getTableFormat(
            "Plaat onder aquarium",
            aquarium.extra.waterproofPlate
          )}

        {(hasPaintedWindows(aquarium.aquariumSides) ||
          hasOptiwhiteWindows(aquarium.aquariumSides)) &&
          getTableSubtitle("Ruiten")}
        {aquarium.aquariumSides &&
          hasPaintedWindows(aquarium.aquariumSides) &&
          getSidesPaintedFormat("Gekleurde ruiten", aquarium.aquariumSides)}
        {hasPaintedWindows(aquarium.aquariumSides) &&
          getTableFormat("Kleur", aquarium.windowPaintColor)}
        {aquarium.aquariumSides &&
          hasOptiwhiteWindows(aquarium.aquariumSides) &&
          getSidesOptiwhiteFormat("Optiwhite ruiten", aquarium.aquariumSides)}

        {getTableSubtitle("Filter")}
        {aquarium.filter.filterType &&
          getTableFormat(
            "Type filter",
            getImageOptionTitle(aquarium.filter.filterType, filterOptions)
          )}
        {aquarium.filter.filterType === FilterOptions.bio &&
          getExtraTableFormat("incl. overloop met 3 boringen")}
        {aquarium.filter.externalDryroom &&
          getTableFormat("Droogkamer", aquarium.filter.externalDryroom)}
        {aquarium.filter.filterLocation &&
          getTableFormat(
            "Locatie",
            getImageOptionTitle(
              aquarium.filter.filterLocation,
              filterLocationOptions
            )
          )}
        {aquarium.extra.pvcPipeworkPackage &&
          getTableFormat(
            "PVC Package",
            getImageOptionTitle(
              aquarium.extra.pvcPipeworkPackage,
              extraPvcPipeworkPackageOptions
            )
          )}
        {aquarium.extra.pvcPipeworkPackage === PvcPipeworkPackage.byAqualife &&
          getExtraTableFormat("incl. doorvoeren")}
        {aquarium.filter.bioCoverPanels &&
          getTableFormat("Afdekplaten", aquarium.filter.bioCoverPanels)}
        {aquarium.filter.osmoseCompartiment &&
          getTableFormat(
            "Osmosecompartiment",
            aquarium.filter.osmoseCompartiment
          )}
        {aquarium.filter.boreholeLeft &&
          getTableFormat("Boorgat links", aquarium.filter.boreholeLeft)}
        {aquarium.filter.boreholeRight &&
          getTableFormat("Boorgat rechts", aquarium.filter.boreholeRight)}

        {getTableSubtitle("Meubelafwerking")}
        {aquarium.furniture.furnitureType &&
          getTableFormat(
            "Type afwerking",
            getImageOptionTitle(
              aquarium.furniture.furnitureType,
              furnitureTypeOptions
            )
          )}
        {aquarium.furniture.furnitureType === FurnitureType.marine &&
          getExtraTableFormat(
            "incl. ventilatiegaten, regelbare poten en bodemplaat"
          )}
        {aquarium.furniture.furnitureType !== FurnitureType.none &&
          getTableFormat(
            "Hoogte onderstel",
            `${aquarium.furniture.dimensions.baseHeight} cm`
          )}
        {(aquarium.furniture.furnitureType === FurnitureType.marine ||
          aquarium.furniture.furnitureType === FurnitureType.aluminium ||
          aquarium.furniture.furnitureType === FurnitureType.decor) &&
          getTableFormat(
            "Lengte meubel",
            `${getClosetDimensions(aquarium.furniture.furnitureType)?.width} cm`
          )}
        {(aquarium.furniture.furnitureType === FurnitureType.marine ||
          aquarium.furniture.furnitureType === FurnitureType.aluminium ||
          aquarium.furniture.furnitureType === FurnitureType.decor) &&
          getTableFormat(
            "Breedte meubel",
            `${getClosetDimensions(aquarium.furniture.furnitureType)?.depth} cm`
          )}
        {aquarium.furniture.dimensions.topHeight > 0 &&
          getTableFormat(
            "Hoogte bovenstel",
            `${aquarium.furniture.dimensions.topHeight} cm`
          )}
        {aquarium.furniture.doorType &&
          aquarium.furniture.furnitureType === FurnitureType.aluminium &&
          getTableFormat(
            "Deursysteem",
            getImageOptionTitle(aquarium.furniture.doorType, doorTypeOptions)
          )}
        {(aquarium.furniture.furnitureType === FurnitureType.decor ||
          aquarium.furniture.furnitureType === FurnitureType.marine) &&
          getTableFormat("Deursysteem", "Softclose deursysteem")}
        {aquarium.furniture.electraRuimte &&
          getTableFormat(
            "Locatie electraruimte",
            getImageOptionTitle(
              aquarium.furniture.electraRuimte,
              electraRuimteOptions
            )
          )}
        {aquarium.furniture.betonPlex &&
          getTableFormat("Betonplexplaat", aquarium.furniture.betonPlex)}
        {aquarium.furniture.seeThrough &&
          getTableFormat("See-through aquarium", aquarium.furniture.seeThrough)}
        {aquarium.furniture.backWall34 &&
          getTableFormat("Achterwand 3/4 dicht", aquarium.furniture.backWall34)}
        {aquarium.furniture.removableSideLeft &&
          getTableFormat(
            "Linker zijkant afneembaar",
            aquarium.furniture.removableSideLeft
          )}
        {aquarium.furniture.removableSideRight &&
          getTableFormat(
            "Rechter zijkant afneembaar",
            aquarium.furniture.removableSideRight
          )}
        {aquarium.furniture.aquaswitch &&
          getTableFormat("Aquaswitch", aquarium.furniture.aquaswitch)}

        {getTableSubtitle("Basis technieken")}
        {aquarium.technics.boosterPump.length > 0 &&
          getTechnicsProduct("Opvoerpomp", aquarium.technics.boosterPump)}
        {aquarium.technics.led.length > 0 &&
          getTechnicsProduct("LED verlichting", aquarium.technics.led)}
        {aquarium.technics.heating.length > 0 &&
          getTechnicsProduct("Verwarming", aquarium.technics.heating)}
        {aquarium.technics.filter.length > 0 &&
          getTechnicsProduct("Filter", aquarium.technics.filter)}
        {aquarium.technics.circulationPump.length > 0 &&
          getTechnicsProduct(
            "Stromingspomp",
            aquarium.technics.circulationPump
          )}
        {aquarium.technics.proteinSkimmer.length > 0 &&
          getTechnicsProduct(
            "Eiwitafschuimer",
            aquarium.technics.proteinSkimmer
          )}
        {aquarium.technics.automaticRefillSystem.length > 0 &&
          getTechnicsProduct(
            "Automatisch bijvulsysteem",
            aquarium.technics.automaticRefillSystem
          )}
        {aquarium.technics.externalExteriorFilter.length > 0 &&
          getTechnicsProduct(
            "Externe buitenfilter",
            aquarium.technics.externalExteriorFilter
          )}
        {aquarium.technics.co2System.length > 0 &&
          getTechnicsProduct("CO2-systeem", aquarium.technics.co2System)}
        {aquarium.technics.interior.length > 0 &&
          getTechnicsProduct("Inrichting", aquarium.technics.interior)}
      </tbody>
    </table>
  );
}

export default OverviewTable;
