import { ImageOption } from "../@types/configurator";
import { DecorTexture } from "../@types/textures";
import antracietTexture from "../images/textures/Antraciet.png";
import concreteDecor from "../images/textures/ConcreteDecor.png";
import placeholder from "../images/textures/placeholder.jpg";

export const decorTextures: Array<ImageOption> = [
  {
    id: DecorTexture.concreteDecor,
    title: "Concrete decor",
    img: concreteDecor,
  },
  {
    id: DecorTexture.antraciet,
    title: "Antraciet",
    img: antracietTexture,
  },
];

export const decorTexturePlaceholder: ImageOption = {
  id: DecorTexture.placeholder,
  title: "Selecteer decor",
  img: placeholder,
};
