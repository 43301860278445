import { Dispatch } from "react";
import { UnknownAction } from "redux";
import {
  Aquarium,
  AquariumType,
  ElectraRuimte,
  FilterOptions,
  FurnitureType,
  Side,
  WaterType,
} from "../@types/aquarium";
import {
  setAquariumSides,
  setAquariumTexture,
  setAquariumType,
  setBackWall34,
  setBetonPlex,
  setBioCoverPanels,
  setBoreholeLeft,
  setBoreholeRight,
  setCoverPanels,
  setCoverPanelsColor,
  setDoorType,
  setElectraRuimte,
  setExternalFilterDryroom,
  setExtraAquaswitch,
  setFilterLocation,
  setFullGlassCoverPanels,
  setFurnitureDimensions,
  setGrindList,
  setLightCover,
  setOsmoseCompartiment,
  setPvcPipeworkPackage,
  setRemovableSideLeft,
  setRemovableSideRight,
  setSeeThrough,
  setTechnicsAutoRefillSystem,
  setTechnicsBoosterPump,
  setTechnicsCirculationPump,
  setTechnicsCo2System,
  setTechnicsExternalFilter,
  setTechnicsFilter,
  setTechnicsHeating,
  setTechnicsInterior,
  setTechnicsLed,
  setTechnicsProteinSkimmer,
  setTurtleIslandLocation,
} from "../redux/aquariumSlice";
import {
  changeAquariumType,
  changeCoverPanels,
  changeGrindListVisbility,
  changeLightCover,
  paintSide,
  setElectraSpace,
  setRearWall,
} from "../components/aquariumComponents/tank";
import { defaultAquarium } from "./defaultAquarium";
import { ProductCategory, ProductData } from "../@types/product";

export const autoAdjustAquarium = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  updateAquariumTypeOptions(aquarium, dispatch);
  updateWindowOptions(aquarium, dispatch);
  updateFilterOptions(aquarium, dispatch);
  updateTopCovers(aquarium, dispatch);
  updateFurnitureOptions(aquarium, dispatch);
  updateTechnicsOptions(aquarium, dispatch);
};

const updateAquariumTypeOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  switch (aquarium.aquariumType) {
    //Hier worden alle eigenschappen van een aquarium die niet relevant zijn aan het geselecteerde aquariumtype uit gezet
    case AquariumType.rimless:
      //TODO: check als grindlijst ook hier een effect op heeft
      if (
        !aquarium.grindlist &&
        (aquarium.furniture.furnitureType === null ||
          aquarium.furniture.furnitureType === FurnitureType.frameOnly)
      ) {
        dispatch(setAquariumTexture(null));
      }
      dispatch(setCoverPanels(false));
      dispatch(setFullGlassCoverPanels(null));
      dispatch(setTurtleIslandLocation(null));
      dispatch(setLightCover(false));
      break;
    case AquariumType.fullGlass:
      //TODO: check als grindlijst ook hier een effect op heeft
      if (
        !aquarium.grindlist &&
        (aquarium.furniture.furnitureType === null ||
          aquarium.furniture.furnitureType === FurnitureType.frameOnly)
      ) {
        dispatch(setAquariumTexture(null));
      }
      dispatch(setCoverPanels(false));
      dispatch(setTurtleIslandLocation(null));
      dispatch(setLightCover(false));
      break;
    case AquariumType.lightCover:
      dispatch(setTurtleIslandLocation(null));
      dispatch(setFullGlassCoverPanels(null));
      dispatch(setLightCover(false));
      break;
    case AquariumType.turtle:
      if (
        (aquarium.furniture.furnitureType === null ||
          aquarium.furniture.furnitureType === FurnitureType.frameOnly) &&
        !aquarium.lightCover &&
        !aquarium.grindlist
      ) {
        dispatch(setAquariumTexture(null));
      }
      dispatch(setFullGlassCoverPanels(null));
      break;

    default:
      break;
  }
};

const updateWindowOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  // console.log(aquarium.aquariumSides);
  const currentObj = aquarium.aquariumSides;
  let newObj = currentObj;
  if (currentObj.back.colorPainted && currentObj.back.optiWhite) {
    newObj = {
      ...newObj,
      back: {
        ...currentObj.back,
        optiWhite: false,
      },
    };
  }
  if (currentObj.left.colorPainted && currentObj.left.optiWhite) {
    newObj = {
      ...newObj,
      left: {
        ...currentObj.left,
        optiWhite: false,
      },
    };
  }
  if (currentObj.right.colorPainted && currentObj.right.optiWhite) {
    newObj = {
      ...newObj,
      right: {
        ...currentObj.right,
        optiWhite: false,
      },
    };
  }
  if (
    aquarium.aquariumSides.back.colorPainted &&
    aquarium.furniture.seeThrough
  ) {
    newObj = {
      ...newObj,
      back: {
        ...currentObj.back,
        colorPainted: false,
      },
    };
    paintSide(Side.achter, false);
  }
  dispatch(setAquariumSides(newObj));
};

const updateFilterOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  switch (aquarium.filter.filterType) {
    case FilterOptions.none:
      dispatch(setExternalFilterDryroom(false));
      dispatch(setFilterLocation(null));
      dispatch(setBioCoverPanels(false));
      dispatch(setOsmoseCompartiment(false));
      dispatch(setBoreholeLeft(false));
      dispatch(setBoreholeRight(false));
      dispatch(setPvcPipeworkPackage(null));
      break;
    case FilterOptions.bio:
      dispatch(setExternalFilterDryroom(false));
      dispatch(setOsmoseCompartiment(false));
      dispatch(setBoreholeLeft(false));
      dispatch(setBoreholeRight(false));
      break;
    case FilterOptions.biological:
      dispatch(setExternalFilterDryroom(false));
      dispatch(setBioCoverPanels(false));
      dispatch(setOsmoseCompartiment(false));
      dispatch(setBoreholeLeft(false));
      dispatch(setBoreholeRight(false));
      dispatch(setPvcPipeworkPackage(null));
      break;
    case FilterOptions.external:
      if (!aquarium.filter.externalDryroom) {
        dispatch(setFilterLocation(null));
      }
      dispatch(setBioCoverPanels(false));
      dispatch(setOsmoseCompartiment(false));
      dispatch(setPvcPipeworkPackage(null));
      break;
    case FilterOptions.sump:
      dispatch(setExternalFilterDryroom(false));
      dispatch(setBioCoverPanels(false));
      dispatch(setBoreholeLeft(false));
      dispatch(setBoreholeRight(false));
      break;

    default:
      break;
  }
};

const updateTopCovers = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  if (aquarium.lightCover && aquarium.furniture.dimensions.topHeight) {
    dispatch(setLightCover(false));
    dispatch(setCoverPanels(false));
    changeLightCover(false);
    changeCoverPanels(false);
  }
  if (
    aquarium.aquariumType === AquariumType.lightCover &&
    aquarium.furniture.dimensions.topHeight
  ) {
    dispatch(setAquariumType(AquariumType.fullGlass));
    dispatch(setCoverPanels(false));
    changeCoverPanels(false);
    changeAquariumType(AquariumType.fullGlass);
  }
};

const updateFurnitureOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  switch (aquarium.furniture.furnitureType) {
    case FurnitureType.none:
      dispatch(setFurnitureDimensions(defaultAquarium.furniture.dimensions));
      dispatch(setBetonPlex(false));
      dispatch(setSeeThrough(false));
      dispatch(setRemovableSideLeft(false));
      dispatch(setRemovableSideRight(false));
      dispatch(setDoorType(null));
      dispatch(setExtraAquaswitch(false));
      break;
    case FurnitureType.frameOnly:
      dispatch(setSeeThrough(false));
      dispatch(setRemovableSideLeft(false));
      dispatch(setRemovableSideRight(false));
      dispatch(setDoorType(null));
      dispatch(setExtraAquaswitch(false));
      break;
    case FurnitureType.decor:
      dispatch(setDoorType(null));
      break;
    case FurnitureType.aluminium:
      break;
    case FurnitureType.marine:
      dispatch(setDoorType(null));
      dispatch(setRemovableSideLeft(false));
      dispatch(setRemovableSideRight(false));
      dispatch(setExtraAquaswitch(false));
      break;
    default:
      break;
  }

  if (
    aquarium.furniture.furnitureType === null ||
    aquarium.furniture.furnitureType === FurnitureType.none ||
    aquarium.furniture.furnitureType === FurnitureType.frameOnly
  ) {
    const toggleDoorsButton = document.getElementById("toggle-doors-button");
    if (toggleDoorsButton) toggleDoorsButton.style.display = "none";
  } else {
    const toggleDoorsButton = document.getElementById("toggle-doors-button");
    if (toggleDoorsButton) toggleDoorsButton.style.display = "flex";
  }
  if (
    aquarium.furniture.furnitureType === FurnitureType.marine ||
    aquarium.furniture.furnitureType === FurnitureType.none ||
    aquarium.furniture.furnitureType === FurnitureType.frameOnly
  ) {
    dispatch(setBackWall34(false));
    setRearWall(false);
  }

  if (
    (aquarium.aquariumDimensions.width < 100 &&
      (aquarium.filter.filterType === FilterOptions.bio ||
        aquarium.filter.filterType === FilterOptions.sump)) ||
    (aquarium.aquariumDimensions.width < 100 &&
      (aquarium.filter.filterType === FilterOptions.none ||
        aquarium.filter.filterType === null ||
        aquarium.filter.filterType === FilterOptions.external ||
        aquarium.filter.filterType === FilterOptions.biological))
  ) {
    dispatch(setElectraRuimte(null));
    setElectraSpace(ElectraRuimte.none);
  } else if (
    aquarium.furniture.electraRuimte === null ||
    aquarium.furniture.electraRuimte === ElectraRuimte.none
  ) {
    setElectraSpace(ElectraRuimte.none);
  } else {
    setElectraSpace(aquarium.furniture.electraRuimte);
  }
  if (
    aquarium.grindlist &&
    (aquarium.furniture.furnitureType === FurnitureType.aluminium ||
      aquarium.furniture.furnitureType === FurnitureType.decor ||
      aquarium.furniture.furnitureType === FurnitureType.marine)
  ) {
    dispatch(setGrindList(false));
    changeGrindListVisbility(false);
  }
};

const updateTechnicsOptions = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  const technics = aquarium.technics;
  if (aquarium.waterType === WaterType.fresh) {
    if (
      aquarium.aquariumType === AquariumType.turtle &&
      technics.boosterPump.length > 0
    ) {
      dispatch(setTechnicsBoosterPump([]));
    } else {
      dispatch(
        setTechnicsBoosterPump(
          filterTechnicsProducts(
            technics.boosterPump,
            ProductCategory.boosterPumpsFreshwater
          )
        )
      );
    }

    if (aquarium.aquariumType === AquariumType.turtle) {
      dispatch(
        setTechnicsLed(
          filterTechnicsProducts(technics.led, ProductCategory.LEDTurtles)
        )
      );
    } else {
      dispatch(
        setTechnicsLed(
          filterTechnicsProducts(technics.led, ProductCategory.LEDFreshwater)
        )
      );
    }

    if (
      aquarium.aquariumType === AquariumType.turtle &&
      technics.heating.length > 0
    ) {
      dispatch(setTechnicsHeating([]));
    } else {
      dispatch(
        setTechnicsHeating(
          filterTechnicsProducts(
            technics.heating,
            ProductCategory.heatingFreshwater
          )
        )
      );
    }

    if (
      aquarium.aquariumType === AquariumType.turtle &&
      technics.filter.length > 0
    ) {
      dispatch(setTechnicsFilter([]));
    } else {
      dispatch(
        setTechnicsFilter(
          filterTechnicsProducts(
            technics.filter,
            ProductCategory.filtersFreshwater
          )
        )
      );
    }

    if (technics.circulationPump.length > 0) {
      dispatch(setTechnicsCirculationPump([]));
    }
    if (technics.proteinSkimmer.length > 0) {
      dispatch(setTechnicsProteinSkimmer([]));
    }
    if (technics.automaticRefillSystem.length > 0) {
      dispatch(setTechnicsAutoRefillSystem([]));
    }

    if (aquarium.aquariumType === AquariumType.turtle) {
      dispatch(
        setTechnicsExternalFilter(
          filterTechnicsProducts(
            technics.externalExteriorFilter,
            ProductCategory.externalExteriorFiltersTurtles
          )
        )
      );
    } else {
      dispatch(
        setTechnicsExternalFilter(
          filterTechnicsProducts(
            technics.externalExteriorFilter,
            ProductCategory.externalExteriorFiltersFreshwater
          )
        )
      );
    }

    if (
      aquarium.aquariumType === AquariumType.turtle &&
      technics.co2System.length > 0
    ) {
      dispatch(setTechnicsCo2System([]));
    } else {
      dispatch(
        setTechnicsCo2System(
          filterTechnicsProducts(
            technics.co2System,
            ProductCategory.co2SystemFreshwater
          )
        )
      );
    }

    if (aquarium.aquariumType === AquariumType.turtle) {
      dispatch(
        setTechnicsInterior(
          filterTechnicsProducts(
            technics.interior,
            ProductCategory.interiorTurtles
          )
        )
      );
    } else {
      dispatch(
        setTechnicsInterior(
          filterTechnicsProducts(
            technics.interior,
            ProductCategory.interiorFreshwater
          )
        )
      );
    }
  }

  //SALT WATER PRODUCTS

  if (aquarium.waterType === WaterType.salty) {
    dispatch(
      setTechnicsBoosterPump(
        filterTechnicsProducts(
          technics.boosterPump,
          ProductCategory.boosterPumpsSaltwater
        )
      )
    );
    dispatch(
      setTechnicsLed(
        filterTechnicsProducts(technics.led, ProductCategory.LEDSaltwater)
      )
    );
    dispatch(
      setTechnicsHeating(
        filterTechnicsProducts(
          technics.heating,
          ProductCategory.heatingSaltwater
        )
      )
    );
    dispatch(
      setTechnicsFilter(
        filterTechnicsProducts(
          technics.filter,
          ProductCategory.filtersSaltwater
        )
      )
    );
    dispatch(
      setTechnicsCirculationPump(
        filterTechnicsProducts(
          technics.circulationPump,
          ProductCategory.circulationPumpsSaltwater
        )
      )
    );
    dispatch(
      setTechnicsProteinSkimmer(
        filterTechnicsProducts(
          technics.proteinSkimmer,
          ProductCategory.proteinSkimmersSaltwater
        )
      )
    );
    dispatch(
      setTechnicsAutoRefillSystem(
        filterTechnicsProducts(
          technics.automaticRefillSystem,
          ProductCategory.automaticRefillSystemsSaltwater
        )
      )
    );
    if (technics.externalExteriorFilter.length > 0) {
      dispatch(setTechnicsExternalFilter([]));
    }
    if (technics.co2System.length > 0) {
      dispatch(setTechnicsCo2System([]));
    }
    dispatch(
      setTechnicsInterior(
        filterTechnicsProducts(
          technics.interior,
          ProductCategory.interiorSaltwater
        )
      )
    );
  }
};

const filterTechnicsProducts = (
  productList: ProductData[],
  productCategory: ProductCategory
) => {
  return productList.filter((item) => item.category.includes(productCategory));
};
