import React from "react";
import Header from "../components/organisms/Header";
import { AllTextObject } from "../@types/language";
import "../styles/orderMessage.css";
import landing_animation from "../images/landing-coral-gif.gif";
import {
  GradientSubTitle,
  SmallerText,
  Text,
} from "../components/atoms/StyledComponents";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Socials from "../components/molecules/Socials";
import OrderProcess from "../components/molecules/OrderProcess";

type Props = { allText: AllTextObject };

function OrderSuccesScreen({ allText }: Props) {
  const user = useSelector((state: RootState) => state.user);
  const isMobile = window.innerWidth < 1024;
  return (
    <div className="order-message-screen-container">
      <Header allText={allText} />
      <main className="landing-banner-container overview-container order-message-screen">
        <div className="order-message-container">
          <div className="order-message-animation-container landing-banner-animation-container">
            <figure>
              <img src={landing_animation} alt="coral animation" />
            </figure>
          </div>
          <div className="order-message-screen-content-container">
            <div className="order-message-screen-content-column">
              <GradientSubTitle color="var(--primary-gradient-200)">
                Bedankt voor je bestelling
                {user.firstname ? `, ${user.firstname}!` : "!"}
              </GradientSubTitle>
              <Text>
                We gaan direct voor je aan de slag! Zodra jouw aquarium bijna af
                is, nemen we contact met je op om een levermoment af te spreken.
                In de tussentijd kan je zeker onze socials in de gaten houden!
              </Text>
              <div>
                <SmallerText weight={700}>verwachte levertermijn</SmallerText>
                <SmallerText>6 weken</SmallerText>
              </div>
              <Socials />
            </div>
            {!isMobile && (
              <div className="order-message-screen-content-column">
                <OrderProcess step={1} />
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default OrderSuccesScreen;
