import {
  PricePerUnitOptions,
  ProductCategory,
  ProductData,
} from "../@types/product";
import { OfferteData } from "../@types/user";
import dummyFilterImage from "../images/filteroptions/biologist.png";
import boosterPumpDummy from "../images/boosterPumpDummy.jpg";
const testDate: string = new Date().toLocaleString();

export const testRowData: OfferteData[] = [
  {
    offerteNumber: 25487,
    firstname: "Dries",
    lastname: "Vanmierlo",
    email: "dries.vanmierlo@telenet.be",
    price: 1652.65,
    isPaid: true,
    date: testDate,
    reminderSend: testDate,
    phonenumber: "0470 63 75 28",
    postalcode: "3950",
    city: "Bocholt",
    street: "Tuinstraat",
    houseNumber: "2",
    country: "BE",
    advancePaymentPaid: false,
    offerteLink: "",
  },
  {
    offerteNumber: 9999,
    firstname: "Daan",
    lastname: "Ezendam",
    email: "daezendam@gmail.com",
    price: 890.33,
    isPaid: false,
    date: testDate,
    reminderSend: null,
    phonenumber: "0470 63 75 28",
    postalcode: "3950",
    city: "Bocholt",
    street: "Tuinstraat",
    houseNumber: "2",
    country: "BE",
    advancePaymentPaid: true,
    offerteLink: "",
  },
];

export const testProductData: ProductData[] = [
  {
    id: "boo-pum-001",
    name: "Aqualife - Sine Wave 6500 (SWP 6500)",
    description:
      "Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter Beschrijving van de bioloog filter",
    category: [
      ProductCategory.boosterPumpsFreshwater,
      ProductCategory.boosterPumpsSaltwater,
      ProductCategory.LEDFreshwater,
    ],
    price: 62.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: true,
    recommandedText:
      "De tekst die uitlegt waarom dit product aangeraden isDe tekst die uitlegt waarom dit product aangeraden isDe tekst die uitlegt waarom dit product aangeraden isDe tekst die uitlegt waarom dit product aangeraden isDe tekst die uitlegt waarom dit product aangeraden is",
    image: boosterPumpDummy,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "boo-pum-002",
    name: "Aqualife - Sine Wave 5500 (SWP 5500)",
    description: "Beschrijving van de bioloog filter",
    category: [
      ProductCategory.boosterPumpsFreshwater,
      ProductCategory.LEDTurtles,
    ],
    price: 62.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "De tekst die uitlegt waarom dit product aangeraden is",
    image: boosterPumpDummy,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "boo-pum-003",
    name: "Aqualife - Sine Wave 4500 (SWP 4500)",
    description: "Beschrijving van de bioloog filter",
    category: [
      ProductCategory.boosterPumpsFreshwater,
      ProductCategory.LEDSaltwater,
    ],
    price: 62.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "De tekst die uitlegt waarom dit product aangeraden is",
    image: boosterPumpDummy,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "fil-bio-001",
    name: "Aqualife - Sine Wave 6500 (SWP 6500)",
    description: "Beschrijving van de bioloog filter",
    category: [ProductCategory.filtersFreshwater],
    price: 62.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: true,
    recommandedText: "De tekst die uitlegt waarom dit product aangeraden is",
    image: dummyFilterImage,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "fil-bio-002",
    name: "Filter Bioloog",
    description: "Beschrijving van de bioloog filter",
    category: [ProductCategory.filtersFreshwater],
    price: 62.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "De tekst die uitlegt waarom dit product aangeraden is",
    image: dummyFilterImage,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "tex-decor-001",
    name: "Antraciet",
    description: "Beschrijving van de bioloog filter",
    category: [ProductCategory.textures],
    price: 62.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perSquareMeter,
    isRecommanded: false,
    recommandedText: "De tekst die uitlegt waarom dit product aangeraden is",
    image: dummyFilterImage,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
];
