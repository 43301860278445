import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LanguageState {
  activeLanguage: string;
}

const initialState: LanguageState = {
  activeLanguage: "dutch",
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setActiveLanguage: (state, action: PayloadAction<string>) => {
      state.activeLanguage = action.payload;
    },
  },
});

export const { setActiveLanguage } = languageSlice.actions;

export default languageSlice.reducer;
