import React, { useMemo, useState } from "react";
import { AllTextObject } from "../@types/language";
import { ProductCategory, ProductData } from "../@types/product";
import { ColDef } from "ag-grid-enterprise";
import { testProductData } from "../data/dummyData";
import Header from "../components/organisms/Header";
import { ArrowLeft } from "@phosphor-icons/react";
import { Title } from "../components/atoms/StyledComponents";
import QuickFilters from "../components/molecules/QuickFilters";
import { DashboardPages } from "../@types/configurator";
import { AG_GRID_LOCALE_NL } from "@ag-grid-community/locale";
import { AgGridReact } from "ag-grid-react";
import AdminProductsMenu from "../components/organisms/AdminProductsMenu";

type Props = { allText: AllTextObject };

function AdminProductsScreen({ allText }: Props) {
  const filterProducts = (category: ProductCategory) => {
    return testProductData.filter((prod) => prod.category.includes(category));
  };

  return (
    <>
      <Header allText={allText} />
      <main className="dashboard-page" id="admin-products-page">
        <a href="/dashboard" className="dashboard-goback-arrow">
          <ArrowLeft color="var(--primary-700)" size={32} weight="bold" />
        </a>
        <Title color="var(--primary-700)">Producten & prijzen</Title>
        <div className="dashboard-page-header">
          {/* <QuickFilters type={DashboardPages.products} /> */}
          {/* <AdminSearchBar placeholder="Zoek op offertenr, naam, stad,..." /> */}
        </div>
        <div className="products-menu-list-container">
          <AdminProductsMenu
            productCategory={ProductCategory.boosterPumpsSaltwater}
            categoryName="Opvoerpompen - zeewater"
            products={filterProducts(ProductCategory.boosterPumpsSaltwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.boosterPumpsFreshwater}
            categoryName="Opvoerpompen - zoetwater"
            products={filterProducts(ProductCategory.boosterPumpsFreshwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.LEDSaltwater}
            categoryName="LED verlichting - zeewater"
            products={filterProducts(ProductCategory.LEDSaltwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.LEDFreshwater}
            categoryName="LED verlichting - zoetwater"
            products={filterProducts(ProductCategory.LEDFreshwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.LEDTurtles}
            categoryName="LED verlichting - schildpad"
            products={filterProducts(ProductCategory.LEDTurtles)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.heatingSaltwater}
            categoryName="Verwarming - zeewater"
            products={filterProducts(ProductCategory.heatingSaltwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.heatingFreshwater}
            categoryName="Verwarming - zoetwater"
            products={filterProducts(ProductCategory.heatingFreshwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.filtersSaltwater}
            categoryName="Filtermateriaal - zeewater"
            products={filterProducts(ProductCategory.filtersSaltwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.filtersFreshwater}
            categoryName="Filtermateriaal - zoetwater"
            products={filterProducts(ProductCategory.filtersFreshwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.circulationPumpsSaltwater}
            categoryName="Stromingspompen - zeewater"
            products={filterProducts(ProductCategory.circulationPumpsSaltwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.proteinSkimmersSaltwater}
            categoryName="Eiwitafschuimer - zeewater"
            products={filterProducts(ProductCategory.proteinSkimmersSaltwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.automaticRefillSystemsSaltwater}
            categoryName="Automatisch bijvulsysteem - zeewater"
            products={filterProducts(
              ProductCategory.automaticRefillSystemsSaltwater
            )}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.externalExteriorFiltersFreshwater}
            categoryName="Externe buitenfilters (OASE) - zoetwater"
            products={filterProducts(
              ProductCategory.externalExteriorFiltersFreshwater
            )}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.externalExteriorFiltersTurtles}
            categoryName="Externe buitenfilters (OASE) - schildpad"
            products={filterProducts(
              ProductCategory.externalExteriorFiltersTurtles
            )}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.co2SystemFreshwater}
            categoryName="CO2-systeem - zoetwater"
            products={filterProducts(
              ProductCategory.co2SystemFreshwater
            )}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.interiorSaltwater}
            categoryName="Interieur - zeewater"
            products={filterProducts(ProductCategory.interiorSaltwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.interiorFreshwater}
            categoryName="Interieur - zoetwater"
            products={filterProducts(ProductCategory.interiorFreshwater)}
          />
          <AdminProductsMenu
            productCategory={ProductCategory.interiorTurtles}
            categoryName="Interieur - schildpad"
            products={filterProducts(ProductCategory.interiorTurtles)}
          />
        </div>
      </main>
    </>
  );
}

export default AdminProductsScreen;
