export type ProductData = {
  id: string;
  name: string;
  description: string;
  category: ProductCategory[];
  price: number;
  discountReseller1: number;
  discountReseller2: number;
  pricePerUnit: PricePerUnitOptions;
  isRecommanded: boolean;
  recommandedText: string;
  image: string;
  dimensionsDependant: DimensionsDependant;
  volumeDependant: VolumeDependant;
};

export enum PricePerUnitOptions {
  perPiece = "per piece",
  perSquareMeter = "per square meter",
  perLiter = "per liter",
  perKilo = "per kilo",
}

export type DimensionsDependant = {
  minLenght: number | null;
  maxLenght: number | null;
  minWidth: number | null;
  maxWidth: number | null;
  minHeight: number | null;
  maxHeight: number | null;
};

export type VolumeDependant = {
  minVolume: number | null;
  maxVolume: number | null;
};

export enum ProductCategory {
  boosterPumpsFreshwater = "boosterpumps freshwater",
  boosterPumpsSaltwater = "boosterpumps saltwater",
  filtersFreshwater = "freshwater filters",
  filtersSaltwater = "saltwater filters",
  LEDFreshwater = "freshwater LEDs",
  LEDSaltwater = "saltwater LEDs",
  LEDTurtles = "turtles LEDs",
  heatingFreshwater = "freshwater heating",
  heatingSaltwater = "saltwater heating",
  circulationPumpsSaltwater = "circulation pumps saltwater",
  proteinSkimmersSaltwater = "protein skimmers saltwater",
  automaticRefillSystemsSaltwater = "automatic refill systems saltwater",
  externalExteriorFiltersFreshwater = "external exterior filters freshwater",
  externalExteriorFiltersTurtles = "external exterior filters turtles",
  co2SystemFreshwater = "CO2-system freshwater",
  interiorFreshwater = "interior freshwater",
  interiorSaltwater = "interior saltwater",
  interiorTurtles = "interior turtles",

  textures = "textures",
  // glass = "glass",
}
