import Landing from "./screens/Landing";
import "./styles/shared.css";
import Header from "./components/organisms/Header";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import ErrorScreen from "./screens/ErrorScreen";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { getAllTextForLanguage } from "./utils/textRegulator";
import { AllTextObject } from "./@types/language";
import ConfiguratorScreen from "./screens/ConfiguratorScreen";
import { resetAquarium } from "./redux/aquariumSlice";
import AdminLandingScreen from "./screens/AdminLandingScreen";
import AdminOfferteScreen from "./screens/AdminOfferteScreen";
import AdminProductsScreen from "./screens/AdminProductsScreen";
import AdminTranslationsScreen from "./screens/AdminTranslationsScreen";
import AdminUsersScreen from "./screens/AdminUsersScreen";
import OrderSuccesScreen from "./screens/OrderSuccesScreen";
import OrderFailedScreen from "./screens/OrderFailedScreen";

function App() {
  const activeLanguage = useSelector(
    (state: RootState) => state.language.activeLanguage
  );
  const dispatch = useDispatch();

  window.onload = () => {
    dispatch(resetAquarium());
  };

  const allText: AllTextObject = getAllTextForLanguage(activeLanguage);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/configurator",
      element: <ConfiguratorScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/configurator/succes",
      element: <OrderSuccesScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/configurator/failed",
      element: <OrderFailedScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard",
      element: <AdminLandingScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard/offertes",
      element: <AdminOfferteScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard/products",
      element: <AdminProductsScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard/translations",
      element: <AdminTranslationsScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard/users",
      element: <AdminUsersScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
