import React from "react";
import { WindowPaintOption } from "../../@types/aquarium";

type Props = {
  checked: WindowPaintOption;
  setValue: React.Dispatch<React.SetStateAction<WindowPaintOption>>;
};

function PaintColorMenu({ checked, setValue }: Props) {
  return (
    <div className="window-paint-menu-container">
      <div
        className={
          "paint-option-container " +
          (checked === WindowPaintOption.black ? "paint-option-active" : "")
        }
        onClick={() => setValue(WindowPaintOption.black)}
      >
        <input
          type="radio"
          name="window-paint-option"
          className="window-paint-option"
          id="window-paint-option-black"
        />
        <label htmlFor="window-paint-option-black">Zwart</label>
        <div className="window-paint-preview-black"></div>
      </div>
      <div
        className={
          "paint-option-container " +
          (checked === WindowPaintOption.blue ? "paint-option-active" : "")
        }
        onClick={() => setValue(WindowPaintOption.blue)}
      >
        <input
          type="radio"
          name="window-paint-option"
          className="window-paint-option"
          id="window-paint-option-blue"
        />
        <label htmlFor="window-paint-option-blue">Blauw</label>
        <div className="window-paint-preview-blue"></div>
      </div>
    </div>
  );
}

export default PaintColorMenu;
