import React, { useEffect, useState } from "react";
import ConfiguratorHeader from "./configurator/ConfiguratorHeader";
import ConfiguratorStepButton from "./configurator/ConfiguratorStepButton";
import { StepInfo, StepStatus, Steps } from "../../@types/configurator";
import ConfiguratorStepMenu from "./configurator/ConfiguratorStepMenu";
import GoToOverviewButton from "../molecules/GoToOverviewButton";
import ConfiguratorMenuFooter from "../molecules/ConfiguratorMenuFooter";
import DimensionsStep from "./steps/DimensionsStep";
import AquariumTypeStep from "./steps/AquariumTypeStep";
import WindowsStep from "./steps/WindowsStep";
import FilterStep from "./steps/FilterStep";
import UserDisclaimer from "./configurator/UserDisclaimer";
import FurnitureStep from "./steps/FurnitureStep";
import TechnicsStep from "./steps/TechnicsStep";
import { useDispatch, useSelector } from "react-redux";
import { RootState, checkNewAquariumDrawing } from "../../redux/store";
import {
  getIndividualStepInfo,
  updateStepInfoList,
} from "../../utils/stepConfigurator";
import { setOpenStep as setOpenStepRedux } from "../../redux/configuratorSlice";
import { motion } from "framer-motion";

function ControlCenter() {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const configurator = useSelector((state: RootState) => state.configurator);
  const user = useSelector((state: RootState) => state.user);
  const stepInfoListRedux = useSelector(
    (state: RootState) => state.configurator.stepInfoList
  );
  const stepItemInfoOverview = useSelector(
    (state: RootState) => state.configurator.stepItemInfoOverview
  );
  const dispatch = useDispatch();
  const [openStep, setOpenStep] = useState<Steps | null>(configurator.openStep);

  const getOverviewButtonDisabled = () => {
    const disableButton = stepInfoListRedux.filter(
      (item) => item.status !== StepStatus.isCompleted
    );
    return disableButton.length !== 0;
  };

  const [overviewButtonDisabled, setOverviewButtonDisabled] = useState<boolean>(
    getOverviewButtonDisabled()
  );

  useEffect(() => {
    // check here if a step is opened
    const updatedStepInfoList: StepInfo[] = stepInfoListRedux.map((step) =>
      step.title === openStep ? { ...step, opened: true } : step
    );

    updateStepInfoList(
      dispatch,
      updatedStepInfoList,
      aquarium,
      stepItemInfoOverview
    );
    setOverviewButtonDisabled(getOverviewButtonDisabled());
    checkNewAquariumDrawing();
  }, [aquarium, openStep]);

  useEffect(() => {
    dispatch(setOpenStepRedux(openStep));
  }, [openStep]);

  useEffect(() => {
    if (configurator.openStep === null) {
      setOpenStep(configurator.openStep);
    }
  }, [configurator]);

  return (
    <div className="control-center-container">
      <ConfiguratorHeader />
      <div
        id="control-center-body"
        style={{
          overflow:
            openStep === null && user.agreedToConditions ? "auto" : "hidden",
        }}
      >
        <UserDisclaimer />
        <ConfiguratorStepMenu setOpenStep={setOpenStep} />
        <div className="control-center-fill"></div>
        <GoToOverviewButton disabled={overviewButtonDisabled} />
        {openStep === Steps.dimensions ? (
          <DimensionsStep setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.aquariumType ? (
          <AquariumTypeStep setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.windowPaint ? (
          <WindowsStep setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.filter ? (
          <FilterStep setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.furniture ? (
          <FurnitureStep setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.technics ? (
          <TechnicsStep setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
      </div>
      <ConfiguratorMenuFooter />
    </div>
  );
}

export default ControlCenter;
