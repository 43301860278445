import { Dispatch } from "react";
import { UnknownAction } from "redux";
import {
  Aquarium,
  AquariumDimensions,
  AquariumType,
  BoreHole,
  DoorType,
  ElectraRuimte,
  FilterLocation,
  FilterOptions,
  FurnitureType,
  SideInfo,
  WindowPaintOption,
} from "../@types/aquarium";
import {
  changeWidth,
  changeDepth,
  changeHeight,
} from "../components/aquariumComponents/aquarium";
import { amountStripsFullGlass } from "../components/aquariumComponents/fullGlass";
import { amountStripLightCover } from "../components/aquariumComponents/lightCover";
import {
  changeAquariumType,
  changeBioCoverPanels,
  changeBoreHole,
  changeClosetElectraSwitchVisibility,
  changeClosetType,
  changeCoverPanels,
  changeFilter,
  changeFilterLocation,
  changeFurnitureTexture,
  changeGrindListVisbility,
  changeLightCover,
  changeLowerClosetHeight,
  changeOsmoseCompartiment,
  changePaintColor,
  changeUpperClosetHeight,
  makeAquariumSeeThrough,
  makeSideOptiWhite,
  paintSide,
  setBetonPlex,
  setDoorType,
  setElectraSpace,
  setFullGlassPanels,
  setRearWall,
  setTurtleIsland,
} from "../components/aquariumComponents/tank";
import { amountStripsTurtle } from "../components/aquariumComponents/turtle";
import { defaultAquarium, defaultDrawingAquarium } from "./defaultAquarium";
import {
  setFilterLocation,
  setLightCover as setLightCoverRedux,
} from "../redux/aquariumSlice";
import { setCoverPanels as setCoverPanelsRedux } from "../redux/aquariumSlice";
import { DecorTexture } from "../@types/textures";

export const checkDimensions = (dimensions: AquariumDimensions) => {
  const validWidth =
    dimensions.width < 50 || dimensions.width > 360 || !changeWidth(dimensions)
      ? false
      : true;

  const validDepth =
    dimensions.depth > dimensions.width ||
    dimensions.depth < 30 ||
    dimensions.depth > 110 ||
    !changeDepth(dimensions)
      ? false
      : true;

  const validHeight =
    dimensions.height < 30 ||
    dimensions.height > 115 ||
    !changeHeight(dimensions)
      ? false
      : true;

  return {
    isValidWidth: validWidth,
    isValidDepth: validDepth,
    isValidHeight: validHeight,
  };
};

export const calcAquariumVolume = (dimensions: AquariumDimensions) => {
  return (dimensions.width * dimensions.depth * dimensions.height) / 1000;
};

export const getAmountWidthStripsAquarium = (type: AquariumType) => {
  switch (type) {
    case AquariumType.fullGlass:
      return amountStripsFullGlass();
    case AquariumType.lightCover:
      return amountStripLightCover();
    case AquariumType.turtle:
      return amountStripsTurtle();

    default:
      return 0;
  }
};

function updateTypeOptions(dispatch: Dispatch<UnknownAction>) {
  dispatch(setCoverPanelsRedux(defaultAquarium.coverPanels));
  dispatch(setLightCoverRedux(defaultAquarium.lightCover));
}

const updateSide = (side: SideInfo) => {
  paintSide(side.sideName, side.colorPainted);
  makeSideOptiWhite(side.sideName, side.optiWhite);
};

let prevAquarium: Aquarium = defaultAquarium;
export const updateDrawing = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  //DIMENSIONS STEP
  changeWidth(aquarium.aquariumDimensions);
  changeDepth(aquarium.aquariumDimensions);
  changeHeight(aquarium.aquariumDimensions);

  //TODO: hier nog het verandere van de siliconenkleur toevoegen

  //AQUARIUM TYPE STEP
  if (aquarium.aquariumType !== null) {
    changeAquariumType(aquarium.aquariumType);
  } else {
    changeAquariumType(AquariumType.rimless);
  }
  if (aquarium.aquariumType !== prevAquarium.aquariumType) {
    updateTypeOptions(dispatch);
  }
  changeCoverPanels(aquarium.coverPanels);
  //TODO: hier nog het verandere van de afdekplatenkleur toevoegen
  changeLightCover(aquarium.lightCover);
  if (aquarium.fullGlassCoverPanels) {
    setFullGlassPanels(aquarium.fullGlassCoverPanels);
  }
  if (aquarium.turtleIsland) {
    setTurtleIsland(aquarium.turtleIsland);
  }
  changeGrindListVisbility(aquarium.grindlist);
  if (
    aquarium.aquariumTexture !== null &&
    aquarium.aquariumTexture !== prevAquarium.aquariumTexture
  ) {
    // TODO DAAN: zorg dat ik null kan meegeven aan changeFurnitureTexture zodat de huidige texture verwijderd wordt op de tekening
    // changeFurnitureTexture(defaultAquarium.aquariumTexture);
    changeFurnitureTexture(aquarium.aquariumTexture);
  }

  // WINDOW STEP
  aquarium.windowPaintColor === WindowPaintOption.black
    ? changePaintColor(0x000000)
    : changePaintColor(0x3d51ff);
  updateSide(aquarium.aquariumSides.front);
  updateSide(aquarium.aquariumSides.back);
  updateSide(aquarium.aquariumSides.left);
  updateSide(aquarium.aquariumSides.right);

  //FILTER STEP
  if (
    aquarium.filter.filterType !== prevAquarium.filter.filterType ||
    (aquarium.filter.filterType === FilterOptions.external &&
      !aquarium.filter.externalDryroom)
  ) {
    changeFilterLocation(FilterLocation.placeholder);
  }
  if (aquarium.filter.filterType) {
    changeFilter(aquarium.filter.filterType);
  } else {
    changeFilter(FilterOptions.none);
  }
  if (aquarium.filter.filterLocation !== null) {
    changeFilterLocation(aquarium.filter.filterLocation);
  } else {
    changeFilterLocation(FilterLocation.placeholder);
  }
  changeBioCoverPanels(aquarium.filter.bioCoverPanels);
  if (aquarium.filter.filterType === FilterOptions.sump) {
    changeOsmoseCompartiment(aquarium.filter.osmoseCompartiment);
  }
  changeBoreHole(BoreHole.links, aquarium.filter.boreholeLeft);
  changeBoreHole(BoreHole.rechts, aquarium.filter.boreholeRight);

  //FURNITURE STEP
  if (aquarium.furniture.furnitureType) {
    changeClosetType(aquarium.furniture.furnitureType);
  } else {
    changeClosetType(FurnitureType.none);
  }
  if (
    aquarium.furniture.furnitureType !== null ||
    aquarium.furniture.furnitureType !== FurnitureType.frameOnly
  ) {
    changeLowerClosetHeight(aquarium.furniture.dimensions.baseHeight);
    changeUpperClosetHeight(aquarium.furniture.dimensions.topHeight);
  } else {
    changeLowerClosetHeight(0);
  }
  setBetonPlex(aquarium.furniture.betonPlex);
  setRearWall(aquarium.furniture.backWall34);
  makeAquariumSeeThrough(aquarium.furniture.seeThrough);
  if (aquarium.furniture.electraRuimte !== null) {
    setElectraSpace(aquarium.furniture.electraRuimte);
  } else {
    setElectraSpace(ElectraRuimte.none);
  }
  if (aquarium.furniture.doorType) {
    setDoorType(aquarium.furniture.doorType);
  }
  changeClosetElectraSwitchVisibility(aquarium.furniture.aquaswitch);

  //zet de huidige aquariumconfiguratie nu ter vergelijking om de volgende aanpassingen mee te vergelijken
  prevAquarium = aquarium;
};
