import * as THREE from 'three';
import { makeGeometryMaterials, makePlank } from './helper';
import { DecorTexture } from '../../@types/textures';

const scale = 25;
const PI = 3.14159265359;

const closetThickness = 1.8;
const widthDistribution = [70,139,241,321,361]
const doorOverlap = 4;

let currWidth: number;
let currHeight: number;
let currDepth: number;
let currLowerClosetHeight: number;
let currUpperClosetHeight = 0;
let currAngle = 0;
let currWaterproofPlateHeight = 0
let currTexture: THREE.Texture[] = []

export function makeLowerSkeletonDoors(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, waterproofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
    closetPlanks[0] = [];
    closetPlanks[1] = [];
    let amountCompartiments = getAmountCompartiments(width)
    let compWidth =  (width+2*closetThickness)/amountCompartiments
    for(let i = 0; i < amountCompartiments; i++){
        //lower doors
        closetPlanks[0].push(makePlank(scene, -width/scale/2-closetThickness/scale+compWidth/scale/2+i*compWidth/scale,-height/scale/2-closetHeight/scale/2+closetThickness/scale/2+doorOverlap/scale/2-waterproofPlateHeight/scale, depth/scale/2+closetThickness/scale/2+0.01, compWidth/scale-0.01, closetHeight/scale+doorOverlap/scale, closetThickness/scale, 0x3d3d3d))
    }
    currWidth = width;
    currHeight = height;
    currDepth = depth;
    currLowerClosetHeight = closetHeight;
    let tempAngle = currAngle
    currAngle = 0
    currWaterproofPlateHeight = waterproofPlateHeight
    turnSkeletonDoors(tempAngle, closetPlanks);
    changeSkeletonDoorsTexture("", closetPlanks)
}

export function makeUpperSkeletonDoors(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, closetPlanks: THREE.Mesh[][]){
    let tempAngle = currAngle
    turnSkeletonDoors(-currAngle, closetPlanks)
    let amountCompartiments = getAmountCompartiments(width)
    let compWidth =  (width+2*closetThickness)/amountCompartiments
    for(let i = 0; i < amountCompartiments; i++){
        //upper doors
        closetPlanks[1].push(makePlank(scene, -width/scale/2-closetThickness/scale+compWidth/scale/2+i*compWidth/scale,height/scale/2+closetHeight/scale/2-closetThickness/scale/2-doorOverlap/scale/2, depth/scale/2+closetThickness/scale/2+0.01, compWidth/scale-0.01, closetHeight/scale+doorOverlap/scale, closetThickness/scale, 0x3d3d3d))
    }
    turnSkeletonDoors(tempAngle, closetPlanks)
    changeSkeletonDoorsTexture("", closetPlanks)
}

function getAmountCompartiments(width: number){
    for(let i = widthDistribution.length-1; i >= 0; i--){
        if(width > widthDistribution[i]){
            return (i+1)*2
        }
    }
    return 1;
}

export function changeSkeletonDoorDepth(depth: number, closetPlanks: THREE.Mesh[][]){
    if(closetPlanks.length  === 0){
        return;
    }
    for(let i = 0; i < closetPlanks[0].length; i++){
        closetPlanks[0][i].position.z += (depth-currDepth)/scale/2
    }
    for(let i = 0; i < closetPlanks[1].length; i++){
        closetPlanks[1][i].position.z += (depth-currDepth)/scale/2
    }
    currDepth = depth;
    changeSkeletonDoorsTexture("", closetPlanks)
}

export function changeSkeletonDoorHeight(height: number, closetPlanks: THREE.Mesh[][]){
    if(closetPlanks.length  === 0){
        return;
    }
    for(let i = 0; i < closetPlanks[0].length; i++){
        closetPlanks[0][i].position.y -= (height-currHeight)/scale/2
    }
    for(let i = 0; i < closetPlanks[1].length; i++){
        closetPlanks[1][i].position.y += (height-currHeight)/scale/2
    }
    currHeight = height;
    changeSkeletonDoorsTexture("", closetPlanks)
}

export function changeSkeletonDoorWidth(scene: THREE.Scene, width: number, closetPlanks: THREE.Mesh[][]){
    if(closetPlanks.length  === 0){
        return;
    }
    let tempAngle = currAngle
    turnSkeletonDoors(-tempAngle, closetPlanks);
    let amountComp = getAmountCompartiments(width)
    let compWidth = (width+2*closetThickness)/amountComp
    for(let i = 0; i < closetPlanks[0].length; i++){
        scene.remove(closetPlanks[0][i])
        //closetPlanks[0].splice(i, 1)
    }

    for(let i = 0; i < amountComp; i++){
        closetPlanks[0][i] = makePlank(scene, -width/scale/2-closetThickness/scale+compWidth/scale/2+i*compWidth/scale,-currHeight/scale/2-currLowerClosetHeight/scale/2+closetThickness/scale/2+doorOverlap/scale/2-currWaterproofPlateHeight/scale, currDepth/scale/2+closetThickness/scale/2+0.01, compWidth/scale-0.01, currLowerClosetHeight/scale+doorOverlap/scale, closetThickness/scale, 0x3d3d3d)
    }
    if(currUpperClosetHeight > 0){
        for(let i = 0; i < closetPlanks[1].length; i++){
            scene.remove(closetPlanks[1][i])
            //closetPlanks[1].splice(i, 1)
        }
    
        for(let i = 0; i < amountComp; i++){
            closetPlanks[1][i] = makePlank(scene, -width/scale/2-closetThickness/scale+compWidth/scale/2+i*compWidth/scale,currHeight/scale/2+currUpperClosetHeight/scale/2-closetThickness/scale/2-doorOverlap/scale/2, currDepth/scale/2+closetThickness/scale/2+0.01, compWidth/scale-0.01, currUpperClosetHeight/scale+doorOverlap/scale, closetThickness/scale, 0x3d3d3d)
        }
    }
    currWidth = width
    currAngle = 0
    turnSkeletonDoors(tempAngle, closetPlanks);
    changeSkeletonDoorsTexture("", closetPlanks)
}

export function changeLowerClosetSkeletonDoorHeight(closetHeight: number, closetPlanks: THREE.Mesh[][]){
    if(closetPlanks.length  === 0){
        return;
    }
    for(let i = 0; i < closetPlanks[0].length; i++){
        closetPlanks[0][i].position.y -= (closetHeight-currLowerClosetHeight)/scale/2
        closetPlanks[0][i].geometry.scale(1,(closetHeight/scale+doorOverlap/scale)/(currLowerClosetHeight/scale+doorOverlap/scale),1);
    }
    currLowerClosetHeight = closetHeight;
    changeSkeletonDoorsTexture("", closetPlanks)
}

export function changeUpperClosetSkeletonDoorHeight(scene: THREE.Scene, closetHeight: number, closetPlanks: THREE.Mesh[][]){
    if(closetPlanks.length  === 0){
        return;
    }
    if(closetHeight === 0){
        
        for(let i = closetPlanks[1].length-1; i >= 0; i--){
            scene.remove(closetPlanks[1][i])
            closetPlanks[1].splice(i,1)
        }
        currUpperClosetHeight = closetHeight;
        changeSkeletonDoorsTexture("", closetPlanks)
        return;
    }
    if(currUpperClosetHeight === 0 || closetPlanks[1].length === 0){
        makeUpperSkeletonDoors(scene, currWidth, currHeight, closetHeight, currDepth, closetPlanks)
        currUpperClosetHeight = closetHeight;
        changeSkeletonDoorsTexture("", closetPlanks)
        return;
    }
    for(let i = 0; i < closetPlanks[1].length; i++){
        closetPlanks[1][i].position.y += (closetHeight-currUpperClosetHeight)/scale/2
        closetPlanks[1][i].geometry.scale(1,(closetHeight/scale+doorOverlap/scale)/(currUpperClosetHeight/scale+doorOverlap/scale),1);
    }
    currUpperClosetHeight = closetHeight;
    changeSkeletonDoorsTexture("", closetPlanks)
}

//angle should be in radians
export function turnSkeletonDoors(angle: number, closetPlanks: THREE.Mesh[][]){
    if(closetPlanks.length === 0){
        return;
    }
    currAngle=(currAngle+angle)%(2*PI);

    let amount_comp = getAmountCompartiments(currWidth);
    let compWidth = (currWidth+2*closetThickness)/amount_comp;
    for(let j = 0; j < 2; j++){
        let side = 1;
        for(let i = 0; i < closetPlanks[j].length; i++){
            if(i === amount_comp-1 && i !== 0){
                side = -1
            }
            //closetPlanks[0][i].rotateY(currAngle)
            //most left point of the door
            let old_x = -currWidth/scale/2-closetThickness/scale+i*compWidth/scale+compWidth/scale/2+side*-1*compWidth/scale/2
            let old_z = currDepth/scale/2+closetThickness/scale/2+0.01
            //new middle point
            let new_x = old_x+(compWidth/scale-0.01)*Math.cos(currAngle)/2*side
            let new_z = old_z+(compWidth/scale-0.01)*Math.sin(currAngle)/2
            closetPlanks[j][i].position.x = new_x;
            closetPlanks[j][i].position.z = new_z;
            closetPlanks[j][i].rotateY(-angle*side)
            side = side*-1
        }
    }
}

export function getSkeletonDoorAngle(){
    return currAngle;
}

export function skeletonDoorsChangeWaterProofPlateHeight(newWaterProofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
    for(let i = 0; i < closetPlanks[0].length; i++){
        closetPlanks[0][i].position.y += (currWaterproofPlateHeight-newWaterProofPlateHeight)/scale
    }
    currWaterproofPlateHeight = newWaterProofPlateHeight
  }

export function changeSkeletonDoorsTexture(texture: String, closetPlanks: THREE.Mesh[][]){
    if(texture !== ""){
        currTexture = [new THREE.TextureLoader().load(String(texture)),new THREE.TextureLoader().load(String(texture))]
    }
    let amountCompartiments = getAmountCompartiments(currWidth)
    let compWidth =  (currWidth+2*closetThickness)/amountCompartiments
    if(currTexture.length !== 0){
        for(let i = 0; i < amountCompartiments; i++){
            //lower doors
            closetPlanks[0][i].material = makeGeometryMaterials(currTexture[0], compWidth/scale-0.01, closetThickness/scale, currLowerClosetHeight/scale+doorOverlap/scale)
        }
        if(currUpperClosetHeight > 0){
            for(let i = 0; i < amountCompartiments; i++){
                //upper doors
                closetPlanks[1][i].material = makeGeometryMaterials(currTexture[1], compWidth/scale-0.01, closetThickness/scale, currUpperClosetHeight/scale+doorOverlap/scale)
            }
        }
    }
}