import React from "react";
import { StepInfo, StepStatus, Steps } from "../../../@types/configurator";
import ConfiguratorStepButton from "./ConfiguratorStepButton";
import {
  Cards,
  Cube,
  Dresser,
  Funnel,
  Plus,
  Ruler,
} from "@phosphor-icons/react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { getIndividualStepInfo } from "../../../utils/stepConfigurator";

type Props = {
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function ConfiguratorStepMenu({ setOpenStep }: Props) {
  const stepInfoList = useSelector(
    (state: RootState) => state.configurator.stepInfoList
  );

  const isAllowedToOpen = (stepId: Steps) => {
    const openStep = stepInfoList.find((step) =>
      step.title === stepId && step.disabled === false ? true : false
    );
    openStep ? setOpenStep(stepId) : setOpenStep(null);
  };

  return (
    <div id="configurator-steps-menu-container">
      <div
        onClick={() => isAllowedToOpen(Steps.dimensions)}
        className="configurator-menu-button-container"
      >
        <ConfiguratorStepButton
          stepkey={"step-" + Steps.dimensions}
          status={getIndividualStepInfo(Steps.dimensions, stepInfoList).status}
          title="Afmetingen"
          stepnumber={1}
          symbol={<Ruler size={64} color="var(--neutral-250)" />}
          disabled={
            getIndividualStepInfo(Steps.dimensions, stepInfoList).disabled
          }
          stepId={Steps.dimensions}
        />
      </div>
      <div
        onClick={() => isAllowedToOpen(Steps.aquariumType)}
        className="configurator-menu-button-container"
      >
        <ConfiguratorStepButton
          stepkey={"step-" + Steps.aquariumType}
          status={
            getIndividualStepInfo(Steps.aquariumType, stepInfoList).status
          }
          title="Aquarium type"
          stepnumber={2}
          symbol={<Cube size={64} color="var(--neutral-250)" />}
          disabled={
            getIndividualStepInfo(Steps.aquariumType, stepInfoList).disabled
          }
          stepId={Steps.aquariumType}
        />
      </div>
      <div
        onClick={() => isAllowedToOpen(Steps.windowPaint)}
        className="configurator-menu-button-container"
      >
        <ConfiguratorStepButton
          stepkey={"step-" + Steps.windowPaint}
          status={getIndividualStepInfo(Steps.windowPaint, stepInfoList).status}
          title="Ruiten verven"
          stepnumber={3}
          symbol={<Cards size={64} color="var(--neutral-250)" />}
          disabled={
            getIndividualStepInfo(Steps.windowPaint, stepInfoList).disabled
          }
          stepId={Steps.windowPaint}
        />
      </div>
      <div
        onClick={() => isAllowedToOpen(Steps.filter)}
        className="configurator-menu-button-container"
      >
        <ConfiguratorStepButton
          stepkey={"step-" + Steps.filter}
          status={getIndividualStepInfo(Steps.filter, stepInfoList).status}
          title="Filtersysteem"
          stepnumber={4}
          symbol={<Funnel size={64} color="var(--neutral-250)" />}
          disabled={getIndividualStepInfo(Steps.filter, stepInfoList).disabled}
          stepId={Steps.filter}
        />
      </div>
      <div
        onClick={() => isAllowedToOpen(Steps.furniture)}
        className="configurator-menu-button-container"
      >
        <ConfiguratorStepButton
          stepkey={"step-" + Steps.furniture}
          status={getIndividualStepInfo(Steps.furniture, stepInfoList).status}
          title="Meubelafwerking"
          stepnumber={5}
          symbol={<Dresser size={64} color="var(--neutral-250)" />}
          disabled={
            getIndividualStepInfo(Steps.furniture, stepInfoList).disabled
          }
          stepId={Steps.furniture}
        />
      </div>
      <div
        onClick={() => isAllowedToOpen(Steps.technics)}
        className="configurator-menu-button-container"
      >
        <ConfiguratorStepButton
          stepkey={"step-" + Steps.technics}
          status={getIndividualStepInfo(Steps.technics, stepInfoList).status}
          title="Basis technieken"
          stepnumber={6}
          symbol={<Plus size={64} color="var(--neutral-250)" />}
          disabled={
            getIndividualStepInfo(Steps.technics, stepInfoList).disabled
          }
          stepId={Steps.technics}
        />
      </div>
    </div>
  );
}

export default ConfiguratorStepMenu;
