import {
  Aquarium,
  AquariumType,
  ElectraRuimte,
  FilterLocation,
  FilterOptions,
  FurnitureType,
  TurtleIsland,
  WaterType,
} from "../@types/aquarium";
import { StepItemInfoList, StepStatus, Steps } from "../@types/configurator";
import { checkDimensions } from "./aquarium";

export const getStepIsDisabled = (stepId: Steps, aquarium: Aquarium) => {
  switch (stepId) {
    case Steps.dimensions:
      return false;
      break;
    case Steps.aquariumType:
      return false;
    case Steps.windowPaint:
      return false;
      break;
    case Steps.filter:
      return aquarium.waterType !== null && aquarium.aquariumType !== null
        ? false
        : true;
      break;
    case Steps.furniture:
      return aquarium.waterType !== null &&
        aquarium.aquariumType !== null &&
        aquarium.filter.filterType !== null
        ? false
        : true;
      break;
    case Steps.technics:
      return aquarium.waterType !== null &&
        aquarium.aquariumType !== null &&
        aquarium.filter.filterType !== null &&
        aquarium.furniture.furnitureType !== null
        ? false
        : true;

      break;
    default:
      return true;
      break;
  }
};

export const getStepProgress = (
  stepTitle: Steps,
  isOpened: boolean,
  aquarium: Aquarium
) => {
  //check als deze volledig is ingevuld
  const isCompleted = getStepIsComplete(stepTitle, aquarium);

  switch (stepTitle) {
    case Steps.dimensions:
      const hasAquariumDimensionsIssues = checkAquariumDimensions(aquarium);
      return isOpened
        ? hasAquariumDimensionsIssues
          ? StepStatus.hasIssue
          : isCompleted
          ? StepStatus.isCompleted
          : StepStatus.toDo
        : StepStatus.toDo;
      break;

    case Steps.aquariumType:
      //check als er geen issues zijn
      const hasAquariumtypeIssues =
        checkAquariumType(aquarium) || checkTurtleIslandLocation(aquarium);
      return isOpened
        ? hasAquariumtypeIssues
          ? StepStatus.hasIssue
          : isCompleted
          ? StepStatus.isCompleted
          : StepStatus.toDo
        : StepStatus.toDo;
      break;

    case Steps.windowPaint:
      const hasWindowPaintIssues = false;
      return isOpened
        ? hasWindowPaintIssues
          ? StepStatus.hasIssue
          : isCompleted
          ? StepStatus.isCompleted
          : StepStatus.toDo
        : StepStatus.toDo;
      break;

    case Steps.filter:
      const hasFilterIssues =
        checkFilterType(aquarium) || checkFilterLocation(aquarium);
      return isOpened
        ? hasFilterIssues
          ? StepStatus.hasIssue
          : isCompleted
          ? StepStatus.isCompleted
          : StepStatus.toDo
        : StepStatus.toDo;
      break;

    case Steps.furniture:
      const hasFurnitureIssues = checkFurnitureType(aquarium);
      return isOpened
        ? hasFurnitureIssues
          ? StepStatus.hasIssue
          : isCompleted
          ? StepStatus.isCompleted
          : StepStatus.toDo
        : StepStatus.toDo;
      break;

    case Steps.technics:
      const hasTechnicsIssues = checkFurnitureType(aquarium);
      return isOpened
        ? hasTechnicsIssues
          ? StepStatus.hasIssue
          : isCompleted
          ? StepStatus.isCompleted
          : StepStatus.toDo
        : StepStatus.toDo;
      break;

    default:
      break;
  }
  return StepStatus.toDo;
};

//Deze functies geven {hasIssue: boolean} terug per apart onderdeel van een stap
export const checkAquariumDimensions = (aquarium: Aquarium) => {
  const checkedDimensions = checkDimensions(aquarium.aquariumDimensions);
  return checkedDimensions.isValidWidth &&
    checkedDimensions.isValidDepth &&
    checkedDimensions.isValidHeight
    ? false
    : true;
};
export const checkWaterType = (aquarium: Aquarium) => {};
export const checkAquariumType = (aquarium: Aquarium) => {
  return aquarium.aquariumType === AquariumType.turtle &&
    aquarium.waterType === WaterType.salty
    ? true
    : false;
};
export const checkTurtleIslandLocation = (aquarium: Aquarium) => {
  const option = aquarium.turtleIsland;
  return (
    (aquarium.aquariumDimensions.width < 80 &&
      (option === TurtleIsland.left || option === TurtleIsland.right)) ||
    (aquarium.aquariumDimensions.width < 120 &&
      option === TurtleIsland.twosided)
  );
};
export const checkFilterType = (aquarium: Aquarium) => {
  return (aquarium.waterType === WaterType.fresh &&
    aquarium.filter.filterType === FilterOptions.sump) ||
    (aquarium.waterType === WaterType.salty &&
      (aquarium.filter.filterType === FilterOptions.bio ||
        aquarium.filter.filterType === FilterOptions.biological ||
        aquarium.filter.filterType === FilterOptions.external)) ||
    (aquarium.furniture.dimensions.baseHeight < 60 &&
      aquarium.filter.filterType === FilterOptions.bio) ||
    (aquarium.furniture.dimensions.baseHeight < 60 &&
      aquarium.filter.filterType === FilterOptions.sump) ||
    (aquarium.aquariumDimensions.width < 132 &&
      (aquarium.filter.filterType === FilterOptions.bio ||
        aquarium.filter.filterType === FilterOptions.sump) &&
      aquarium.furniture.furnitureType === FurnitureType.marine)
    ? true
    : false;
};
export const checkFilterLocation = (aquarium: Aquarium) => {
  return (
    aquarium.furniture.seeThrough &&
    aquarium.filter.filterLocation === FilterLocation.midBack
  );
};
export const checkFurnitureType = (aquarium: Aquarium) => {
  return false;
};

export const checkElectraRuimte = (aquarium: Aquarium) => {
  return aquarium.aquariumDimensions.width < 100 &&
    (aquarium.furniture.electraRuimte === ElectraRuimte.left ||
      aquarium.furniture.electraRuimte === ElectraRuimte.right)
    ? true
    : false;
};

// deze controleerd of een stap volledig is ingevuld of niet: boolean
export const getStepIsComplete = (stepId: Steps, aquarium: Aquarium) => {
  switch (stepId) {
    case Steps.dimensions:
      return aquarium.aquariumDimensions && aquarium.sharpenGlass !== null
        ? true
        : false;
      break;
    case Steps.aquariumType:
      if (aquarium.grindlist && !aquarium.aquariumTexture) {
        return false;
      } else {
        switch (aquarium.aquariumType) {
          case AquariumType.rimless:
            return true;
          case AquariumType.lightCover:
            return aquarium.aquariumTexture && aquarium.coverPanels !== null
              ? true
              : false;
          case AquariumType.fullGlass:
            return aquarium.fullGlassCoverPanels ? true : false;
          case AquariumType.turtle:
            return (aquarium.turtleIsland && !aquarium.lightCover) ||
              (aquarium.turtleIsland &&
                aquarium.lightCover &&
                aquarium.aquariumTexture)
              ? true
              : false;
          default:
            return false;
        }
      }
      break;
    case Steps.windowPaint:
      return aquarium.windowPaintColor ? true : false;
      break;
    case Steps.filter:
      switch (aquarium.filter.filterType) {
        case FilterOptions.none:
          return true;
          break;
        case FilterOptions.biological:
          return aquarium.filter.filterLocation ? true : false;
          break;
        case FilterOptions.bio:
          return aquarium.filter.filterLocation &&
            aquarium.filter.bioCoverPanels !== null &&
            aquarium.extra.pvcPipeworkPackage !== null
            ? true
            : false;
          break;
        case FilterOptions.external:
          return !aquarium.filter.externalDryroom ||
            (aquarium.filter.externalDryroom && aquarium.filter.filterLocation)
            ? true
            : false;
          break;
        case FilterOptions.sump:
          return aquarium.filter.filterLocation &&
            aquarium.filter.osmoseCompartiment !== null &&
            aquarium.extra.pvcPipeworkPackage !== null
            ? true
            : false;
          break;

        default:
          return false;
          break;
      }
      break;
    case Steps.furniture:
      switch (aquarium.furniture.furnitureType) {
        case FurnitureType.none:
          return true;
          break;
        case FurnitureType.frameOnly:
          return aquarium.furniture.dimensions ? true : false;
          break;
        case FurnitureType.marine:
          return aquarium.furniture.dimensions ? true : false;
          break;
        case FurnitureType.decor:
          return aquarium.furniture.dimensions &&
            aquarium.furniture.electraRuimte
            ? true
            : false;
          break;
        case FurnitureType.aluminium:
          return aquarium.furniture.dimensions &&
            aquarium.furniture.electraRuimte &&
            aquarium.furniture.doorType
            ? true
            : false;
          break;

        default:
          break;
      }
      break;
    case Steps.technics:
      return true;
      break;

    default:
      return false;
      break;
  }
};
