import * as THREE from "three";
import { makeCylinder, makePlank } from "./helper";
import filterWidth from "./jsonFiles/filterWidthInfo.json";
import { ElectraRuimte } from "../../@types/aquarium";
import electra from "../../images/electrakast.png"
import { getGlassWidth } from "./tank";

const scale = 25;
const PI = 3.14159265359;

const widthDistribution = [139, 241, 321, 361];
const hingeDepthDiff = 8; //poles on the sides should be more to the back to make sure the hinges of the doors fit
export const bottomHeightDiff = 3; //on the bottom the pole is lifted a bit
const legsLowerRadius = 2;
const legsUpperRadius = 1;
const legsHeight = 1;

const thicknessVolume = 400; //if volume of aquarium is larger than this value, the pole should become thicker
const smallPileThickness = 3; //width and height of poles when volume is less than thicknessVolume
const thickPileThickness = 4; //width and height of poles when volume is larger than thicknessVolume
const betonplexThickness = 1.8;
export const electraPlateWidth = 15;
const electrabuttonsHeight = 45;
const electrabuttonsWidth = 5;
const electrabuttonsDepthDiff = 5; // how much the button panel should be placed back

let currWidth: number;
let currHeight: number;
let currDepth: number;
let currLowerClosetHeight: number;
let currUpperClosetHeight = 0;
let betonPlexVisible = false;
let rearWallVisible = false;
let currElectraSide = ElectraRuimte.none;
let currWaterproofPlateHeight = 0
let currElectraSwitchVisible = false;

export function makeSkeletonCloset(
  scene: THREE.Scene,
  width: number,
  height: number,
  closetHeight: number,
  depth: number,
  waterproofPlateHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  let poleThickness = getPoleThickness(width, height, depth);
  let amountComp = getAmountSkeletonCompartiments(width);
  let compWidth = (width - poleThickness) / amountComp;

  closetPlanks.push([]); //lower doors
  closetPlanks.push([]); //upper doors
  closetPlanks.push([]); //horizontal poles
  //front upper pole
  closetPlanks[2].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 - poleThickness / scale / 2 - waterproofPlateHeight/scale,
      depth / scale / 2 - poleThickness / scale / 2,
      width / scale,
      poleThickness / scale,
      poleThickness / scale,
      0xa6a6a6
    )
  );
  //back upper pole
  closetPlanks[2].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 - poleThickness / scale / 2 - waterproofPlateHeight/scale,
      -depth / scale / 2 + poleThickness / scale / 2,
      width / scale,
      poleThickness / scale,
      poleThickness / scale,
      0xa6a6a6
    )
  );
  //front lower pole
  closetPlanks[2].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 -
        closetHeight / scale +
        poleThickness / scale / 2 +
        bottomHeightDiff / scale - waterproofPlateHeight/scale,
      depth / scale / 2 - poleThickness / scale / 2,
      width / scale,
      poleThickness / scale,
      poleThickness / scale,
      0xa6a6a6
    )
  );
  //back lower pole
  closetPlanks[2].push(
    makePlank(
      scene,
      0,
      -height / scale / 2 -
        closetHeight / scale +
        poleThickness / scale / 2 +
        bottomHeightDiff / scale - waterproofPlateHeight/scale,
      -depth / scale / 2 + poleThickness / scale / 2,
      width / scale,
      poleThickness / scale,
      poleThickness / scale,
      0xa6a6a6
    )
  );

  closetPlanks.push([]); //compartiment poles
  for (let i = 0; i < amountComp + 1; i++) {
    //front pole
    closetPlanks[3].push(
      makePlank(
        scene,
        -width / scale / 2 +
          poleThickness / scale / 2 +
          (i * compWidth) / scale,
        -height / scale / 2 - closetHeight / scale / 2 + legsHeight / scale / 2 - waterproofPlateHeight/scale,
        depth / scale / 2 - poleThickness / scale / 2,
        poleThickness / scale,
        closetHeight / scale - legsHeight / scale,
        poleThickness / scale,
        0xa6a6a6
      )
    );
    //back pole
    closetPlanks[3].push(
      makePlank(
        scene,
        -width / scale / 2 +
          poleThickness / scale / 2 +
          (i * compWidth) / scale,
        -height / scale / 2 - closetHeight / scale / 2 + legsHeight / scale / 2 - waterproofPlateHeight/scale,
        -depth / scale / 2 + poleThickness / scale / 2,
        poleThickness / scale,
        closetHeight / scale - legsHeight / scale,
        poleThickness / scale,
        0xa6a6a6
      )
    );
    //upper pole
    closetPlanks[3].push(
      makePlank(
        scene,
        -width / scale / 2 +
          poleThickness / scale / 2 +
          (i * compWidth) / scale,
        -height / scale / 2 - poleThickness / scale / 2 - waterproofPlateHeight/scale,
        0,
        poleThickness / scale,
        poleThickness / scale,
        depth / scale,
        0xa6a6a6
      )
    );
    //lower pole
    closetPlanks[3].push(
      makePlank(
        scene,
        -width / scale / 2 +
          poleThickness / scale / 2 +
          (i * compWidth) / scale,
        -height / scale / 2 -
          closetHeight / scale +
          poleThickness / scale / 2 +
          bottomHeightDiff / scale - waterproofPlateHeight/scale,
        0,
        poleThickness / scale,
        poleThickness / scale,
        depth / scale,
        0xa6a6a6
      )
    );
  }

  //legs
  closetPlanks.push([]);
  for (let i = 0; i < amountComp + 1; i++) {
    closetPlanks[4].push(
      makeCylinder(
        scene,
        -width / scale / 2 +
          poleThickness / scale / 2 +
          (i * compWidth) / scale,
        -height / scale / 2 - closetHeight / scale + legsHeight / scale / 2 - waterproofPlateHeight/scale,
        depth / scale / 2 - poleThickness / scale / 2,
        legsUpperRadius / scale,
        legsHeight / scale,
        0x000000,
        legsLowerRadius / scale
      )
    );
    closetPlanks[4].push(
      makeCylinder(
        scene,
        -width / scale / 2 +
          poleThickness / scale / 2 +
          (i * compWidth) / scale,
        -height / scale / 2 - closetHeight / scale + legsHeight / scale / 2 - waterproofPlateHeight/scale,
        -depth / scale / 2 + poleThickness / scale / 2,
        legsUpperRadius / scale,
        legsHeight / scale,
        0x000000,
        legsLowerRadius / scale
      )
    );
  }
  currWidth = width;
  currHeight = height;
  currLowerClosetHeight = closetHeight;
  currDepth = depth;
  currWaterproofPlateHeight = waterproofPlateHeight
  makeBetonPlex(scene, closetPlanks);
  changeSkeletonBetonPlex(scene, betonPlexVisible, closetPlanks);

  makeRearWall(scene, closetPlanks);
  changeSkeletonRearWall(scene, rearWallVisible, closetPlanks);
  makeElectraRoom(scene, currElectraSide, closetPlanks);
}

export function makeUpperSkeletonCloset(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, closetPlanks: THREE.Mesh[][]){
  if(closetHeight === 0){
    return
  }
  let poleThickness = getPoleThickness(width, height, depth);
  let amountComp = getAmountSkeletonCompartiments(width);
  let compWidth = (width - poleThickness) / amountComp;
  let glassWidth = getGlassWidth(width, height, depth)
  closetPlanks[6] = []
  //front upper pole
  closetPlanks[6].push(makePlank(scene, 0, height/scale/2+closetHeight/scale-poleThickness/scale/2-glassWidth/2, depth/scale/2-poleThickness/scale/2, width/scale, poleThickness/scale, poleThickness/scale,0xa6a6a6))
  //back upper pole
  closetPlanks[6].push(makePlank(scene, 0, height/scale/2+closetHeight/scale-poleThickness/scale/2-glassWidth/2, -depth/scale/2+poleThickness/scale/2, width/scale, poleThickness/scale, poleThickness/scale,0xa6a6a6))
  //front lower pole
  closetPlanks[6].push(makePlank(scene, 0, height/scale/2+poleThickness/scale/2-glassWidth/2, depth/scale/2-poleThickness/scale/2, width/scale, poleThickness/scale, poleThickness/scale,0xa6a6a6))
  //back lower pole
  closetPlanks[6].push(makePlank(scene, 0, height/scale/2+poleThickness/scale/2-glassWidth/2, -depth/scale/2+poleThickness/scale/2, width/scale, poleThickness/scale, poleThickness/scale,0xa6a6a6))
  
  //compartimentpoles
  for (let i = 0; i < amountComp + 1; i++){
    //front pole
    closetPlanks[6].push(makePlank(
      scene,
      -width / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
      height / scale / 2 + closetHeight / scale / 2 - glassWidth/2,
      depth / scale / 2 - poleThickness / scale / 2,
      poleThickness / scale,
      closetHeight / scale,
      poleThickness / scale,
      0xa6a6a6
    ))
    //back pole
    closetPlanks[6].push(makePlank(
      scene,
      -width / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
        height / scale / 2 + closetHeight / scale / 2 - glassWidth/2,
      -depth / scale / 2 + poleThickness / scale / 2,
      poleThickness / scale,
      closetHeight / scale,
      poleThickness / scale,
      0xa6a6a6
    ))
    //upper pole
    closetPlanks[6].push(makePlank(
      scene,
      -width / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
        height/scale/2+closetHeight/scale-poleThickness/scale/2-glassWidth/2,
      0,
      poleThickness / scale,
      poleThickness / scale,
      depth / scale,
      0xa6a6a6
    )
  )
  //lower pole
    closetPlanks[6].push(makePlank(
      scene,
      -width / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
        height/scale/2+poleThickness/scale/2-glassWidth/2,
      0,
      poleThickness / scale,
      poleThickness / scale,
      depth / scale,
      0xa6a6a6
    ))
  }

  currWidth = width
  currHeight = height
  currDepth = depth
  currUpperClosetHeight = closetHeight
}

export function changeUpperSkeletonClosetHeight(scene: THREE.Scene, newClosetHeight: number, closetPlanks: THREE.Mesh[][]){
  if(newClosetHeight === 0 && currUpperClosetHeight === 0)
    return

  if(newClosetHeight === 0 && currUpperClosetHeight !== 0){
    for(let i = 0; i < closetPlanks[6].length; i++){
      scene.remove(closetPlanks[6][i]);
    }
  }else if(newClosetHeight !== 0 && currUpperClosetHeight === 0){
    makeUpperSkeletonCloset(scene, currWidth, currHeight, newClosetHeight, currDepth, closetPlanks)
  }else{
    closetPlanks[6][0].position.y += (newClosetHeight - currUpperClosetHeight) / scale;
    closetPlanks[6][1].position.y += (newClosetHeight - currUpperClosetHeight) / scale;
    let amountComp = getAmountSkeletonCompartiments(currWidth);
    for (let i = 0; i < amountComp + 1; i++){
      closetPlanks[6][4+i*4].geometry.scale(1,newClosetHeight/currUpperClosetHeight,1)
      closetPlanks[6][4+i*4+1].geometry.scale(1,newClosetHeight/currUpperClosetHeight,1)
      closetPlanks[6][4+i*4].position.y += (newClosetHeight - currUpperClosetHeight) / scale/2;
      closetPlanks[6][4+i*4+1].position.y += (newClosetHeight - currUpperClosetHeight) / scale/2;
      closetPlanks[6][4+i*4+2].position.y += (newClosetHeight - currUpperClosetHeight) / scale;
    }
  }
  currUpperClosetHeight = newClosetHeight
}

export function getPoleThickness(width: number, height: number, depth: number) {
  if ((width * height * depth) / 1000 > thicknessVolume) {
    return thickPileThickness;
  }
  return smallPileThickness;
}

export function getAmountSkeletonCompartiments(width: number) {
  for (let i = 0; i < widthDistribution.length; i++) {
    if (width < widthDistribution[i]) {
      return i + 1;
    }
  }
  return widthDistribution.length;
}

export function changeSkeletonWidth(
  scene: THREE.Scene,
  newWidth: number,
  closetPlanks: THREE.Mesh[][]
) {
  if (currWidth === newWidth) {
    return;
  }

  let amountComp = getAmountSkeletonCompartiments(newWidth);
  let poleThickness = getPoleThickness(newWidth, currHeight, currDepth);
  let compWidth = (newWidth - poleThickness) / amountComp;
  if (poleThickness !== getPoleThickness(currWidth, currHeight, currDepth)) {
    for (let i = 2; i < 5; i++) {
      for (let j = closetPlanks[i].length - 1; j >= 0; j--) {
        scene.remove(closetPlanks[i][j]);
        closetPlanks[i].splice(j, 1);
      }
    }
    for(let i = closetPlanks[6].length-1; i >= 0; i--){
      scene.remove(closetPlanks[6][i])
      closetPlanks[6].splice(i, 1);
    }
    makeSkeletonCloset(
      scene,
      newWidth,
      currHeight,
      currLowerClosetHeight,
      currDepth,
      currWaterproofPlateHeight,
      closetPlanks
    );
    makeUpperSkeletonCloset(
      scene,
      newWidth,
      currHeight,
      currUpperClosetHeight,
      currDepth,
      closetPlanks
    );
    if (betonPlexVisible) {
      makeBetonPlex(scene, closetPlanks);
    }
    if (rearWallVisible) {
      makeRearWall(scene, closetPlanks);
    }
    return;
  }
  for (let i = 0; i < closetPlanks[2].length; i++) {
    closetPlanks[2][i].geometry.scale(newWidth / currWidth, 1, 1);
  }

  for (let i = 3; i < 5; i++) {
    for (let j = 0; j < closetPlanks[i].length; j++) {
      scene.remove(closetPlanks[i][j]);
    }
  }

  for (let i = 0; i < amountComp + 1; i++) {
    //front pole
    closetPlanks[3][i * 4] = makePlank(
      scene,
      -newWidth / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale / 2 +
        legsHeight / scale / 2 - currWaterproofPlateHeight/scale,
      currDepth / scale / 2 - poleThickness / scale / 2,
      poleThickness / scale,
      currLowerClosetHeight / scale - legsHeight / scale,
      poleThickness / scale,
      0xa6a6a6
    );
    //back pole
    closetPlanks[3][i * 4 + 1] = makePlank(
      scene,
      -newWidth / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale / 2 +
        legsHeight / scale / 2 - currWaterproofPlateHeight/scale,
      -currDepth / scale / 2 + poleThickness / scale / 2,
      poleThickness / scale,
      currLowerClosetHeight / scale - legsHeight / scale,
      poleThickness / scale,
      0xa6a6a6
    );
    //upper pole
    closetPlanks[3][i * 4 + 2] = makePlank(
      scene,
      -newWidth / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
      -currHeight / scale / 2 - poleThickness / scale / 2 - currWaterproofPlateHeight/scale,
      0,
      poleThickness / scale,
      poleThickness / scale,
      currDepth / scale,
      0xa6a6a6
    );
    //lower pole
    closetPlanks[3][i * 4 + 3] = makePlank(
      scene,
      -newWidth / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale +
        poleThickness / scale / 2 +
        bottomHeightDiff / scale - currWaterproofPlateHeight/scale,
      0,
      poleThickness / scale,
      poleThickness / scale,
      currDepth / scale,
      0xa6a6a6
    );
  }

  //legs
  for (let i = 0; i < amountComp + 1; i++) {
    closetPlanks[4][i * 2] = makeCylinder(
      scene,
      -newWidth / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale +
        legsHeight / scale / 2 - currWaterproofPlateHeight/scale,
      currDepth / scale / 2 - poleThickness / scale / 2,
      legsUpperRadius / scale,
      legsHeight / scale,
      0x000000,
      legsLowerRadius / scale
    );
    closetPlanks[4][i * 2 + 1] = makeCylinder(
      scene,
      -newWidth / scale / 2 +
        poleThickness / scale / 2 +
        (i * compWidth) / scale,
      -currHeight / scale / 2 -
        currLowerClosetHeight / scale +
        legsHeight / scale / 2 - currWaterproofPlateHeight/scale,
      -currDepth / scale / 2 + poleThickness / scale / 2,
      legsUpperRadius / scale,
      legsHeight / scale,
      0x000000,
      legsLowerRadius / scale
    );
  }
  //upper skeleton
  for (let i = 0; i < 4; i++) {
    closetPlanks[6][i].geometry.scale(newWidth / currWidth, 1, 1);
  }
  for(let i = closetPlanks[6].length-1; i >= 4; i--){
    scene.remove(closetPlanks[6][i])
    closetPlanks[6].splice(i, 1);
  }
  let glassWidth = getGlassWidth(newWidth, currHeight, currDepth)
//compartimentpoles
for (let i = 0; i < amountComp + 1; i++){
  //front pole
  closetPlanks[6].push(makePlank(
    scene,
    -newWidth / scale / 2 +
      poleThickness / scale / 2 +
      (i * compWidth) / scale,
      currHeight / scale / 2 + currUpperClosetHeight / scale / 2 - glassWidth/2,
    currDepth / scale / 2 - poleThickness / scale / 2,
    poleThickness / scale,
    currUpperClosetHeight / scale,
    poleThickness / scale,
    0xa6a6a6
  ))
  //back pole
  closetPlanks[6].push(makePlank(
    scene,
    -newWidth / scale / 2 +
      poleThickness / scale / 2 +
      (i * compWidth) / scale,
      currHeight / scale / 2 + currUpperClosetHeight / scale / 2 - glassWidth/2,
    -currDepth / scale / 2 + poleThickness / scale / 2,
    poleThickness / scale,
    currUpperClosetHeight / scale,
    poleThickness / scale,
    0xa6a6a6
  ))
  //upper pole
  closetPlanks[6].push(makePlank(
    scene,
    -newWidth / scale / 2 +
      poleThickness / scale / 2 +
      (i * compWidth) / scale,
      currHeight/scale/2+currUpperClosetHeight/scale-poleThickness/scale/2-glassWidth/2,
    0,
    poleThickness / scale,
    poleThickness / scale,
    currDepth / scale,
    0xa6a6a6
  )
)
//lower pole
  closetPlanks[6].push(makePlank(
    scene,
    -newWidth / scale / 2 +
      poleThickness / scale / 2 +
      (i * compWidth) / scale,
      currHeight/scale/2+poleThickness/scale/2-glassWidth/2,
    0,
    poleThickness / scale,
    poleThickness / scale,
    currDepth / scale,
    0xa6a6a6
  ))
}

  currWidth = newWidth;

  if (betonPlexVisible) {
    makeBetonPlex(scene, closetPlanks);
  }
  if (rearWallVisible) {
    makeRearWall(scene, closetPlanks);
  }
  makeElectraRoom(scene, currElectraSide, closetPlanks);
}

export function changeSkeletonHeight(
  scene: THREE.Scene,
  newHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  if (currHeight === newHeight) {
    return;
  }

  if (
    getPoleThickness(currWidth, newHeight, currDepth) !==
    getPoleThickness(currWidth, currHeight, currDepth)
  ) {
    for (let i = 2; i < 5; i++) {
      for (let j = closetPlanks[i].length - 1; j >= 0; j--) {
        scene.remove(closetPlanks[i][j]);
        closetPlanks[i].splice(j, 1);
      }
    }
    for(let i = closetPlanks[6].length-1; i >= 0; i--){
      scene.remove(closetPlanks[6][i])
      closetPlanks[6].splice(i, 1);
    }
    makeSkeletonCloset(
      scene,
      currWidth,
      newHeight,
      currLowerClosetHeight,
      currDepth,
      currWaterproofPlateHeight,
      closetPlanks
    );
    makeUpperSkeletonCloset(
      scene, currWidth, newHeight, currUpperClosetHeight, currDepth, closetPlanks
    )
    if (betonPlexVisible) {
      makeBetonPlex(scene, closetPlanks);
    }
    if (rearWallVisible) {
      makeRearWall(scene, closetPlanks);
    }
    return;
  }
  for (let i = 2; i < 5; i++) {
    for (let j = closetPlanks[i].length - 1; j >= 0; j--) {
      closetPlanks[i][j].position.y -= (newHeight - currHeight) / scale / 2;
    }
  }
  for(let i = 0; i < closetPlanks[6].length; i++){
    closetPlanks[6][i].position.y += (newHeight - currHeight) / scale / 2;
  }

  currHeight = newHeight;
  if (betonPlexVisible) {
    makeBetonPlex(scene, closetPlanks);
  }
  if (rearWallVisible) {
    makeRearWall(scene, closetPlanks);
  }
  makeElectraRoom(scene, currElectraSide, closetPlanks);
}

export function changeSkeletonLowerClosetHeight(
  scene: THREE.Scene,
  newClosetHeight: number,
  closetPlanks: THREE.Mesh[][]
) {
  if (newClosetHeight === currLowerClosetHeight) {
    return;
  }

  let amountComp = getAmountSkeletonCompartiments(currWidth);
  //horizontal planks
  closetPlanks[2][2].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale;
  closetPlanks[2][3].position.y -=
    (newClosetHeight - currLowerClosetHeight) / scale;
  for (let i = 0; i < amountComp + 1; i++) {
    //vertical planks
    closetPlanks[3][i * 4].geometry.scale(
      1,
      (newClosetHeight / scale - legsHeight / scale) /
        (currLowerClosetHeight / scale - legsHeight / scale),
      1
    );
    closetPlanks[3][i * 4 + 1].geometry.scale(
      1,
      (newClosetHeight / scale - legsHeight / scale) /
        (currLowerClosetHeight / scale - legsHeight / scale),
      1
    );
    closetPlanks[3][i * 4].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale / 2;
    closetPlanks[3][i * 4 + 1].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale / 2;

    //lower transverse pole
    closetPlanks[3][i * 4 + 3].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale;

    //legs
    closetPlanks[4][i * 2].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale;
    closetPlanks[4][i * 2 + 1].position.y -=
      (newClosetHeight - currLowerClosetHeight) / scale;
  }

  currLowerClosetHeight = newClosetHeight;
  if (betonPlexVisible) {
    makeBetonPlex(scene, closetPlanks);
  }
  if (rearWallVisible) {
    makeRearWall(scene, closetPlanks);
  }
  makeElectraRoom(scene, currElectraSide, closetPlanks);
}

export function changeSkeletonDepth(
  scene: THREE.Scene,
  newDepth: number,
  closetPlanks: THREE.Mesh[][]
) {
  if (currDepth === newDepth) {
    return;
  }

  if (
    getPoleThickness(currWidth, currHeight, newDepth) !==
    getPoleThickness(currWidth, currHeight, currDepth)
  ) {
    for (let i = 2; i < 5; i++) {
      for (let j = closetPlanks[i].length - 1; j >= 0; j--) {
        scene.remove(closetPlanks[i][j]);
        closetPlanks[i].splice(j, 1);
      }
    }
    for(let i = closetPlanks[6].length-1; i >= 0; i--){
      scene.remove(closetPlanks[6][i])
      closetPlanks[6].splice(i, 1);
    }
    makeSkeletonCloset(
      scene,
      currWidth,
      currHeight,
      currLowerClosetHeight,
      newDepth,
      currWaterproofPlateHeight,
      closetPlanks
    );
    makeUpperSkeletonCloset(
      scene, currWidth, currHeight, currUpperClosetHeight, newDepth, closetPlanks
    )
    if (betonPlexVisible) {
      makeBetonPlex(scene, closetPlanks);
    }
    if (rearWallVisible) {
      makeRearWall(scene, closetPlanks);
    }
    return;
  }

  closetPlanks[2][0].position.z += (newDepth - currDepth) / scale / 2;
  closetPlanks[2][1].position.z -= (newDepth - currDepth) / scale / 2;
  closetPlanks[2][2].position.z += (newDepth - currDepth) / scale / 2;
  closetPlanks[2][3].position.z -= (newDepth - currDepth) / scale / 2;

  closetPlanks[6][0].position.z += (newDepth - currDepth) / scale / 2;
  closetPlanks[6][1].position.z -= (newDepth - currDepth) / scale / 2;
  closetPlanks[6][2].position.z += (newDepth - currDepth) / scale / 2;
  closetPlanks[6][3].position.z -= (newDepth - currDepth) / scale / 2;

  let amountComp = getAmountSkeletonCompartiments(currWidth);
  for (let i = 0; i < amountComp + 1; i++) {
    //lower
    //vertical planks
    closetPlanks[3][i * 4].position.z += (newDepth - currDepth) / scale / 2;
    closetPlanks[3][i * 4 + 1].position.z -= (newDepth - currDepth) / scale / 2;

    //transverse poles
    closetPlanks[3][i * 4 + 2].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[3][i * 4 + 3].geometry.scale(1, 1, newDepth / currDepth);
    
    //upper
    //vertical planks
    closetPlanks[6][(i+1) * 4].position.z += (newDepth - currDepth) / scale / 2;
    closetPlanks[6][(i+1) * 4 + 1].position.z -= (newDepth - currDepth) / scale / 2;

    //transverse poles
    closetPlanks[6][(i+1) * 4 + 2].geometry.scale(1, 1, newDepth / currDepth);
    closetPlanks[6][(i+1) * 4 + 3].geometry.scale(1, 1, newDepth / currDepth);

    //legs
    closetPlanks[4][i * 2].position.z += (newDepth - currDepth) / scale / 2;
    closetPlanks[4][i * 2 + 1].position.z -= (newDepth - currDepth) / scale / 2;
  }

  currDepth = newDepth;
  if (betonPlexVisible) {
    makeBetonPlex(scene, closetPlanks);
  }
  if (rearWallVisible) {
    makeRearWall(scene, closetPlanks);
  }
  makeElectraRoom(scene, currElectraSide, closetPlanks);
}

export function getSkeletonFilterHeight(): number {
  return (
    -currLowerClosetHeight +
    getPoleThickness(currWidth, currHeight, currDepth) +
    bottomHeightDiff +
    betonplexThickness
  );
}

function makeBetonPlex(scene: THREE.Scene, closetPlanks: THREE.Mesh[][]) {
  if (closetPlanks.length > 2 && closetPlanks[2].length > 4) {
    scene.remove(closetPlanks[2][4]);
  }
  closetPlanks[2][4] = makePlank(
    scene,
    0,
    (-currHeight / 2 -
      currLowerClosetHeight +
      getPoleThickness(currWidth, currHeight, currDepth) +
      bottomHeightDiff +
      betonplexThickness / 2) /
      scale  - currWaterproofPlateHeight/scale,
    0,
    currWidth / scale - 0.001,
    betonplexThickness / scale,
    currDepth / scale - 0.001,
    0x1a1a1a
  );
}

export function changeSkeletonBetonPlex(
  scene: THREE.Scene,
  newBetonPlexVisible: boolean,
  closetPlanks: THREE.Mesh[][]
) {
  betonPlexVisible = newBetonPlexVisible;
  if (betonPlexVisible) {
    makeBetonPlex(scene, closetPlanks);
  } else {
    scene.remove(closetPlanks[2][4]);
  }
}

function makeRearWall(scene: THREE.Scene, closetPlanks: THREE.Mesh[][]) {
  if (closetPlanks.length > 2 && closetPlanks[2].length > 5) {
    scene.remove(closetPlanks[2][5]);
  }
  let poleThickness = getPoleThickness(currWidth, currHeight, currDepth);
  closetPlanks[2][5] = makePlank(
    scene,
    0,
    -currHeight / scale / 2 +
      (5 / 4) *
        (-currLowerClosetHeight / scale / 2 +
          bottomHeightDiff / scale / 2 +
          poleThickness / scale / 2) - currWaterproofPlateHeight/scale,
    -currDepth / scale / 2 + poleThickness / scale,
    currWidth / scale,
    ((currLowerClosetHeight / scale -
      bottomHeightDiff / scale -
      poleThickness / scale) *
      3) /
      4,
    betonplexThickness / scale,
    0x1a1a1a
  );
}

export function changeSkeletonRearWall(
  scene: THREE.Scene,
  newRearwallVisible: boolean,
  closetPlanks: THREE.Mesh[][]
) {
  rearWallVisible = newRearwallVisible;
  if (rearWallVisible) {
    makeRearWall(scene, closetPlanks);
  } else {
    scene.remove(closetPlanks[2][5]);
  }
}

export function makeElectraRoom(
  scene: THREE.Scene,
  side: ElectraRuimte,
  closetPlanks: THREE.Mesh[][]
) {
  if (closetPlanks[2].length > 6) {
    scene.remove(closetPlanks[2][6]);
    scene.remove(closetPlanks[2][7]);
    scene.remove(closetPlanks[2][8]);
  }
  let poleThickness = getPoleThickness(currWidth, currHeight, currDepth);
  let electraWidth = getElectraWidth(currWidth);
  if (electraWidth === 0) {
    return;
  }

  if (side === ElectraRuimte.left) {
    //seperator plank
    closetPlanks[2][6] = makePlank(
      scene,
      -currWidth / scale / 2 + electraWidth / scale,
      -currHeight / scale / 2 +
        (-currLowerClosetHeight / scale +
          bottomHeightDiff / scale +
          poleThickness / scale +
          legsHeight / scale) /
          2 - currWaterproofPlateHeight/scale,
      0,
      betonplexThickness / scale,
      currLowerClosetHeight / scale -
        bottomHeightDiff / scale -
        poleThickness / scale -
        legsHeight / scale,
      currDepth / scale - 0.001,
      0x1a1a1a
    );
    //electra panel plank
    closetPlanks[2][7] = makePlank(
      scene,
      -currWidth / scale / 2 +
        electraWidth / scale -
        electraPlateWidth / scale / 2,
      -currHeight / scale / 2 +
        (-currLowerClosetHeight / scale +
          bottomHeightDiff / scale +
          poleThickness / scale +
          legsHeight / scale) /
          2 - currWaterproofPlateHeight/scale,
      currDepth / scale / 2 -
        betonplexThickness / scale -
        electrabuttonsDepthDiff / scale,
      electraPlateWidth / scale,
      currLowerClosetHeight / scale -
        bottomHeightDiff / scale -
        poleThickness / scale -
        legsHeight / scale,
      betonplexThickness / scale,
      0x000000
    );
    //buttons on electra panel
    closetPlanks[2][8] = makeElectrabuttons(
      scene,
      -currWidth / scale / 2 +
        electraWidth / scale -
        electraPlateWidth / scale / 2,
      -currHeight / scale / 2 +
        (-currLowerClosetHeight / scale +
          bottomHeightDiff / scale +
          poleThickness / scale +
          legsHeight / scale) /
          2 - currWaterproofPlateHeight/scale,
      currDepth / scale / 2 - betonplexThickness / scale-
      electrabuttonsDepthDiff / scale+0.001, 
      closetPlanks
    );
  } else if (side === ElectraRuimte.right) {
    //seperator planks
    closetPlanks[2][6] = makePlank(
      scene,
      currWidth / scale / 2 - electraWidth / scale,
      -currHeight / scale / 2 +
        (-currLowerClosetHeight / scale +
          bottomHeightDiff / scale +
          poleThickness / scale +
          legsHeight / scale) /
          2 - currWaterproofPlateHeight/scale,
      0,
      betonplexThickness / scale,
      currLowerClosetHeight / scale -
        bottomHeightDiff / scale -
        poleThickness / scale -
        legsHeight / scale,
      currDepth / scale - 0.001,
      0x1a1a1a
    );
    //electra panel plank
    closetPlanks[2][7] = makePlank(
      scene,
      currWidth / scale / 2 -
        electraWidth / scale +
        electraPlateWidth / scale / 2,
      -currHeight / scale / 2 +
        (-currLowerClosetHeight / scale +
          bottomHeightDiff / scale +
          poleThickness / scale +
          legsHeight / scale) /
          2 - currWaterproofPlateHeight/scale,
      currDepth / scale / 2 -
        betonplexThickness / scale -
        electrabuttonsDepthDiff / scale,
      electraPlateWidth / scale,
      currLowerClosetHeight / scale -
        bottomHeightDiff / scale -
        poleThickness / scale -
        legsHeight / scale,
      betonplexThickness / scale,
      0x000000
    );
    //buttons on electra panel
    closetPlanks[2][8] = makeElectrabuttons(
      scene,
      currWidth / scale / 2 -
        electraWidth / scale +
        electraPlateWidth / scale / 2,
      -currHeight / scale / 2 +
        (-currLowerClosetHeight / scale +
          bottomHeightDiff / scale +
          poleThickness / scale +
          legsHeight / scale) /
          2 - currWaterproofPlateHeight/scale,
      currDepth / scale / 2 - betonplexThickness / scale-
      electrabuttonsDepthDiff / scale+0.001,
      closetPlanks
    );
  }
  currElectraSide = side;
  changeElectraSwitchVisibility(scene, currElectraSwitchVisible, closetPlanks)
  //scene.remove(closetPlanks[2][7])
}

function getElectraWidth(width: number) {
  for (let i = 0; i < filterWidth["aquariumWidth"].length; i++) {
    if (width < Number(filterWidth["aquariumWidth"][i])) {
      return Number(filterWidth["electrabreedte"][i - 1]);
    }
  }
  return 0;
}

// the picture of the buttons is loaded in here
function makeElectrabuttons(
  scene: THREE.Scene,
  x: number,
  y: number,
  z: number,
  closetPlanks: THREE.Mesh[][]
) {
  if(closetPlanks.length > 2 && closetPlanks[2].length > 8){
    closetPlanks[2][8].position.set(x,y,z)
    return closetPlanks[2][8]
  }
  let geometry = new THREE.BoxGeometry(
    electrabuttonsWidth / scale,
    electrabuttonsHeight / scale,
    0.1
  );
  let cubeMaterials = [
    new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(""),
    }),
    new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(""),
    }),
    new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(""),
    }),
    new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(""),
    }),
    new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(electra),
    }),
    new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(""),
    }),
  ];

  let buttons = new THREE.Mesh(geometry, cubeMaterials);
  buttons.position.set(x, y, z);
  scene.add(buttons);
  return buttons;
}

export function skeletonChangeWaterProofPlateHeight(newWaterProofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
  for(let j = 2; j < 5; j++){
    for(let i = 0; i < closetPlanks[j].length; i++){
        closetPlanks[j][i].position.y += (currWaterproofPlateHeight-newWaterProofPlateHeight)/scale
    }
  }
  
  currWaterproofPlateHeight = newWaterProofPlateHeight
}

export function changeElectraSwitchVisibility(scene: THREE.Scene, electraSwitchVisible: boolean, closetPlanks: THREE.Mesh[][]){
  currElectraSwitchVisible = electraSwitchVisible;
  if(closetPlanks.length > 2 && closetPlanks[2].length > 8){
    if(electraSwitchVisible){
      scene.add(closetPlanks[2][8])
    }else{
      scene.remove(closetPlanks[2][8])
    }
  }
}