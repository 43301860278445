import {
  DeliveryOption,
  DoorType,
  ElectraRuimte,
  ExtraLEDFreshWaterOption,
  ExtraLEDSaltWaterOption,
  FilterLocation,
  FilterOptions,
  FullGlassCoverPanels,
  FurnitureType,
  PvcPipeworkPackage,
  TurtleIsland,
} from "../@types/aquarium";
import {
  CountryOptions,
  ImageOption,
  SelectOption,
  TextOption,
} from "../@types/configurator";
import none from "../images/fullglasscoveroptions/none.png";
import sliding from "../images/fullglasscoveroptions/sliding.png";
import nonsliding from "../images/fullglasscoveroptions/nonsliding.png";
import trutleisland_none from "../images/turtleislandoptions/none.png";
import left from "../images/turtleislandoptions/left.png";
import right from "../images/turtleislandoptions/right.png";
import twosided from "../images/turtleislandoptions/twosided.png";
import biological from "../images/filteroptions/biological.png";
import biologist from "../images/filteroptions/biologist.png";
import sump from "../images/filteroptions/sump.png";
import external from "../images/filteroptions/external.png";
import option_none from "../images/option_none.jpg";
import leftback from "../images/filterlocationoptions/leftback.png";
import leftmid from "../images/filterlocationoptions/leftmid.png";
import midback from "../images/filterlocationoptions/backmid.png";
import midmid from "../images/filterlocationoptions/midmid.png";
import rightback from "../images/filterlocationoptions/rightback.png";
import rightmid from "../images/filterlocationoptions/rightmid.png";
import placeholder from "../images/textures/placeholder.jpg";
import frameOnly from "../images/furnituretypeoptions/frameOnly.png";
import decor from "../images/furnituretypeoptions/decor.png";
import aluminium from "../images/furnituretypeoptions/aluminium.png";
import marine from "../images/furnituretypeoptions/marine.webp";
import arrowleft from "../images/arrow-left.png";
import arrowright from "../images/arrow-right.png";
import slidingdoors from "../images/doortypeoptions/slidingdoors.png";
import pushdoors from "../images/doortypeoptions/pushdoors.png";
import removablepanels from "../images/doortypeoptions/removablepanels.png";
import cichlids from "../images/extraledoptions/cichlids.png";
import companionaquarium from "../images/extraledoptions/companionaquarium.png";
import discusaltumsamazone from "../images/extraledoptions/discusaltumsamazone.png";
import fishsoftlpscoral from "../images/extraledoptions/fishsoftlpscoral.png";
import fishsoftlpsstonecoral from "../images/extraledoptions/fishsoftlpsstonecoral.png";
import manyplants from "../images/extraledoptions/manyplants.png";
import onlyfish from "../images/extraledoptions/onlyfish.png";
import pvcPipeworkPlaceholder from "../images/pvcPipeworkPlaceholder.jpg";

export const fullGlassCoverOptions: Array<ImageOption> = [
  {
    id: FullGlassCoverPanels.noPanels,
    img: none,
    title: "Geen",
  },
  {
    id: FullGlassCoverPanels.nonSliding,
    img: nonsliding,
    title: "Niet schuifbaar",
  },
  {
    id: FullGlassCoverPanels.sliding,
    img: sliding,
    title: "Schuifbaar",
  },
];

export const turtleIslandOptions: Array<ImageOption> = [
  {
    id: TurtleIsland.noIsland,
    img: trutleisland_none,
    title: "Geen",
  },
  {
    id: TurtleIsland.left,
    img: left,
    title: "Links",
  },
  {
    id: TurtleIsland.right,
    img: right,
    title: "Rechts",
  },
  {
    id: TurtleIsland.twosided,
    img: twosided,
    title: "Beide kanten",
  },
];

export const filterOptions: Array<ImageOption> = [
  {
    id: FilterOptions.none,
    img: option_none,
    title: "Geen",
  },
  {
    id: FilterOptions.biological,
    img: biological,
    title: "Biologische",
  },
  {
    id: FilterOptions.bio,
    img: biologist,
    title: "Bioloog",
  },
  {
    id: FilterOptions.external,
    img: external,
    title: "Externe filter",
  },
  {
    id: FilterOptions.sump,
    img: sump,
    title: "SUMP",
  },
];

export const filterLocationOptions: Array<ImageOption> = [
  {
    id: FilterLocation.leftBack,
    img: leftback,
    title: "Links achter",
  },
  {
    id: FilterLocation.leftMid,
    img: leftmid,
    title: "Links midden",
  },
  {
    id: FilterLocation.midBack,
    img: midback,
    title: "Midden achter",
  },
  {
    id: FilterLocation.midMid,
    img: midmid,
    title: "Centraal",
  },
  {
    id: FilterLocation.rightBack,
    img: rightback,
    title: "Rechts achter",
  },
  {
    id: FilterLocation.rightMid,
    img: rightmid,
    title: "Rechts midden",
  },
];
export const furnitureTypeOptions: Array<ImageOption> = [
  {
    id: FurnitureType.none,
    img: option_none,
    title: "Geen meubel",
  },
  {
    id: FurnitureType.frameOnly,
    img: frameOnly,
    title: "Enkel skelet",
  },
  {
    id: FurnitureType.decor,
    img: decor,
    title: "Decor",
  },
  {
    id: FurnitureType.aluminium,
    img: aluminium,
    title: "Aluminium",
  },
  {
    id: FurnitureType.marine,
    img: marine,
    title: "Marine conform",
  },
];
export const electraRuimteOptions: Array<ImageOption> = [
  {
    id: ElectraRuimte.none,
    img: option_none,
    title: "Geen",
  },
  {
    id: ElectraRuimte.left,
    img: arrowleft,
    title: "Links",
  },
  {
    id: ElectraRuimte.right,
    img: arrowright,
    title: "Rechts",
  },
];
export const doorTypeOptions: Array<ImageOption> = [
  {
    id: DoorType.slidingDoors,
    img: slidingdoors,
    title: "Schuifdeuren",
  },
  {
    id: DoorType.pushDoors,
    img: pushdoors,
    title: "Pushdeuren",
  },
  {
    id: DoorType.removablePanels,
    img: removablepanels,
    title: "Afneembare panelen",
  },
];

export const extraLEDFreshWaterOptions: Array<ImageOption> = [
  {
    id: ExtraLEDFreshWaterOption.none,
    img: option_none,
    title: "Geen",
  },
  {
    id: ExtraLEDFreshWaterOption.companionAquarium,
    img: companionaquarium,
    title: "Gezeldschaps verlichting",
  },
  {
    id: ExtraLEDFreshWaterOption.manyPlants,
    img: manyplants,
    title: "Vis met veel planten",
  },
  {
    id: ExtraLEDFreshWaterOption.cichlids,
    img: cichlids,
    title: "Cichliden",
  },
  {
    id: ExtraLEDFreshWaterOption.discusAltumsAmazone,
    img: discusaltumsamazone,
    title: "Discus / altums amazone",
  },
];

export const extraLEDSaltWaterOptions: Array<ImageOption> = [
  {
    id: ExtraLEDSaltWaterOption.none,
    img: option_none,
    title: "Geen",
  },
  {
    id: ExtraLEDSaltWaterOption.onlyFish,
    img: onlyfish,
    title: "Enkel vissen",
  },
  {
    id: ExtraLEDSaltWaterOption.fishSoftLpsCoral,
    img: fishsoftlpscoral,
    title: "Vis, soft en lps koraal",
  },
  {
    id: ExtraLEDSaltWaterOption.fishSoftLpsStoneCoral,
    img: fishsoftlpsstonecoral,
    title: "Vis, soft en lps steenkoralen",
  },
];
export const extraPvcPipeworkPackageOptions: Array<ImageOption> = [
  {
    id: PvcPipeworkPackage.diy,
    img: option_none,
    title: "ik maak dit zelf",
  },
  {
    id: PvcPipeworkPackage.byAqualife,
    img: pvcPipeworkPlaceholder,
    title: "door Aqualife laten maken",
  },
];

export const filterLocationPlaceholder: ImageOption = {
  id: FilterLocation.placeholder,
  title: "Selecteer locatie",
  img: placeholder,
};

export const deliveryOptions: TextOption[] = [
  {
    id: DeliveryOption.pickup,
    title: "Zelf afhalen",
  },
  {
    id: DeliveryOption.onlyDeliver,
    title: "Enkel leveren door Aqualife",
  },
  {
    id: DeliveryOption.deliverHalfInstallation,
    title: "Leveren en halve installatie door Aqualife",
  },
  {
    id: DeliveryOption.deliverCompleteInstallation,
    title: "Volledige installatie door Aqualife",
  },
];

export const countryOptions: SelectOption[] = [
  { value: CountryOptions.belgium, title: "België" },
  { value: CountryOptions.netherlands, title: "Nederland" },
  { value: CountryOptions.germany, title: "Duitsland" },
  { value: CountryOptions.france, title: "Frankrijk" },
  { value: CountryOptions.luxembourg, title: "Luxemburg" },
  { value: CountryOptions.bulgaria, title: "Bulgarije" },
  { value: CountryOptions.czechia, title: "Tsjechië" },
  { value: CountryOptions.denmark, title: "Denemarken" },
  { value: CountryOptions.estonia, title: "Estland" },
  { value: CountryOptions.ireland, title: "Ierland" },
  { value: CountryOptions.greece, title: "Griekenland" },
  { value: CountryOptions.spain, title: "Spanje" },
  { value: CountryOptions.croatia, title: "Kroatië" },
  { value: CountryOptions.italy, title: "Italië" },
  { value: CountryOptions.cyprus, title: "Cyprus" },
  { value: CountryOptions.latvia, title: "Letland" },
  { value: CountryOptions.lithuania, title: "Litouwen" },
  { value: CountryOptions.hungary, title: "Hongarije" },
  { value: CountryOptions.malta, title: "Malta" },
  { value: CountryOptions.austria, title: "Oostenrijk" },
  { value: CountryOptions.poland, title: "Polen" },
  { value: CountryOptions.portugal, title: "Portugal" },
  { value: CountryOptions.romania, title: "Roemenië" },
  { value: CountryOptions.slovenia, title: "Slovenië" },
  { value: CountryOptions.slovakia, title: "Slowakije" },
  { value: CountryOptions.finland, title: "Finland" },
  { value: CountryOptions.sweden, title: "Zweden" },
  { value: CountryOptions.norway, title: "Noorwegen" },
];
