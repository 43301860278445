import { Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { Steps } from "../../../@types/configurator";
import ConfiguratorStep from "../../templates/ConfiguratorStep";
import StepItem from "../../templates/StepItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AquariumType, WaterType } from "../../../@types/aquarium";
import {
  setTechnicsAutoRefillSystem,
  setTechnicsBoosterPump,
  setTechnicsCirculationPump,
  setTechnicsCo2System,
  setTechnicsExternalFilter,
  setTechnicsFilter,
  setTechnicsHeating,
  setTechnicsInterior,
  setTechnicsLed,
  setTechnicsProteinSkimmer,
} from "../../../redux/aquariumSlice";
import { testProductData } from "../../../data/dummyData";
import WebshopItem from "../../molecules/WebshopItem";
import { ProductCategory, ProductData } from "../../../@types/product";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination, A11y } from "swiper/modules";
import { calcAquariumVolume } from "../../../utils/aquarium";

type Props = {
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function TechnicsStep({ setOpenStep }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const isMobile = window.innerWidth < 1024;
  const aquariumVolume = calcAquariumVolume(aquarium.aquariumDimensions);
  const dispatch = useDispatch();

  const [boosterPump, setBoosterPump] = useState<ProductData[]>(
    aquarium.technics.boosterPump
  );
  const [ledOption, setLedOption] = useState<ProductData[]>(
    aquarium.technics.led
  );
  const [heating, setHeating] = useState<ProductData[]>(
    aquarium.technics.heating
  );
  const [filter, setFilter] = useState<ProductData[]>(aquarium.technics.filter);
  const [circulationPump, setCirculationPump] = useState<ProductData[]>(
    aquarium.technics.circulationPump
  );
  const [proteinSkimmer, setProteinSkimmer] = useState<ProductData[]>(
    aquarium.technics.proteinSkimmer
  );
  const [autoRefillSystem, setAutoRefillSystem] = useState<ProductData[]>(
    aquarium.technics.automaticRefillSystem
  );
  const [externalFilter, setExternalFilter] = useState<ProductData[]>(
    aquarium.technics.externalExteriorFilter
  );
  const [co2System, setCo2System] = useState<ProductData[]>(
    aquarium.technics.co2System
  );
  const [interior, setInterior] = useState<ProductData[]>(
    aquarium.technics.interior
  );

  useEffect(() => {
    setBoosterPump(aquarium.technics.boosterPump);
    setLedOption(aquarium.technics.led);
    setHeating(aquarium.technics.heating);
    setFilter(aquarium.technics.filter);
    setCirculationPump(aquarium.technics.circulationPump);
    setProteinSkimmer(aquarium.technics.proteinSkimmer);
    setAutoRefillSystem(aquarium.technics.automaticRefillSystem);
    setExternalFilter(externalFilter);
    setCo2System(aquarium.technics.co2System);
    setInterior(aquarium.technics.interior);
  }, [aquarium]);

  useEffect(() => {
    dispatch(setTechnicsBoosterPump(boosterPump));
  }, [boosterPump]);
  useEffect(() => {
    dispatch(setTechnicsLed(ledOption));
  }, [ledOption]);
  useEffect(() => {
    dispatch(setTechnicsHeating(heating));
  }, [heating]);
  useEffect(() => {
    dispatch(setTechnicsFilter(filter));
  }, [filter]);
  useEffect(() => {
    dispatch(setTechnicsCirculationPump(circulationPump));
  }, [circulationPump]);
  useEffect(() => {
    dispatch(setTechnicsProteinSkimmer(proteinSkimmer));
  }, [proteinSkimmer]);
  useEffect(() => {
    dispatch(setTechnicsAutoRefillSystem(autoRefillSystem));
  }, [autoRefillSystem]);
  useEffect(() => {
    dispatch(setTechnicsExternalFilter(externalFilter));
  }, [externalFilter]);
  useEffect(() => {
    dispatch(setTechnicsCo2System(co2System));
  }, [co2System]);
  useEffect(() => {
    dispatch(setTechnicsInterior(interior));
  }, [interior]);

  const filterAquariumDependencies = (item: ProductData) => {
    if (
      (item.dimensionsDependant.minLenght &&
        item.dimensionsDependant.minLenght >
          aquarium.aquariumDimensions.width) ||
      (item.dimensionsDependant.maxLenght &&
        item.dimensionsDependant.maxLenght <
          aquarium.aquariumDimensions.width) ||
      (item.dimensionsDependant.minWidth &&
        item.dimensionsDependant.minWidth >
          aquarium.aquariumDimensions.depth) ||
      (item.dimensionsDependant.maxWidth &&
        item.dimensionsDependant.maxWidth <
          aquarium.aquariumDimensions.depth) ||
      (item.dimensionsDependant.minHeight &&
        item.dimensionsDependant.minHeight >
          aquarium.aquariumDimensions.height) ||
      (item.dimensionsDependant.maxHeight &&
        item.dimensionsDependant.maxHeight <
          aquarium.aquariumDimensions.height) ||
      (item.volumeDependant.minVolume &&
        item.volumeDependant.minVolume > aquariumVolume) ||
      (item.volumeDependant.maxVolume &&
        item.volumeDependant.maxVolume < aquariumVolume)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ConfiguratorStep
      title="Basis technieken"
      step={6}
      stepId={Steps.technics}
      symbol={
        <Plus
          className="configurator-step-header-symbol"
          size={80}
          color="var(--primary-300)"
        />
      }
      setOpenStep={setOpenStep}
    >
      <>
        {aquarium.aquariumType !== AquariumType.turtle && (
          <StepItem
            title="Opvoerpompen"
            extraTitle="(optioneel)"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.boosterPumpsFreshwater
                            : ProductCategory.boosterPumpsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            item={option}
                            currentSelectedItems={boosterPump}
                            setCurrentSelectedItems={setBoosterPump}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.boosterPumpsFreshwater
                            : ProductCategory.boosterPumpsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          item={option}
                          currentSelectedItems={boosterPump}
                          setCurrentSelectedItems={setBoosterPump}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        <StepItem
          title="LED verlichting"
          extraTitle="(optioneel)"
          helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
          errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
          hasIssue={false}
        >
          <>
            <div className="optionlist-container">
              {isMobile ? (
                <Swiper
                  spaceBetween={12}
                  slidesPerView={2}
                  modules={[Pagination, A11y]}
                  pagination={{ clickable: false }}
                >
                  {testProductData
                    .filter((item) =>
                      item.category.includes(
                        aquarium.waterType === WaterType.salty
                          ? ProductCategory.LEDSaltwater
                          : aquarium.aquariumType === AquariumType.turtle
                          ? ProductCategory.LEDTurtles
                          : ProductCategory.LEDFreshwater
                      )
                    )
                    .filter((item) => filterAquariumDependencies(item))
                    .map((option) => (
                      <SwiperSlide>
                        <WebshopItem
                          item={option}
                          currentSelectedItems={ledOption}
                          setCurrentSelectedItems={setLedOption}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                <>
                  {testProductData
                    .filter((item) =>
                      item.category.includes(
                        aquarium.waterType === WaterType.salty
                          ? ProductCategory.LEDSaltwater
                          : aquarium.aquariumType === AquariumType.turtle
                          ? ProductCategory.LEDTurtles
                          : ProductCategory.LEDFreshwater
                      )
                    )
                    .filter((item) => filterAquariumDependencies(item))
                    .map((option) => (
                      <WebshopItem
                        item={option}
                        currentSelectedItems={ledOption}
                        setCurrentSelectedItems={setLedOption}
                      />
                    ))}
                </>
              )}
            </div>
          </>
        </StepItem>
        {aquarium.aquariumType !== AquariumType.turtle && (
          <StepItem
            title="Verwarming"
            extraTitle="(optioneel)"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.heatingFreshwater
                            : ProductCategory.heatingSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            item={option}
                            currentSelectedItems={heating}
                            setCurrentSelectedItems={setHeating}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.heatingFreshwater
                            : ProductCategory.heatingSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          item={option}
                          currentSelectedItems={heating}
                          setCurrentSelectedItems={setHeating}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.aquariumType !== AquariumType.turtle && (
          <StepItem
            title="Filters"
            extraTitle="(optioneel)"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.filtersFreshwater
                            : ProductCategory.filtersSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            item={option}
                            currentSelectedItems={filter}
                            setCurrentSelectedItems={setFilter}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.filtersFreshwater
                            : ProductCategory.filtersSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          item={option}
                          currentSelectedItems={filter}
                          setCurrentSelectedItems={setFilter}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.salty && (
          <StepItem
            title="Stromingspompen"
            extraTitle="(optioneel)"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.circulationPumpsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            item={option}
                            currentSelectedItems={circulationPump}
                            setCurrentSelectedItems={setCirculationPump}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.circulationPumpsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          item={option}
                          currentSelectedItems={circulationPump}
                          setCurrentSelectedItems={setCirculationPump}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.salty && (
          <StepItem
            title="Eiwitafschuimer"
            extraTitle="(optioneel)"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.proteinSkimmersSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            item={option}
                            currentSelectedItems={proteinSkimmer}
                            setCurrentSelectedItems={setProteinSkimmer}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.proteinSkimmersSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          item={option}
                          currentSelectedItems={proteinSkimmer}
                          setCurrentSelectedItems={setProteinSkimmer}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.salty && (
          <StepItem
            title="Automatisch bijvulsysteem"
            extraTitle="(optioneel)"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.automaticRefillSystemsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            item={option}
                            currentSelectedItems={autoRefillSystem}
                            setCurrentSelectedItems={setAutoRefillSystem}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.automaticRefillSystemsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          item={option}
                          currentSelectedItems={autoRefillSystem}
                          setCurrentSelectedItems={setAutoRefillSystem}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.fresh && (
          <StepItem
            title="Externe buitenfilter"
            extraTitle="(optioneel)"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          aquarium.aquariumType === AquariumType.turtle
                            ? ProductCategory.externalExteriorFiltersTurtles
                            : ProductCategory.externalExteriorFiltersFreshwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            item={option}
                            currentSelectedItems={externalFilter}
                            setCurrentSelectedItems={setExternalFilter}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {testProductData
                      .filter((item) =>
                        item.category.includes(
                          aquarium.aquariumType === AquariumType.turtle
                            ? ProductCategory.externalExteriorFiltersTurtles
                            : ProductCategory.externalExteriorFiltersFreshwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          item={option}
                          currentSelectedItems={externalFilter}
                          setCurrentSelectedItems={setExternalFilter}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.fresh &&
          aquarium.aquariumType !== AquariumType.turtle && (
            <StepItem
              title="CO2-systeem"
              extraTitle="(optioneel)"
              helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
              errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
              hasIssue={false}
            >
              <>
                <div className="optionlist-container">
                  {isMobile ? (
                    <Swiper
                      spaceBetween={12}
                      slidesPerView={2}
                      modules={[Pagination, A11y]}
                      pagination={{ clickable: false }}
                    >
                      {testProductData
                        .filter((item) =>
                          item.category.includes(
                            ProductCategory.co2SystemFreshwater
                          )
                        )
                        .filter((item) => filterAquariumDependencies(item))
                        .map((option) => (
                          <SwiperSlide>
                            <WebshopItem
                              item={option}
                              currentSelectedItems={co2System}
                              setCurrentSelectedItems={setCo2System}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  ) : (
                    <>
                      {testProductData
                        .filter((item) =>
                          item.category.includes(
                            ProductCategory.co2SystemFreshwater
                          )
                        )
                        .filter((item) => filterAquariumDependencies(item))
                        .map((option) => (
                          <WebshopItem
                            item={option}
                            currentSelectedItems={co2System}
                            setCurrentSelectedItems={setCo2System}
                          />
                        ))}
                    </>
                  )}
                </div>
              </>
            </StepItem>
          )}
        <StepItem
          title="Inrichting"
          extraTitle="(optioneel)"
          helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
          errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
          hasIssue={false}
        >
          <>
            <div className="optionlist-container">
              {isMobile ? (
                <Swiper
                  spaceBetween={12}
                  slidesPerView={2}
                  modules={[Pagination, A11y]}
                  pagination={{ clickable: false }}
                >
                  {testProductData
                    .filter((item) =>
                      item.category.includes(
                        aquarium.waterType === WaterType.salty
                          ? ProductCategory.interiorSaltwater
                          : aquarium.aquariumType === AquariumType.turtle
                          ? ProductCategory.interiorTurtles
                          : ProductCategory.interiorFreshwater
                      )
                    )
                    .filter((item) => filterAquariumDependencies(item))
                    .map((option) => (
                      <SwiperSlide>
                        <WebshopItem
                          item={option}
                          currentSelectedItems={interior}
                          setCurrentSelectedItems={setInterior}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                <>
                  {testProductData
                    .filter((item) =>
                      item.category.includes(
                        aquarium.waterType === WaterType.salty
                          ? ProductCategory.interiorSaltwater
                          : aquarium.aquariumType === AquariumType.turtle
                          ? ProductCategory.interiorTurtles
                          : ProductCategory.interiorFreshwater
                      )
                    )
                    .filter((item) => filterAquariumDependencies(item))
                    .map((option) => (
                      <WebshopItem
                        item={option}
                        currentSelectedItems={interior}
                        setCurrentSelectedItems={setInterior}
                      />
                    ))}
                </>
              )}
            </div>
          </>
        </StepItem>
      </>
    </ConfiguratorStep>
  );
}

export default TechnicsStep;
