import React from "react";
import { SubTitle, Text } from "../atoms/StyledComponents";
import "../../styles/footer.css";

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-content-container">
        <div>
          <SubTitle padding="var(--spacing-sm)">Aqualife N.V.</SubTitle>
          <Text>
            We zijn sinds 1991 gespecialiseerd in het bouwen van Exclusieve en
            duurzame maatwerk aquaria, meubels en filtersystemen. Al onze
            troeven kan u hier terugvinden. Wist u dat we nu ook over een online
            prijs configuratie beschikken zodat u zelf de prijs kan berekenen?
            We willen je zeker eens uitnodigen in onze showroom om alle wensen
            te bespreken! U bent op afspraak van harte welkom. Bekijk alvast
            onze nieuwe bedrijfsfilm. Opgelet: in onze winkel verkopen we geen
            levende have.
          </Text>
        </div>
        <div>
          <SubTitle padding="var(--spacing-sm)">Openingsuren</SubTitle>

          <Text>
            MAANDAG: 08.00h - 17.00h <br /> DINSDAG: 08.00h - 17.00h WOENSDAG:
            08.00h - 17.00h DONDERDAG: 08.00h - 17.00h VRIJDAG: 08.00h - 16.00h
            ZATERDAG: ENKEL OP AFSPRAAK Zondag: GESLOTEN
          </Text>
        </div>
        <div>
          <SubTitle padding="var(--spacing-sm)">Contact</SubTitle>

          <Text>
            EUROPALAAN 9 3900 OVERPELT BELGIUM Tel. 011/64.79.86 Fax.
            011/66.38.15 E-MAIL: raf@aqualife-nv.be
          </Text>
        </div>
      </div>
      <Text style={{ textAlign: "center" }}>
        &copy; 2024{" "}
        <a
          style={{ textDecoration: "none", color: "var(--neutral-100)" }}
          href="https://www.dvmedia.be"
          target="_blank"
          rel="noreferrer"
        >
          Dries Vanmierlo
        </a>{" "}
        & Daan Ezendam
      </Text>
    </footer>
  );
}

export default Footer;
