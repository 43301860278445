import * as THREE from "three";
import rimlessThickness from "./jsonFiles/rimless.json";
import lightcoverThickness from "./jsonFiles/lightcover.json";
import fullglassThickness from "./jsonFiles/fullglass.json";
import turtleThickness from "./jsonFiles/turtle.json";
import {
  changeSiliconDepth,
  changeSiliconGlassWidth,
  changeSiliconHeight,
  changeSiliconWidth,
  drawSiliconStrips,
} from "./siliconStrips";
import { colorPlankSide, makeGeometryMaterials, makePlank } from "./helper";
import {
  changeTurtleCoverpanels,
  changeTurtleDepth,
  changeTurtleGlassWidth,
  changeTurtleHeight,
  changeTurtleIsland,
  changeTurtleLightCover,
  changeTurtleWidth,
  makeTurtleAquarium,
} from "./turtle";
import {
  changeFullGlassDepth,
  changeFullGlassGlassWidth,
  changeFullGlassHeight,
  changeFullGlassWidth,
  lengthstripsHeight,
  makeFullGlassAquarium,
  setGlassPanels,
} from "./fullGlass";
import {
  changeLightCoverDepth,
  changeLightCoverGlassWidth,
  changeLightCoverHeight,
  changeLightCoverPanels,
  changeLightCoverTexture,
  changeLightCoverWidth,
  lightcoverDiff,
  makeLightCoverAquarium,
} from "./lightCover";
import {
  AquariumType,
  BoreHole,
  DoorType,
  ElectraRuimte,
  FilterLocation,
  FilterOptions,
  FullGlassCoverPanels,
  FurnitureType,
  LightboardFinish,
  Side,
  TurtleIsland,
} from "../../@types/aquarium";
import {
  changeFilterDepth,
  changeFilterHeight,
  ChangeFilterHeightDiff,
  changeFilterWaterproofPlateHeight,
  changeFilterWidth,
  MakeBoreHole,
  makeFilter,
  makeFilterMarineConform,
  setElectraruimte,
  setFilterLocation,
  toggleCoverPanels,
  toggleOsmoseComp,
} from "./filteroptions";
import {
  changeBetonPlex,
  changeClosetTexture,
  changeDoorType,
  changeElectraRuimte,
  changeFurnitureElectraSwitchVisibility,
  changeRearWall,
  changeWaterProofPlateHeight,
  getFilterHeight,
  getLowerFunritureHeight,
  makeFilterCompartiment,
  rotateClosetDoors,
  setAquariumSeeThrough,
  setClosetDepth,
  setClosetHeight,
  setClosetType,
  setClosetWidth,
  setLowerClosetHeight,
  setUpperClosetHeight,
} from "./closetOptions";
import { DecorTexture } from "../../@types/textures";
import antraciet from "../../images/textures/Antraciet.png";
import concrete from "../../images/textures/WoodTexture.jpg";
// import { testFunction } from "./priceCalculator";

//https://www.youtube.com/watch?v=lGokKxJ8D2c

const scale = 25;
const normalOpacity = 0.4;
const optiwhiteOpacity = 0.2;
const filterInAquariumHeightDiff = 5;
const closetThickness = 1.8;
const defaultBottomClosetHeight = 85;
const grindListHeight = 4;
export const betonplexlimit = 400;

let glassWidth = 0.8 / scale;
let Scene: THREE.Scene;

let front: THREE.Mesh;
let back: THREE.Mesh;
let left: THREE.Mesh;
let right: THREE.Mesh;
let lower: THREE.Mesh;
let betonPlex: THREE.Mesh;

let currAquariumType: AquariumType = AquariumType.rimless;
let aquariumTypePlanks: THREE.Mesh[] = [];
let grindListPlanks: THREE.Mesh[] = [];
let currGrindListVisible = false;
let grindListTexture: THREE.Texture[] = [];
let currTexture: DecorTexture;

let currWidth: number;
let currHeight: number;
let currDepth: number;
let bottomClosetHeight = 0;
let upperClosetHeight = 0;

let thicknessJSON: any;
getGlassThicknessJSON("rimless");

let sidePaintColor: THREE.ColorRepresentation = 0x000000; //the color that the sides that the user clicked should have, belongs to "ruiten verver"
let paintedSides: Side[] = []; // the sides that are painted

let optiWhiteSides: Side[] = [];
let sideOpacities: number[] = [
  normalOpacity,
  normalOpacity,
  normalOpacity,
  normalOpacity,
]; // opacities of the sides, [front, back, left, right]

let filterType: FilterOptions;
let currFilterLocation: FilterLocation;
let filterInAquariumHeight: number;
let currFurniturType = FurnitureType.none;
let currBetonplexVisible = true;
const thickBetonplex = 1.8;
const thinBetonplex = 0.9;

//draws the 5 main panels: front, back, left, right glasspanel and the lower solid panel
export function drawTankGlass(
  scene: THREE.Scene,
  width: number,
  height: number,
  depth: number
) {
  glassWidth = getGlassWidth(width, height, depth);
  Scene = scene;
  front = makePlank(
    scene,
    0,
    0,
    depth / (2 * scale) - glassWidth / 2,
    width / scale,
    height / scale - glassWidth,
    glassWidth,
    0xdefcff,
    sideOpacities[0]
  );
  back = makePlank(
    scene,
    0,
    0,
    -depth / (2 * scale) + glassWidth / 2,
    width / scale,
    height / scale - glassWidth,
    glassWidth,
    0xdefcff,
    sideOpacities[1]
  );
  left = makePlank(
    scene,
    -width / (2 * scale) + glassWidth / 2,
    0,
    0,
    glassWidth,
    height / scale - glassWidth,
    depth / scale - glassWidth * 2,
    0xdefcff,
    sideOpacities[2]
  );
  right = makePlank(
    scene,
    width / (2 * scale) - glassWidth / 2,
    0,
    0,
    glassWidth,
    height / scale - glassWidth,
    depth / scale - glassWidth * 2,
    0xdefcff,
    sideOpacities[3]
  );
  lower = makePlank(
    scene,
    0,
    -height / (2 * scale),
    0,
    width / scale,
    glassWidth,
    depth / scale,
    0xaaaaaa
  );

  let betonplexThickness = 0;
  if ((width * height * depth) / 1000 > betonplexlimit) {
    betonplexThickness = thickBetonplex;
  } else {
    betonplexThickness = thinBetonplex;
  }

  for (let i = 0; i < grindListPlanks.length; i++) {
    scene.remove(grindListPlanks[i]);
  }
  //front grindlist
  grindListPlanks[0] = makePlank(
    scene,
    0,
    -height / scale / 2 -
      glassWidth -
      betonplexThickness / scale +
      grindListHeight / scale / 2,
    depth / scale / 2 + 0.01,
    width / scale + 0.02,
    grindListHeight / scale,
    0.01,
    0xeeeeee
  );
  // back
  grindListPlanks[1] = makePlank(
    scene,
    0,
    -height / scale / 2 -
      glassWidth -
      betonplexThickness / scale +
      grindListHeight / scale / 2,
    -depth / scale / 2 - 0.01,
    width / scale + 0.02,
    grindListHeight / scale,
    0.01,
    0xeeeeee
  );
  // left
  grindListPlanks[2] = makePlank(
    scene,
    -width / scale / 2 - 0.01,
    -height / scale / 2 -
      glassWidth -
      betonplexThickness / scale +
      grindListHeight / scale / 2,
    0,
    0.01,
    grindListHeight / scale,
    depth / scale + 0.02,
    0xeeeeee
  );
  // right
  grindListPlanks[3] = makePlank(
    scene,
    width / scale / 2 + 0.01,
    -height / scale / 2 -
      glassWidth -
      betonplexThickness / scale +
      grindListHeight / scale / 2,
    0,
    0.01,
    grindListHeight / scale,
    depth / scale + 0.02,
    0xeeeeee
  );
  for (let i = 0; i < grindListPlanks.length; i++) {
    scene.remove(grindListPlanks[i]);
  }
  betonPlex = makePlank(
    scene,
    0,
    -height / scale / 2 - glassWidth - betonplexThickness / scale / 2,
    0,
    width / scale,
    betonplexThickness / scale,
    depth / scale,
    0x1a1a1a
  );
  if (!currBetonplexVisible) {
    scene.remove(betonPlex);
  } else {
    changeWaterProofPlateHeight(betonplexThickness);
  }

  drawSiliconStrips(scene, width, height, depth, glassWidth);
  currHeight = height;
  currDepth = depth;
  currWidth = width;
  filterInAquariumHeight = height;
  changeGrindListTexture("");
}

//if height of aquarium changes
export function changeTankHeight(height: number) {
  //scale(1,1/(currHeight/25-glassWidth),1) because the glass panels are smaller to fit onto bottom panel
  //scale(1,height/25-glassWidth,1)
  //-->scale(1,(height/25-glassWidth)/(currHeight/25-glassWidth),1)
  let newGlassWidth = getGlassWidth(currWidth, height, currDepth); //check if the height is permitted
  if (newGlassWidth === 0) {
    //if height isn't permitted return false
    return false;
  }
  filterInAquariumHeight += height - currHeight;
  front.geometry.scale(
    1,
    (height / scale - glassWidth) / (currHeight / scale - glassWidth),
    1
  );
  back.geometry.scale(
    1,
    (height / scale - glassWidth) / (currHeight / scale - glassWidth),
    1
  );
  left.geometry.scale(
    1,
    (height / scale - glassWidth) / (currHeight / scale - glassWidth),
    1
  );
  right.geometry.scale(
    1,
    (height / scale - glassWidth) / (currHeight / scale - glassWidth),
    1
  );
  lower.position.y = -height / (2 * scale);

  for (let i = 0; i < grindListPlanks.length; i++) {
    grindListPlanks[i].position.y -= (height - currHeight) / scale / 2;
  }

  changeSiliconHeight(height);
  changeAquariumTypeHeight(height);
  changeFilterHeight(Scene, height, filterInAquariumHeight);
  ChangeFilterHeightDiff(Scene, getFilterHeight(), bottomClosetHeight);
  setClosetHeight(Scene, height);

  //if al heights are changed, change glasswidth
  if (newGlassWidth !== glassWidth) {
    setGlassWidth(getGlassWidth(currWidth, height, currDepth));
  }

  let betonPlexThickness = 0;
  if (
    (currWidth * height * currDepth) / 1000 > betonplexlimit !==
      (currWidth * currHeight * currDepth) / 1000 > betonplexlimit &&
    currBetonplexVisible
  ) {
    if ((currWidth * height * currDepth) / 1000 > betonplexlimit) {
      betonPlex.geometry.scale(1, thickBetonplex / thinBetonplex, 1);
      betonPlexThickness = thickBetonplex;
    } else {
      betonPlex.geometry.scale(1, thinBetonplex / thickBetonplex, 1);
      betonPlexThickness = thinBetonplex;
    }
    for (let i = 0; i < grindListPlanks.length; i++) {
      grindListPlanks[i].position.y =
        -height / scale / 2 -
        glassWidth -
        betonPlexThickness / scale +
        grindListHeight / scale / 2;
    }
    changeWaterProofPlateHeight(betonPlexThickness);
  }
  betonPlex.position.set(
    0,
    -height / 2 / scale - newGlassWidth - betonPlexThickness / scale / 2,
    0
  );

  currHeight = height;
  return true;
}

//if width of aquarium changes
export function changeTankWidth(width: number) {
  let newGlassWidth = getGlassWidth(width, currHeight, currDepth); //check if the width is permitted
  if (newGlassWidth === 0) {
    //if width isn't permitted return false
    return false;
  }
  front.geometry.scale(width / currWidth, 1, 1);
  back.geometry.scale(width / currWidth, 1, 1);
  lower.geometry.scale(width / currWidth, 1, 1);
  left.position.x = -width / (2 * scale) + glassWidth / 2;
  right.position.x = width / (2 * scale) - glassWidth / 2;

  grindListPlanks[0].geometry.scale(
    (width / scale + 0.02) / (currWidth / scale + 0.02),
    1,
    1
  );
  grindListPlanks[1].geometry.scale(
    (width / scale + 0.02) / (currWidth / scale + 0.02),
    1,
    1
  );
  grindListPlanks[2].position.x -= (width - currWidth) / scale / 2;
  grindListPlanks[3].position.x += (width - currWidth) / scale / 2;

  changeSiliconWidth(width);
  changeAquariumTypeWidth(width);
  changeFilterWidth(Scene, width);
  setClosetWidth(Scene, width);

  //if al widths are changed, change glasswidth
  if (newGlassWidth !== glassWidth) {
    setGlassWidth(getGlassWidth(width, currHeight, currDepth));
  }
  let betonPlexThickness = 0;
  if (
    (width * currHeight * currDepth) / 1000 > betonplexlimit !==
      (currWidth * currHeight * currDepth) / 1000 > betonplexlimit &&
    currBetonplexVisible
  ) {
    if ((width * currHeight * currDepth) / 1000 > betonplexlimit) {
      betonPlex.geometry.scale(1, thickBetonplex / thinBetonplex, 1);
      betonPlexThickness = thickBetonplex;
    } else {
      betonPlex.geometry.scale(1, thinBetonplex / thickBetonplex, 1);
      betonPlexThickness = thinBetonplex;
    }
    for (let i = 0; i < grindListPlanks.length; i++) {
      grindListPlanks[i].position.y =
        -currHeight / scale / 2 -
        glassWidth -
        betonPlexThickness / scale +
        grindListHeight / scale / 2;
    }
    changeWaterProofPlateHeight(betonPlexThickness);
  }
  betonPlex.geometry.scale(width / currWidth, 1, 1);
  betonPlex.position.set(
    0,
    -currHeight / 2 / scale - newGlassWidth - betonPlexThickness / scale / 2,
    0
  );
  currWidth = width;
  changeGrindListTexture("");
  return true;
}

//if depth of aquarium changes
export function changeTankDepth(depth: number) {
  //scale(1,1, 1/(currDepth/25-glassWidth*2)) because side panels are smaller than front and back panel
  //scale(1,1, (depth/25-glassWidth*2))
  //--> scale(1,1, (depth/25-glassWidth*2)/(currDepth/25-glassWidth*2))
  let newGlassWidth = getGlassWidth(currWidth, currHeight, currDepth); //check if the depth is permitted
  if (newGlassWidth === 0) {
    //if width isn't permitted return false
    return false;
  }
  left.geometry.scale(
    1,
    1,
    (depth / scale - glassWidth * 2) / (currDepth / scale - glassWidth * 2)
  );
  right.geometry.scale(
    1,
    1,
    (depth / scale - glassWidth * 2) / (currDepth / scale - glassWidth * 2)
  );
  lower.geometry.scale(1, 1, depth / currDepth);
  front.position.z = depth / (2 * scale) - glassWidth / 2;
  back.position.z = -depth / (2 * scale) + glassWidth / 2;

  grindListPlanks[0].position.z += (depth - currDepth) / scale / 2;
  grindListPlanks[1].position.z -= (depth - currDepth) / scale / 2;
  grindListPlanks[2].geometry.scale(
    1,
    1,
    (depth / scale + 0.02) / (currDepth / scale + 0.02)
  );
  grindListPlanks[3].geometry.scale(
    1,
    1,
    (depth / scale + 0.02) / (currDepth / scale + 0.02)
  );

  changeSiliconDepth(depth);
  changeAquariumTypeDepth(depth);
  changeFilterDepth(Scene, depth);
  setClosetDepth(Scene, depth);

  //if al depths are changed, change glasswidth
  if (newGlassWidth !== glassWidth) {
    setGlassWidth(getGlassWidth(currWidth, currHeight, depth));
  }

  let betonPlexThickness = 0;
  if (
    (currWidth * currHeight * depth) / 1000 > betonplexlimit !==
      (currWidth * currHeight * currDepth) / 1000 > betonplexlimit &&
    currBetonplexVisible
  ) {
    if ((currWidth * currHeight * depth) / 1000 > betonplexlimit) {
      betonPlex.geometry.scale(1, thickBetonplex / thinBetonplex, 1);
      betonPlexThickness = thickBetonplex;
    } else {
      betonPlex.geometry.scale(1, thinBetonplex / thickBetonplex, 1);
      betonPlexThickness = thinBetonplex;
    }
    for (let i = 0; i < grindListPlanks.length; i++) {
      grindListPlanks[i].position.y =
        -currHeight / scale / 2 -
        glassWidth -
        betonPlexThickness / scale +
        grindListHeight / scale / 2;
    }
    changeWaterProofPlateHeight(betonPlexThickness);
  }
  betonPlex.geometry.scale(1, 1, depth / currDepth);
  betonPlex.position.set(
    0,
    -currHeight / 2 / scale - newGlassWidth - betonPlexThickness / scale / 2,
    0
  );

  currDepth = depth;
  changeGrindListTexture("");
  return true;
}

//https://aquariumconfigurator.be/NewWebsite/glassThiknesses.php
// change aquariumtype to one of the 4 types
export function changeAquariumType(type: AquariumType) {
  if (currAquariumType === type) {
    return;
  }
  currAquariumType = type;
  switch (type) {
    case AquariumType.rimless: {
      getGlassThicknessJSON("rimless");
      let newGlassWidth = getGlassWidth(currWidth, currHeight, currDepth);
      if (newGlassWidth === 0) {
        return false;
      }
      makeRimlessAquarium(Scene);
      setGlassWidth(newGlassWidth);
      filterInAquariumHeight = currHeight;
      break;
    }
    case AquariumType.fullGlass: {
      getGlassThicknessJSON("fullglass");
      let newGlassWidth = getGlassWidth(currWidth, currHeight, currDepth);
      if (newGlassWidth === 0) {
        return false;
      }
      makeFullGlassAquarium(
        Scene,
        currWidth,
        currHeight,
        currDepth,
        newGlassWidth,
        aquariumTypePlanks
      );
      setGlassWidth(newGlassWidth);
      filterInAquariumHeight =
        currHeight - lengthstripsHeight - filterInAquariumHeightDiff;
      break;
    }
    case AquariumType.lightCover: {
      getGlassThicknessJSON("lightcover");
      let newGlassWidth = getGlassWidth(currWidth, currHeight, currDepth);
      if (newGlassWidth === 0) {
        return false;
      }
      makeLightCoverAquarium(
        Scene,
        currWidth,
        currHeight,
        currDepth,
        newGlassWidth,
        aquariumTypePlanks
      );
      setGlassWidth(newGlassWidth);
      filterInAquariumHeight =
        currHeight - lightcoverDiff - filterInAquariumHeightDiff;
      break;
    }
    case AquariumType.turtle: {
      getGlassThicknessJSON("turtle");
      let newGlassWidth = getGlassWidth(currWidth, currHeight, currDepth);
      if (newGlassWidth === 0) {
        return false;
      }
      makeTurtleAquarium(
        Scene,
        currWidth,
        currHeight,
        currDepth,
        newGlassWidth,
        aquariumTypePlanks
      );
      setGlassWidth(newGlassWidth);
      filterInAquariumHeight = currHeight / 2;
      break;
    }
  }
  changeFilterLocation(currFilterLocation);
  return true;
}

//if type changes, change the JSONfile to the right type
//JSON files should already be loaded in before everything starts because glassthickness is an important thing
function getGlassThicknessJSON(type: string) {
  switch (type) {
    case "rimless": {
      thicknessJSON = rimlessThickness;
      break;
    }
    case "fullglass": {
      thicknessJSON = fullglassThickness;
      break;
    }
    case "lightcover": {
      thicknessJSON = lightcoverThickness;
      break;
    }
    case "turtle": {
      thicknessJSON = turtleThickness;
      break;
    }
  }
  /*var xhr = new XMLHttpRequest();
    xhr.open('POST', 'glassThiknesses.php', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
            var jsonResponse = JSON.parse(xhr.responseText);
            console.log(jsonResponse);
        }
    };
    xhr.send("type="+type);*/
}

function makeRimlessAquarium(scene: THREE.Scene) {
  for (let i = aquariumTypePlanks.length - 1; i >= 0; i--) {
    Scene.remove(aquariumTypePlanks[i]);
    aquariumTypePlanks.splice(i);
  }
}

//change height of the planks that belong to the aquariumtype
function changeAquariumTypeHeight(height: number) {
  switch (currAquariumType) {
    case AquariumType.rimless: {
      break;
    }
    case AquariumType.fullGlass: {
      changeFullGlassHeight(height, aquariumTypePlanks);
      break;
    }
    case AquariumType.lightCover: {
      changeLightCoverHeight(height, aquariumTypePlanks);
      break;
    }
    case AquariumType.turtle: {
      changeTurtleHeight(height, aquariumTypePlanks);
      break;
    }
  }
}

//change depth of the planks that belong to the aquariumtype
function changeAquariumTypeDepth(depth: number) {
  switch (currAquariumType) {
    case AquariumType.rimless: {
      break;
    }
    case AquariumType.fullGlass: {
      changeFullGlassDepth(Scene, depth, aquariumTypePlanks);
      break;
    }
    case AquariumType.lightCover: {
      changeLightCoverDepth(Scene, depth, aquariumTypePlanks);
      break;
    }
    case AquariumType.turtle: {
      changeTurtleDepth(Scene, depth, aquariumTypePlanks);
      break;
    }
  }
}

//change width of the planks that belong to the aquariumtype
function changeAquariumTypeWidth(width: number) {
  switch (currAquariumType) {
    case AquariumType.rimless: {
      break;
    }
    case AquariumType.fullGlass: {
      changeFullGlassWidth(Scene, width, aquariumTypePlanks);
      break;
    }
    case AquariumType.lightCover: {
      changeLightCoverWidth(Scene, width, aquariumTypePlanks);
      break;
    }
    case AquariumType.turtle: {
      changeTurtleWidth(Scene, width, aquariumTypePlanks);
      break;
    }
  }
}

//change width of the planks that belong to the aquariumtype
function changeAquariumTypeGlassWidth(glassWidth: number) {
  switch (currAquariumType) {
    case AquariumType.rimless: {
      break;
    }
    case AquariumType.fullGlass: {
      changeFullGlassGlassWidth(Scene, glassWidth, aquariumTypePlanks);
      break;
    }
    case AquariumType.lightCover: {
      changeLightCoverGlassWidth(glassWidth, aquariumTypePlanks);
      break;
    }
    case AquariumType.turtle: {
      changeTurtleGlassWidth(glassWidth, aquariumTypePlanks);
      break;
    }
  }
}

// searches for glasswidth in the JSON file, the vertical values and horizontal values should be arranged from small to big
export function getGlassWidth(width: number, height: number, depth: number) {
  //searches for the index of the height
  let horizontalIndex = -1;
  for (let i = 0; i < thicknessJSON["horizontalValues"].length; i++) {
    if (height < Number(thicknessJSON["horizontalValues"][i])) {
      horizontalIndex = i - 1;
      break;
    }
  }
  //if the height is bigger than the biggest value than it should be set to that index
  if (horizontalIndex === -1) {
    horizontalIndex = thicknessJSON["horizontalValues"].length - 1;
  }

  //searches for the index of the width or depth
  let verticalIndex = -1;
  for (let i = 0; i < thicknessJSON["verticalValues"].length; i++) {
    if (
      width < Number(thicknessJSON["verticalValues"][i]) &&
      depth < Number(thicknessJSON["verticalValues"][i])
    ) {
      verticalIndex = i - 1;
      break;
    }
  }
  //if the width or depth is bigger than the biggest value than it should be set to that index
  if (verticalIndex === -1) {
    verticalIndex = thicknessJSON["horizontalValues"].length - 1;
  }

  // the thicknessvalues consist of the vertical index, the horizontal index and the thickness of the glass in mm, search for the right indices and return the thickness
  for (let i = 0; i < thicknessJSON["thicknessValues"].length; i++) {
    if (
      Number(thicknessJSON["thicknessValues"][i][0]) === horizontalIndex &&
      Number(thicknessJSON["thicknessValues"][i][1]) === verticalIndex
    ) {
      return Number(thicknessJSON["thicknessValues"][i][2]) / 10 / scale;
    }
  }
  //if nothing was found, which should be impossible, nothing has to be changed because then the program will be broken somewhere else
  return glassWidth;
}

// set the glasswidth and put all the glass in the correct place again, also make sure the aquariumtype changes as well
function setGlassWidth(newGlassWidth: number) {
  front.geometry.scale(
    1,
    (currHeight / scale - newGlassWidth) / (currHeight / scale - glassWidth),
    newGlassWidth / glassWidth
  );
  back.geometry.scale(
    1,
    (currHeight / scale - newGlassWidth) / (currHeight / scale - glassWidth),
    newGlassWidth / glassWidth
  );
  front.position.z = currDepth / (2 * scale) - newGlassWidth / 2;
  back.position.z = -currDepth / (2 * scale) + newGlassWidth / 2;
  left.geometry.scale(
    newGlassWidth / glassWidth,
    (currHeight / scale - newGlassWidth) / (currHeight / scale - glassWidth),
    (currDepth / scale - newGlassWidth * 2) /
      (currDepth / scale - glassWidth * 2)
  );
  right.geometry.scale(
    newGlassWidth / glassWidth,
    (currHeight / scale - newGlassWidth) / (currHeight / scale - glassWidth),
    (currDepth / scale - newGlassWidth * 2) /
      (currDepth / scale - glassWidth * 2)
  );
  left.position.x = -currWidth / (2 * scale) + newGlassWidth / 2;
  right.position.x = currWidth / (2 * scale) - newGlassWidth / 2;
  lower.geometry.scale(1, newGlassWidth / glassWidth, 1);
  changeSiliconGlassWidth(newGlassWidth);
  changeAquariumTypeGlassWidth(newGlassWidth);
  setFilterLocation(
    Scene,
    currWidth,
    currHeight,
    filterInAquariumHeight,
    currDepth,
    newGlassWidth,
    currFilterLocation
  );
  glassWidth = newGlassWidth;
  changeFilter(filterType);
  if ((currWidth * currHeight * currDepth) / 1000 > betonplexlimit)
    betonPlex.position.set(
      0,
      -currHeight / 2 / scale - newGlassWidth - thickBetonplex / scale / 2,
      0
    );
  else
    betonPlex.position.set(
      0,
      -currHeight / 2 / scale - newGlassWidth - thinBetonplex / scale / 2,
      0
    );
}

export function changeCoverPanels(drawPanels: boolean) {
  switch (currAquariumType) {
    case AquariumType.lightCover: {
      changeLightCoverPanels(Scene, aquariumTypePlanks, drawPanels);
      break;
    }
    case AquariumType.turtle: {
      //for turtle it is also with the whole lightboard or not, the coverpanels is an option after that
      changeTurtleCoverpanels(Scene, aquariumTypePlanks, drawPanels);
      break;
    }
  }
}

export function setFullGlassPanels(panels: FullGlassCoverPanels) {
  if (currAquariumType === AquariumType.fullGlass) {
    setGlassPanels(Scene, panels, aquariumTypePlanks);
  }
}

//lichtboorafwerking: aluminium of decorpaneel
export function setLightboardFinish(lightBoardFinish: LightboardFinish) {
  switch (currAquariumType) {
    case AquariumType.lightCover: {
      break;
    }
    case AquariumType.turtle: {
      break;
    }
  }
}

//TODO zorg ervoor dat opties niet aangeduid kunnen worden als het aquarium niet groot genoeg is (eiland aan beide kanten kan niet wanneer het aquarium 50cm breed is)
export function setTurtleIsland(turtleIsland: TurtleIsland) {
  if (currAquariumType === AquariumType.turtle) {
    changeTurtleIsland(Scene, turtleIsland, aquariumTypePlanks);
  }
}

export function changeLightCover(drawLightCover: boolean) {
  if (currAquariumType === AquariumType.turtle) {
    changeTurtleLightCover(Scene, aquariumTypePlanks, drawLightCover);
  }
}

//[front, back, left, right]
export function changePaintColor(color: THREE.ColorRepresentation) {
  sidePaintColor = color;
  if (paintedSides.indexOf(Side.links, 0) > -1) {
    Scene.remove(left);
    left = colorPlankSide(
      Scene,
      -currWidth / (2 * scale) + glassWidth / 2,
      0,
      0,
      glassWidth,
      currHeight / scale - glassWidth,
      currDepth / scale - glassWidth * 2,
      [0xdefcff, sidePaintColor, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff],
      [
        sideOpacities[2],
        1,
        sideOpacities[2],
        sideOpacities[2],
        sideOpacities[2],
        sideOpacities[2],
      ]
    );
  }
  if (paintedSides.indexOf(Side.rechts, 0) > -1) {
    Scene.remove(right);
    right = colorPlankSide(
      Scene,
      currWidth / (2 * scale) - glassWidth / 2,
      0,
      0,
      glassWidth,
      currHeight / scale - glassWidth,
      currDepth / scale - glassWidth * 2,
      [sidePaintColor, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff],
      [
        1,
        sideOpacities[3],
        sideOpacities[3],
        sideOpacities[3],
        sideOpacities[3],
        sideOpacities[3],
      ]
    );
  }
  if (paintedSides.indexOf(Side.achter, 0) > -1) {
    Scene.remove(back);
    back = colorPlankSide(
      Scene,
      0,
      0,
      -currDepth / (2 * scale) + glassWidth / 2,
      currWidth / scale,
      currHeight / scale - glassWidth,
      glassWidth,
      [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, sidePaintColor],
      [
        sideOpacities[1],
        sideOpacities[1],
        sideOpacities[1],
        sideOpacities[1],
        sideOpacities[1],
        1,
      ]
    );
  }
}

export function paintSide(side: Side, isPainted: boolean) {
  let deletedElement = false;
  const index = paintedSides.indexOf(side, 0);
  if (!isPainted) {
    paintedSides.splice(index, 1);
    deletedElement = true;
  } else {
    paintedSides.push(side);
  }
  switch (side) {
    case Side.links: {
      if (deletedElement) {
        Scene.remove(left);
        left = makePlank(
          Scene,
          -currWidth / (2 * scale) + glassWidth / 2,
          0,
          0,
          glassWidth,
          currHeight / scale - glassWidth,
          currDepth / scale - glassWidth * 2,
          0xdefcff,
          sideOpacities[2]
        );
      } else {
        Scene.remove(left);
        left = colorPlankSide(
          Scene,
          -currWidth / (2 * scale) + glassWidth / 2,
          0,
          0,
          glassWidth,
          currHeight / scale - glassWidth,
          currDepth / scale - glassWidth * 2,
          [0xdefcff, sidePaintColor, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff],
          [
            sideOpacities[2],
            1,
            sideOpacities[2],
            sideOpacities[2],
            sideOpacities[2],
            sideOpacities[2],
          ]
        );
      }
      break;
    }
    case Side.rechts: {
      if (deletedElement) {
        Scene.remove(right);
        right = makePlank(
          Scene,
          currWidth / (2 * scale) - glassWidth / 2,
          0,
          0,
          glassWidth,
          currHeight / scale - glassWidth,
          currDepth / scale - glassWidth * 2,
          0xdefcff,
          sideOpacities[3]
        );
      } else {
        Scene.remove(right);
        right = colorPlankSide(
          Scene,
          currWidth / (2 * scale) - glassWidth / 2,
          0,
          0,
          glassWidth,
          currHeight / scale - glassWidth,
          currDepth / scale - glassWidth * 2,
          [sidePaintColor, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff],
          [
            1,
            sideOpacities[3],
            sideOpacities[3],
            sideOpacities[3],
            sideOpacities[3],
            sideOpacities[3],
          ]
        );
      }
      break;
    }
    case Side.achter: {
      if (deletedElement) {
        Scene.remove(back);
        back = makePlank(
          Scene,
          0,
          0,
          -currDepth / (2 * scale) + glassWidth / 2,
          currWidth / scale,
          currHeight / scale - glassWidth,
          glassWidth,
          0xdefcff,
          sideOpacities[1]
        );
      } else {
        Scene.remove(back);
        back = colorPlankSide(
          Scene,
          0,
          0,
          -currDepth / (2 * scale) + glassWidth / 2,
          currWidth / scale,
          currHeight / scale - glassWidth,
          glassWidth,
          [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, sidePaintColor],
          [
            sideOpacities[1],
            sideOpacities[1],
            sideOpacities[1],
            sideOpacities[1],
            sideOpacities[1],
            1,
          ]
        );
      }
    }
  }
}

//[front, back, left, right]
export function makeSideOptiWhite(side: Side, isOptiwhite: boolean) {
  let deletedElement = false;
  const index = optiWhiteSides.indexOf(side, 0); //check if the side is already in the list
  if (!isOptiwhite) {
    //if the side is in the list, delete it
    optiWhiteSides.splice(index, 1);
    deletedElement = true;
  } else {
    //if the side is not in the list, add it
    optiWhiteSides.push(side);
  }
  switch (side) {
    case Side.links: {
      if (deletedElement) {
        sideOpacities[2] = normalOpacity;
      } else {
        sideOpacities[2] = optiwhiteOpacity;
      }
      Scene.remove(left);
      left = makePlank(
        Scene,
        -currWidth / (2 * scale) + glassWidth / 2,
        0,
        0,
        glassWidth,
        currHeight / scale - glassWidth,
        currDepth / scale - glassWidth * 2,
        0xdefcff,
        sideOpacities[2]
      );
      break;
    }
    case Side.rechts: {
      if (deletedElement) {
        sideOpacities[3] = normalOpacity;
      } else {
        sideOpacities[3] = optiwhiteOpacity;
      }
      Scene.remove(right);
      right = makePlank(
        Scene,
        currWidth / (2 * scale) - glassWidth / 2,
        0,
        0,
        glassWidth,
        currHeight / scale - glassWidth,
        currDepth / scale - glassWidth * 2,
        0xdefcff,
        sideOpacities[3]
      );
      break;
    }
    case Side.achter: {
      if (deletedElement) {
        sideOpacities[1] = normalOpacity;
      } else {
        sideOpacities[1] = optiwhiteOpacity;
      }
      Scene.remove(back);
      back = makePlank(
        Scene,
        0,
        0,
        -currDepth / (2 * scale) + glassWidth / 2,
        currWidth / scale,
        currHeight / scale - glassWidth,
        glassWidth,
        0xdefcff,
        sideOpacities[1]
      );
      break;
    }
    case Side.voor: {
      if (deletedElement) {
        sideOpacities[0] = normalOpacity;
      } else {
        sideOpacities[0] = optiwhiteOpacity;
      }
      Scene.remove(front);
      front = makePlank(
        Scene,
        0,
        0,
        currDepth / (2 * scale) - glassWidth / 2,
        currWidth / scale,
        currHeight / scale - glassWidth,
        glassWidth,
        0xdefcff,
        sideOpacities[0]
      );
      break;
    }
  }
  changePaintColor(sidePaintColor); //to make sure the sides that has to be painted are painted, because that isn't checked in this function
}

export function changeFilter(filter: FilterOptions) {
  filterType = filter;
  let betonPlexThickness = 0;
  if (currBetonplexVisible)
    if ((currWidth * currHeight * currDepth) / 1000 > betonplexlimit) {
      betonPlexThickness = thickBetonplex;
    } else {
      betonPlexThickness = thinBetonplex;
    }
  makeFilter(
    Scene,
    currWidth,
    currHeight,
    currDepth,
    betonPlexThickness,
    glassWidth,
    filter
  );
  if (
    currFurniturType === FurnitureType.marine &&
    (filterType === FilterOptions.bio || filterType === FilterOptions.sump)
  ) {
    makeFilterCompartiment(Scene, true);
    makeFilterMarineConform(
      Scene,
      true,
      -currHeight / 2 - getLowerFunritureHeight() + closetThickness
    );
  } else {
    makeFilterCompartiment(Scene, false);
    makeFilterMarineConform(
      Scene,
      false,
      -currHeight / 2 - getLowerFunritureHeight() + closetThickness
    );
  }
  if (
    currFurniturType !== FurnitureType.none &&
    currFurniturType !== FurnitureType.marine
  ) {
    ChangeFilterHeightDiff(Scene, getFilterHeight(), bottomClosetHeight);
  }
}

export function changeFilterLocation(filterLocation: FilterLocation) {
  currFilterLocation = filterLocation;
  setFilterLocation(
    Scene,
    currWidth,
    currHeight,
    filterInAquariumHeight,
    currDepth,
    glassWidth,
    filterLocation
  );
}

export function changeBoreHole(boreHole: BoreHole, drawHole: boolean) {
  MakeBoreHole(Scene, boreHole, glassWidth, drawHole);
}

export function changeBioCoverPanels(drawBioCoverPanels: boolean) {
  toggleCoverPanels(Scene, drawBioCoverPanels);
}

export function changeOsmoseCompartiment(drawOsmoseCompartiment: boolean) {
  toggleOsmoseComp(Scene, drawOsmoseCompartiment);
}

export function changeClosetType(closetType: FurnitureType) {
  if (closetType !== FurnitureType.none) {
    bottomClosetHeight = defaultBottomClosetHeight;
  } else {
    bottomClosetHeight = 0;
  }
  setClosetType(
    Scene,
    currWidth,
    currHeight,
    bottomClosetHeight,
    currDepth,
    closetType
  );
  if (
    closetType === FurnitureType.marine &&
    (filterType === FilterOptions.bio || filterType === FilterOptions.sump)
  ) {
    makeFilterCompartiment(Scene, true);
    makeFilterMarineConform(
      Scene,
      true,
      -currHeight / 2 - getLowerFunritureHeight() + closetThickness
    );
  } else {
    makeFilterCompartiment(Scene, false);
    makeFilterMarineConform(
      Scene,
      false,
      -currHeight / 2 - getLowerFunritureHeight() + closetThickness
    );
  }
  if (
    closetType !== FurnitureType.none &&
    closetType !== FurnitureType.marine
  ) {
    ChangeFilterHeightDiff(Scene, getFilterHeight(), bottomClosetHeight);
  } else if (closetType === FurnitureType.none) {
    ChangeFilterHeightDiff(Scene, getFilterHeight(), bottomClosetHeight);
  }
  if (
    closetType === FurnitureType.aluminium ||
    closetType === FurnitureType.decor ||
    closetType === FurnitureType.marine
  ) {
    changeGrindListVisbility(false);
  }
  currFurniturType = closetType;
  if (upperClosetHeight > 0) {
    changeUpperClosetHeight(upperClosetHeight);
  }
}

export function changeLowerClosetHeight(height: number) {
  bottomClosetHeight = height;
  setLowerClosetHeight(Scene, height);
  if (currFurniturType === FurnitureType.marine) {
    makeFilterMarineConform(
      Scene,
      true,
      -currHeight / 2 - getLowerFunritureHeight() + closetThickness
    );
  }

  if (
    currFurniturType !== FurnitureType.none &&
    currFurniturType !== FurnitureType.marine
  ) {
    ChangeFilterHeightDiff(Scene, getFilterHeight(), height);
  }
}

export function changeUpperClosetHeight(height: number) {
  upperClosetHeight = height;
  setUpperClosetHeight(Scene, height);
}

export function rotateDoors(angle: number) {
  rotateClosetDoors(angle);
}

export function makeAquariumSeeThrough(seeThrough: boolean) {
  setAquariumSeeThrough(Scene, seeThrough);
}

export function setBetonPlex(betonPlexVisible: boolean) {
  changeBetonPlex(Scene, betonPlexVisible);
}

export function setRearWall(rearWallVisible: boolean) {
  changeRearWall(Scene, rearWallVisible);
}

export function setDoorType(doorType: DoorType) {
  changeDoorType(
    Scene,
    currWidth,
    currHeight,
    bottomClosetHeight,
    upperClosetHeight,
    currDepth,
    doorType
  );
}

export function setElectraSpace(electraSpace: ElectraRuimte) {
  changeElectraRuimte(Scene, electraSpace);
  setElectraruimte(Scene, electraSpace);
}

export function changeBetonPlexVisibility(betonPlexVisible: boolean) {
  currBetonplexVisible = betonPlexVisible;
  if (betonPlexVisible) {
    Scene.add(betonPlex);
    if ((currWidth * currHeight * currDepth) / 1000 > betonplexlimit) {
      changeWaterProofPlateHeight(thickBetonplex);
      changeFilterWaterproofPlateHeight(thickBetonplex);
    } else {
      changeWaterProofPlateHeight(thinBetonplex);
      changeFilterWaterproofPlateHeight(thinBetonplex);
    }
  } else {
    Scene.remove(betonPlex);
    changeWaterProofPlateHeight(0);
  }
}
export function getGlassWidthNumber() {
  return getGlassWidth(currWidth, currHeight, currDepth);
}

// also changes texture of lightcover and grindlist
export function changeFurnitureTexture(texture: DecorTexture) {
  let texturePhoto: String = "";
  if (texture === DecorTexture.antraciet) {
    texturePhoto = antraciet;
  } else if (texture === DecorTexture.concreteDecor) {
    texturePhoto = concrete;
  }
  //lichtkap texture moet mee veranderen
  changeClosetTexture(texturePhoto);
  changeGrindListTexture(texturePhoto);
  changeLightCoverTexture(
    texturePhoto,
    aquariumTypePlanks,
    currAquariumType === AquariumType.lightCover
  );
}

export function changeGrindListVisbility(grindListVisible: boolean) {
  if (grindListVisible) {
    for (let i = 0; i < grindListPlanks.length; i++) {
      Scene.add(grindListPlanks[i]);
    }
  } else {
    for (let i = 0; i < grindListPlanks.length; i++) {
      Scene.remove(grindListPlanks[i]);
    }
  }
}

function changeGrindListTexture(texture: String) {
  if (texture !== "") {
    grindListTexture = [
      new THREE.TextureLoader().load(String(texture)),
      new THREE.TextureLoader().load(String(texture)),
    ];
  }

  if (grindListTexture.length !== 0) {
    grindListPlanks[0].material = makeGeometryMaterials(
      grindListTexture[0],
      currWidth / scale,
      0.01,
      grindListHeight / scale
    );
    grindListPlanks[1].material = makeGeometryMaterials(
      grindListTexture[0],
      currWidth / scale,
      0.01,
      grindListHeight / scale
    );
    grindListPlanks[2].material = makeGeometryMaterials(
      grindListTexture[1],
      0.01,
      currWidth / scale,
      grindListHeight / scale
    );
    grindListPlanks[3].material = makeGeometryMaterials(
      grindListTexture[1],
      0.01,
      currWidth / scale,
      grindListHeight / scale
    );
  }
}

export function changeClosetElectraSwitchVisibility(visibility: boolean) {
  changeFurnitureElectraSwitchVisibility(Scene, visibility);
}
