import * as THREE from 'three';
import { makeGeometryMaterials, makePlank } from './helper';
import { makeSkeletonCloset } from './skeleton';
import { Angle } from '@phosphor-icons/react/dist/ssr';
import { DecorTexture } from '../../@types/textures';

const scale = 25;
const PI = 3.14159265359;

const closetThickness = 0.3;
const doorLeftRightSideDiff = 5;
const doorUpperBottomDiff = 9;
const outerPlankOverlap = 4;
const widthDistribution = [139,241,321,361]
const slidingDoorsExtraWidth = 2;

let currWidth: number;
let currHeight: number;
let currDepth: number;
let currLowerClosetHeight: number;
let currUpperClosetHeight = 0;
let currAngle = 0
let currWaterproofPlateHeight = 0
let currTexture: THREE.Texture[] = []

export function makeAluminiumSlidingDoors(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, waterproofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
    closetPlanks.push([])
    closetPlanks.push([])
    
    let amountComp = getAmountCompartiments(width)
    let compWidth = width/amountComp;
    for(let i = 0; i < amountComp; i++){
        closetPlanks[0].push(makePlank(scene, -width/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+(compWidth-2*doorLeftRightSideDiff)/scale/4, -height/scale/2-closetHeight/scale/2+outerPlankOverlap/scale/2-waterproofPlateHeight/scale, depth/scale/2+closetThickness/scale/2-closetThickness/scale, (compWidth-2*doorLeftRightSideDiff)/scale/2+slidingDoorsExtraWidth/scale, closetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        closetPlanks[0].push(makePlank(scene, -width/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+3*(compWidth-2*doorLeftRightSideDiff)/scale/4, -height/scale/2-closetHeight/scale/2+outerPlankOverlap/scale/2-waterproofPlateHeight/scale, depth/scale/2+closetThickness/scale/2-2*closetThickness/scale, (compWidth-2*doorLeftRightSideDiff)/scale/2+slidingDoorsExtraWidth/scale, closetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
    }
    makeFrontPlank(scene, width, height, closetHeight, depth, waterproofPlateHeight, closetPlanks)
    
    currWidth = width;
    currHeight = height;
    currDepth = depth;
    currLowerClosetHeight = closetHeight;
    let tempAngle = currAngle
    currAngle = 0
    currWaterproofPlateHeight = waterproofPlateHeight
    slideDoors(tempAngle, closetPlanks);
    changeAluminiumSlidingDoorsTexture("", closetPlanks)
}

function  makeFrontPlank(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, waterproofPlateHeight: number, closetPlanks: THREE.Mesh[][], index = 0){
    let amountComp = getAmountCompartiments(width)
    let compWidth = width/amountComp;
    for(let i = 0; i < amountComp; i++){
        let shape = new THREE.Shape();

        shape.moveTo(-width/scale/2+i*compWidth/scale-closetThickness/scale, -height/scale/2+outerPlankOverlap/scale-waterproofPlateHeight/scale);
        shape.lineTo(-width/scale/2+i*compWidth/scale-closetThickness/scale, -height/scale/2-closetHeight/scale-waterproofPlateHeight/scale);
        shape.lineTo(-width/scale/2+(i+1)*compWidth/scale+closetThickness/scale, -height/scale/2-closetHeight/scale-waterproofPlateHeight/scale);
        shape.lineTo(-width/scale/2+(i+1)*compWidth/scale+closetThickness/scale, -height/scale/2+outerPlankOverlap/scale-waterproofPlateHeight/scale);
        shape.lineTo(-width/scale/2+i*compWidth/scale-closetThickness/scale, -height/scale/2+outerPlankOverlap/scale-waterproofPlateHeight/scale);

        shape.moveTo(-width/scale/2+doorLeftRightSideDiff/scale+i*compWidth/scale, -height/scale/2+outerPlankOverlap/scale-doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        shape.lineTo(-width/scale/2-doorLeftRightSideDiff/scale+(i+1)*compWidth/scale, -height/scale/2+outerPlankOverlap/scale-doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        shape.lineTo(-width/scale/2-doorLeftRightSideDiff/scale+(i+1)*compWidth/scale, -height/scale/2-closetHeight/scale+doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        shape.lineTo(-width/scale/2+doorLeftRightSideDiff/scale+i*compWidth/scale, -height/scale/2-closetHeight/scale+doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        shape.lineTo(-width/scale/2+doorLeftRightSideDiff/scale+i*compWidth/scale, -height/scale/2+outerPlankOverlap/scale-doorUpperBottomDiff/scale-waterproofPlateHeight/scale)
        
        let shapeGeo = new THREE.ExtrudeGeometry(shape, {depth: closetThickness/scale, bevelEnabled: false});
        let shapeMat = new THREE.MeshPhongMaterial({color: 0x3d3d3d, shininess: 50, emissive:0x3d3d3d});

        let shapeMesh = new THREE.Mesh(shapeGeo, shapeMat);
        shapeMesh.position.z = depth/scale/2+closetThickness/scale/2
        scene.add(shapeMesh);
        closetPlanks[index].push(shapeMesh)
    }
}

export function makeUpperAluminiumSlidingDoors(scene: THREE.Scene, width: number, height: number, closetHeight: number, depth: number, closetPlanks: THREE.Mesh[][]){
    
    closetPlanks.push([])
    closetPlanks.push([])
    
    let amountComp = getAmountCompartiments(width)
    let compWidth = width/amountComp;
    for(let i = 0; i < amountComp; i++){
        closetPlanks[1].push(makePlank(scene, -width/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+(compWidth-2*doorLeftRightSideDiff)/scale/4, height/scale/2+closetHeight/scale/2-outerPlankOverlap/scale/2, depth/scale/2+closetThickness/scale/2-closetThickness/scale, (compWidth-2*doorLeftRightSideDiff)/scale/2+slidingDoorsExtraWidth/scale, closetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        closetPlanks[1].push(makePlank(scene, -width/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+3*(compWidth-2*doorLeftRightSideDiff)/scale/4, height/scale/2+closetHeight/scale/2-outerPlankOverlap/scale/2, depth/scale/2+closetThickness/scale/2-2*closetThickness/scale, (compWidth-2*doorLeftRightSideDiff)/scale/2+slidingDoorsExtraWidth/scale, closetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
    }
    makeFrontPlank(scene, width, -(height+2*closetHeight)+2*outerPlankOverlap, closetHeight, depth, 0, closetPlanks,1);

    currWidth = width;
    currHeight = height;
    currDepth = depth;
    currUpperClosetHeight = closetHeight;
    let tempAngle = currAngle
    currAngle = 0
    slideDoors(tempAngle, closetPlanks);
    changeAluminiumSlidingDoorsTexture("", closetPlanks)
}

export function changeAluminiumSlidingDoorWidth(scene: THREE.Scene, newWidth: number, closetPlanks: THREE.Mesh[][]){
    for(let i = 0; i < 2; i++){
        for(let j = closetPlanks[i].length-1; j >= 0 ; j--){
            scene.remove(closetPlanks[i][j])
            closetPlanks[i].splice(j,1)
        }
    }
    let amountComp = getAmountCompartiments(newWidth)
    let compWidth = newWidth/amountComp;
    for(let i = 0; i < amountComp; i++){
        closetPlanks[0].push(makePlank(scene, -newWidth/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+(compWidth-2*doorLeftRightSideDiff)/scale/4, -currHeight/scale/2-currLowerClosetHeight/scale/2+outerPlankOverlap/scale/2, currDepth/scale/2+closetThickness/scale/2-closetThickness/scale, (compWidth-2*doorLeftRightSideDiff)/scale/2+slidingDoorsExtraWidth/scale, currLowerClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        closetPlanks[0].push(makePlank(scene, -newWidth/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+3*(compWidth-2*doorLeftRightSideDiff)/scale/4, -currHeight/scale/2-currLowerClosetHeight/scale/2+outerPlankOverlap/scale/2, currDepth/scale/2+closetThickness/scale/2-2*closetThickness/scale, (compWidth-2*doorLeftRightSideDiff)/scale/2+slidingDoorsExtraWidth/scale, currLowerClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        
        if(currUpperClosetHeight > 0){
            closetPlanks[1].push(makePlank(scene, -newWidth/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+(compWidth-2*doorLeftRightSideDiff)/scale/4, currHeight/scale/2+currUpperClosetHeight/scale/2-outerPlankOverlap/scale/2, currDepth/scale/2+closetThickness/scale/2-closetThickness/scale, (compWidth-2*doorLeftRightSideDiff)/scale/2+slidingDoorsExtraWidth/scale, currUpperClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
            closetPlanks[1].push(makePlank(scene, -newWidth/scale/2+i*compWidth/scale+doorLeftRightSideDiff/scale+3*(compWidth-2*doorLeftRightSideDiff)/scale/4, currHeight/scale/2+currUpperClosetHeight/scale/2-outerPlankOverlap/scale/2, currDepth/scale/2+closetThickness/scale/2-2*closetThickness/scale, (compWidth-2*doorLeftRightSideDiff)/scale/2+slidingDoorsExtraWidth/scale, currUpperClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01, closetThickness/scale, 0x3d3d3d))
        }
    }
    

    makeFrontPlank(scene, newWidth, currHeight, currLowerClosetHeight, currDepth, currWaterproofPlateHeight, closetPlanks)
    if(currUpperClosetHeight > 0){
        makeFrontPlank(scene, newWidth, -(currHeight+2*currUpperClosetHeight)+2*outerPlankOverlap, currUpperClosetHeight, currDepth, 0, closetPlanks,1);
    }
    
    currWidth = newWidth
    let tempAngle = currAngle
    currAngle = 0
    slideDoors(tempAngle, closetPlanks);
    changeAluminiumSlidingDoorsTexture("", closetPlanks)
}

export function changeAluminiumSlidingDoorHeight(scene: THREE.Scene, newHeight: number, closetPlanks: THREE.Mesh[][]){
    if(newHeight === currHeight){
        return
    }
    for(let j = 0; j < closetPlanks[0].length ; j++){
        closetPlanks[0][j].position.y -= (newHeight-currHeight)/scale/2
    }

    for(let j = 0; j < closetPlanks[1].length ; j++){
        closetPlanks[1][j].position.y += (newHeight-currHeight)/scale/2
    }

    currHeight = newHeight;
}

export function changeAluminiumSlidingDoorDepth(scene: THREE.Scene, newDepth: number, closetPlanks: THREE.Mesh[][]){
    if(currDepth === newDepth){
        return
    }
    for(let i = 0; i < 2; i++){
        for(let j = 0; j < closetPlanks[i].length; j++){
            closetPlanks[i][j].position.z += (newDepth-currDepth)/scale/2
        }
    }

    currDepth = newDepth
}

export function changeAluminiumLowerSlidingDoorHeight(scene: THREE.Scene, newClosetHeight: number, closetPlanks: THREE.Mesh[][]){
    if(currLowerClosetHeight === newClosetHeight){
        return;
    }
    for(let i = closetPlanks[0].length-1; i >= 0; i--){
        scene.remove(closetPlanks[0][i])
        closetPlanks[0].splice(i,1);
    }
    let tempAngle = currAngle
    slideDoors(-currAngle, closetPlanks)
    makeAluminiumSlidingDoors(scene, currWidth, currHeight, newClosetHeight, currDepth, currWaterproofPlateHeight, closetPlanks);
    slideDoors(tempAngle, closetPlanks)
    changeAluminiumSlidingDoorsTexture("", closetPlanks)
}

export function changeAluminiumUpperSlidningDoorHeight(scene: THREE.Scene, newClosetHeight: number, closetPlanks: THREE.Mesh[][]){
    
    for(let i = closetPlanks[1].length-1; i >= 0; i--){
        scene.remove(closetPlanks[1][i])
        closetPlanks[1].splice(i,1);
    }

    if(newClosetHeight > 0){
        let tempAngle = currAngle
        slideDoors(-currAngle, closetPlanks)
        makeUpperAluminiumSlidingDoors(scene, currWidth, currHeight, newClosetHeight, currDepth, closetPlanks)
        slideDoors(tempAngle, closetPlanks)
    }
    
    changeAluminiumSlidingDoorsTexture("", closetPlanks)
}

function getAmountCompartiments(width: number){
    for(let i = widthDistribution.length-1; i >= 0; i--){
        if(width > widthDistribution[i]){
            return i+2
        }
    }
    return 1;
}

//the animate function goes until 80 degrees, so we'll take that and slide the door like 90 degrees is totaly open and 0 degrees is totaly closed
// angle is how far the door should be moved in the state it is now
export function slideDoors(angle: number, closetPlanks: THREE.Mesh[][]){
    let amount_comp = getAmountCompartiments(currWidth);
    let compWidth = currWidth/amount_comp;

    currAngle=(currAngle+angle)%(PI/2);
    let doorWidthMovement = (compWidth-2*doorLeftRightSideDiff)*angle/(PI/2)/2
    for(let j = 0; j < 2; j++){
        let side = -0.5;
        for(let i = 0; i < closetPlanks[j].length/3; i++){
            closetPlanks[j][2*i+0.5+side].position.x += doorWidthMovement/scale*side*-2;
            side *=-1
        }
    }
}

export function getSlideWidth(): number{
    return currAngle;
}

export function aluminiumSlidingDoorsChangeWaterProofPlateHeight(newWaterProofPlateHeight: number, closetPlanks: THREE.Mesh[][]){
    for(let i = 0; i < closetPlanks[0].length; i++){
        closetPlanks[0][i].position.y += (currWaterproofPlateHeight-newWaterProofPlateHeight)/scale
    }
    currWaterproofPlateHeight = newWaterProofPlateHeight
}

export function changeAluminiumSlidingDoorsTexture(texture: String, closetPlanks: THREE.Mesh[][]){
    if(texture !== ""){
        currTexture = [new THREE.TextureLoader().load(String(texture)),new THREE.TextureLoader().load(String(texture))]
    }
    let amountComp = getAmountCompartiments(currWidth)
    let compWidth = currWidth/amountComp;
    if(currTexture.length !== 0){
        for(let i = 0; i < amountComp*2; i++){
            //lower doors
            closetPlanks[0][i].material = makeGeometryMaterials(currTexture[0], (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, closetThickness/scale, currLowerClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01)
        }
        for(let i = amountComp*2; i < amountComp*3; i++){
            //doorframe
            closetPlanks[0][i].material = makeGeometryMaterials(currTexture[0], compWidth/scale, closetThickness/scale, currLowerClosetHeight/scale+outerPlankOverlap/scale)
        }
        if(currUpperClosetHeight > 0){
            for(let i = 0; i < amountComp*2; i++){
                //upper doors
                closetPlanks[1][i].material = makeGeometryMaterials(currTexture[1], (compWidth-2*doorLeftRightSideDiff)/scale/2-0.01, closetThickness/scale, currUpperClosetHeight/scale-2*doorUpperBottomDiff/scale+outerPlankOverlap/scale-0.01)
            }
            for(let i = amountComp*2; i < amountComp*3; i++){
                //doorframe
                closetPlanks[1][i].material = makeGeometryMaterials(currTexture[0], compWidth/scale, closetThickness/scale, currUpperClosetHeight/scale+outerPlankOverlap/scale)
            }
        }
    }
}