import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import "../../../styles/configuratorNavigator.css";
import { SmallText, Text } from "../../atoms/StyledComponents";
import { StepObj, StepStatus, Steps } from "../../../@types/configurator";
import {
  renderNextStep,
  renderPrevStep,
} from "../../../utils/stepConfigurator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setShowOverviewScreen } from "../../../redux/configuratorSlice";

type Props = {
  currentStep: number;
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function ConfiguratorNavigator({ currentStep, setOpenStep }: Props) {
  const dispatch = useDispatch();
  const stepInfoListRedux = useSelector(
    (state: RootState) => state.configurator.stepInfoList
  );

  const prevStepObj: StepObj | null = renderPrevStep(
    currentStep,
    stepInfoListRedux
  );
  const nextStepObj: StepObj | null = renderNextStep(
    currentStep,
    stepInfoListRedux
  );
  const getOverviewButtonDisabled = () => {
    const disableButton = stepInfoListRedux.filter(
      (item) => item.status !== StepStatus.isCompleted
    );
    return disableButton.length !== 0;
  };

  return (
    <div className="configurator-navigator-container">
      {prevStepObj && (
        <div
          className="configurator-navigator-button"
          onClick={() => setOpenStep(prevStepObj.name)}
        >
          <CaretLeft size={28} weight="bold" />
          <div>
            <Text className="configurator-navigator-button-title">vorige</Text>
            <SmallText>{prevStepObj.title}</SmallText>
          </div>
        </div>
      )}
      <div className="configurator-navigator-fill"></div>
      {nextStepObj && (
        <div
          className="configurator-navigator-button"
          onClick={() => setOpenStep(nextStepObj.name)}
        >
          <div>
            <Text className="configurator-navigator-button-title">
              volgende
            </Text>
            <SmallText>{nextStepObj.title}</SmallText>
          </div>
          <CaretRight size={28} weight="bold" />
        </div>
      )}
      {currentStep === 6 && !getOverviewButtonDisabled() && (
        <div
          className="configurator-navigator-button configurator-navigator-button-to-overview"
          onClick={() => {
            setOpenStep(null);
            dispatch(setShowOverviewScreen(true));
          }}
        >
          <div>
            <Text className="configurator-navigator-button-title">
              volgende
            </Text>
            <SmallText>Overzicht</SmallText>
          </div>
          <CaretRight size={28} weight="bold" />
        </div>
      )}
    </div>
  );
}

export default ConfiguratorNavigator;
