import React, { useEffect, useState } from "react";
import ConfiguratorStep from "../../templates/ConfiguratorStep";
import {
  ImageOption,
  StepInfoShort,
  StepStatus,
  Steps,
} from "../../../@types/configurator";
import { ArrowsCounterClockwise, Cube } from "@phosphor-icons/react";
import StepItem from "../../templates/StepItem";
import BiotoopButton from "../../molecules/BiotoopButton";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  AquariumType,
  CoverPanelsColor,
  FullGlassCoverPanels,
  FurnitureType,
  TurtleIsland,
  WaterType,
} from "../../../@types/aquarium";
import {
  setFullGlassCoverPanels,
  setWaterType as setWaterTypeRedux,
} from "../../../redux/aquariumSlice";
import { setAquariumType as setAquariumTypeRedux } from "../../../redux/aquariumSlice";
import { aquariumTypeOptions } from "../../../data/aquariumTypes";
import {
  changeAquariumType,
  changeCoverPanels,
  changeGrindListVisbility,
  changeLightCover,
  setTurtleIsland,
} from "../../aquariumComponents/tank";
import { DecorTexture } from "../../../@types/textures";
import ConfiguratorImageOption from "../../molecules/ConfiguratorImageOption";
import {
  setOpenTexturesModal,
} from "../../../redux/configuratorSlice";
import { decorTexturePlaceholder, decorTextures } from "../../../data/textures";
import ImageSelectionButton from "../../molecules/ImageSelectionButton";
import IconToggle from "../../molecules/IconToggle";
import { setCoverPanels as setCoverPanelsRedux } from "../../../redux/aquariumSlice";
import {
  fullGlassCoverOptions,
  turtleIslandOptions,
} from "../../../data/aquarium";
import { setFullGlassPanels } from "../../aquariumComponents/tank";
import { setTurtleIslandLocation as setTurtleIslandLocationRedux } from "../../../redux/aquariumSlice";
import { setLightCover as setLightCoverRedux } from "../../../redux/aquariumSlice";
import { setCoverPanelsColor as setCoverPanelsColorRedux } from "../../../redux/aquariumSlice";
import { setGrindList as setGrindListRedux } from "../../../redux/aquariumSlice";
import { defaultAquarium } from "../../../utils/defaultAquarium";
import CoverPanelsColorMenu from "../../molecules/CoverPanelsColorMenu";

type Props = {
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function AquariumTypeStep({ setOpenStep }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const stepItemInfoOverview = useSelector(
    (state: RootState) => state.configurator.stepItemInfoOverview
  );
  const dispatch = useDispatch();
  const [waterType, setWaterType] = useState<WaterType | null>(
    aquarium.waterType
  );
  const [aquariumType, setAquariumType] = useState<AquariumType | null>(
    aquarium.aquariumType
  );
  const [aquariumTexture, setAquariumTexture] = useState<DecorTexture | null>(
    aquarium.aquariumTexture
  );
  const getTextureObj = (textureID: DecorTexture): ImageOption => {
    const decorTexture = decorTextures.find((item) => item.id === textureID);
    return decorTexture ? decorTexture : decorTexturePlaceholder;
  };
  const [selectedTexture, setSelectedTexture] = useState<ImageOption>(
    getTextureObj(
      aquarium.aquariumTexture
        ? aquarium.aquariumTexture
        : DecorTexture.placeholder
    )
  );
  const [coverPanels, setCoverPanels] = useState<boolean>(aquarium.coverPanels);
  const [fullGlassPanelOption, setFullGlassPanelOption] =
    useState<FullGlassCoverPanels | null>(aquarium.fullGlassCoverPanels);
  const [turtleIslandLocation, setTurtleIslandLocation] =
    useState<TurtleIsland | null>(aquarium.turtleIsland);
  const [lightCover, setLightCover] = useState<boolean>(aquarium.lightCover);
  const [coverPanelsColor, setCoverPanelsColor] = useState<CoverPanelsColor>(
    aquarium.coverPanelsColor
  );
  const [grindList, setGrindList] = useState<boolean>(aquarium.grindlist);

  // Reset aquarium
  useEffect(() => {
    setWaterType(aquarium.waterType);
    setAquariumType(aquarium.aquariumType);
    setAquariumTexture(aquarium.aquariumTexture);
    setCoverPanels(aquarium.coverPanels);
    setFullGlassPanelOption(aquarium.fullGlassCoverPanels);
    setTurtleIslandLocation(aquarium.turtleIsland);
    setLightCover(aquarium.lightCover);
  }, [aquarium]);

  useEffect(() => {
    dispatch(setWaterTypeRedux(waterType));
  }, [waterType]);

  useEffect(() => {
    dispatch(setAquariumTypeRedux(aquariumType));
  }, [aquariumType]);

  useEffect(() => {
    setSelectedTexture(
      aquariumTexture ? getTextureObj(aquariumTexture) : decorTexturePlaceholder
    );
  }, [aquariumTexture]);

  useEffect(() => {
    dispatch(setCoverPanelsRedux(coverPanels));
  }, [coverPanels]);

  useEffect(() => {
    dispatch(setFullGlassCoverPanels(fullGlassPanelOption));
  }, [fullGlassPanelOption]);

  useEffect(() => {
    dispatch(setTurtleIslandLocationRedux(turtleIslandLocation));
  }, [turtleIslandLocation]);

  useEffect(() => {
    if (lightCover === false && aquariumType === AquariumType.turtle) {
      dispatch(setCoverPanelsRedux(false));
    }
    dispatch(setLightCoverRedux(lightCover));
  }, [lightCover]);

  useEffect(() => {
    dispatch(setCoverPanelsColorRedux(coverPanelsColor));
  }, [coverPanelsColor]);
  useEffect(() => {
    dispatch(setGrindListRedux(grindList));
  }, [grindList]);

  const openTextureMenu = () => {
    dispatch(setOpenTexturesModal(true));
  };

  return (
    <ConfiguratorStep
      title="Aquarium type"
      step={2}
      stepId={Steps.aquariumType}
      symbol={
        <Cube
          className="configurator-step-header-symbol"
          size={80}
          color="var(--primary-300)"
        />
      }
      setOpenStep={setOpenStep}
    >
      <>
        <StepItem
          title="Selecteer de biotoop"
          helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
          errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
          hasIssue={false}
        >
          <BiotoopButton waterType={waterType} setWaterType={setWaterType} />
        </StepItem>
        {waterType && (
          <StepItem
            title="Selecteer het type aquarium"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Uw huidige configuratie klopt niet meer met uw aangeduiden aquariumtype. Duid een van de onderstaande opties aan om deze fout op te lossen."
            hasIssue={stepItemInfoOverview.aquariumType.aquariumType.hasIssue}
          >
            <div className="optionlist-container">
              {aquariumTypeOptions
                .filter(
                  (option) =>
                    (aquarium.waterType === WaterType.salty &&
                      option.id !== AquariumType.turtle) ||
                    aquarium.waterType === WaterType.fresh
                )
                .map((option) => (
                  <ConfiguratorImageOption
                    name="type-aquarium-option"
                    option={option}
                    value={aquariumType}
                    setValue={setAquariumType}
                    key={"type-aquarium-option-" + option.id}
                  />
                ))}
            </div>
          </StepItem>
        )}

        {aquariumType === AquariumType.fullGlass && (
          <StepItem
            title="Afdekruiten"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <div className="optionlist-container">
              {fullGlassCoverOptions.map((option) => (
                <ConfiguratorImageOption
                  name="fullglass-panel-option"
                  option={option}
                  value={fullGlassPanelOption}
                  setValue={setFullGlassPanelOption}
                  key={"fullglass-panel-option-" + option.id}
                />
              ))}
            </div>
          </StepItem>
        )}
        {aquariumType === AquariumType.turtle && (
          <>
            <StepItem
              title="Schildpadden eiland"
              helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
              errortext="De gekozen optie is niet mogelijk met de huidige afmetingen van het aquarium. Gelieve een van onderstaande opties aan te duiden of de lengte aan te passen."
              hasIssue={stepItemInfoOverview.aquariumType.turtleIsland.hasIssue}
            >
              <div className="optionlist-container">
                {turtleIslandOptions
                  .filter(
                    (option) =>
                      option.id === TurtleIsland.noIsland ||
                      (aquarium.aquariumDimensions.width >= 80 &&
                        (option.id === TurtleIsland.left ||
                          option.id === TurtleIsland.right)) ||
                      (aquarium.aquariumDimensions.width >= 120 &&
                        option.id === TurtleIsland.twosided)
                  )
                  .map((option) => (
                    <ConfiguratorImageOption
                      name="turtle-island-option"
                      option={option}
                      value={turtleIslandLocation}
                      setValue={setTurtleIslandLocation}
                      key={"turtle-island-option-" + option.id}
                    />
                  ))}
              </div>
            </StepItem>
            <StepItem
              title="Lichtboord"
              helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
              errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
              hasIssue={false}
            >
              <IconToggle
                checked={lightCover}
                setValue={setLightCover}
                text="Wilt u een lichtboord op het schildpadden aquarium?"
              />
            </StepItem>
          </>
        )}
        {aquariumType !== null && (
          <StepItem
            title="Grindlijst"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <IconToggle
              checked={grindList}
              setValue={setGrindList}
              text="Wilt u een grindlijst toevoegen aan uw aquarium?"
              disabled={
                aquarium.furniture.furnitureType === FurnitureType.aluminium ||
                aquarium.furniture.furnitureType === FurnitureType.decor ||
                aquarium.furniture.furnitureType === FurnitureType.marine
              }
            />
          </StepItem>
        )}
        {(aquariumType === AquariumType.lightCover ||
          (aquariumType === AquariumType.turtle && lightCover) ||
          grindList) && (
          <StepItem
            title="Selecteer de textuur"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <div className="optionlist-container">
              <div
                className="optionlist-button-container"
                onClick={() => openTextureMenu()}
              >
                <ImageSelectionButton
                  name="aquarium-texture-option"
                  option={selectedTexture}
                  value={aquariumTexture}
                  setValue={setAquariumTexture}
                  key={"aquarium-texture-option-" + selectedTexture.id}
                />
              </div>
            </div>
          </StepItem>
        )}
        {(aquariumType === AquariumType.lightCover ||
          (aquariumType === AquariumType.turtle && lightCover)) && (
          <StepItem
            title="Afdekplaten"
            helptext="Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in...Vul in de ze stap de afmetingen in..."
            errortext="Bepaalde afmetingen kloppen niet meer, gelieve deze opennieuw in te vullen."
            hasIssue={false}
          >
            <>
              <IconToggle
                checked={coverPanels}
                setValue={setCoverPanels}
                text="Wilt u afdekplaten voor bovenop het aquarium?"
              />
              {coverPanels && (
                <>
                  <h4 className="window-paint-menu-title">
                    Selecteer een kleur
                  </h4>
                  <CoverPanelsColorMenu
                    checked={coverPanelsColor}
                    setValue={setCoverPanelsColor}
                  />
                </>
              )}
            </>
          </StepItem>
        )}
      </>
    </ConfiguratorStep>
  );
}

export default AquariumTypeStep;
