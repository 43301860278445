import React from "react";
import Header from "../components/organisms/Header";
import { AllTextObject } from "../@types/language";
import "../styles/orderMessage.css";
import landing_animation from "../images/landing-coral-gif.gif";
import {
  GradientSubTitle,
  SmallerText,
  Text,
} from "../components/atoms/StyledComponents";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Socials from "../components/molecules/Socials";
import OrderProcess from "../components/molecules/OrderProcess";
import OverviewActionButton from "../components/molecules/OverviewActionButton";
import { Link } from "react-router-dom";

type Props = { allText: AllTextObject };

function ErrorScreen({ allText }: Props) {
  return (
    <div className="order-message-screen-container">
      <Header allText={allText} />
      <main className="landing-banner-container overview-container order-message-screen">
        <div className="order-message-container">
          <div className="order-message-animation-container landing-banner-animation-container">
            <figure>
              <img src={landing_animation} alt="coral animation" />
            </figure>
          </div>
          <div className="order-message-screen-content-container">
            <div
              className="order-message-screen-content-column"
              style={{
                width: "90%",
                maxWidth: "500px",
                marginRight: "auto",
                marginLeft: "var(--spacing-sm,)",
              }}
            >
              <GradientSubTitle color="var(--primary-gradient-200)">
                Oeps, er ging iets mis!
              </GradientSubTitle>
              <Text>
                Probeer de pagina te herladen of klik op een van de onderstaande
                opties:
              </Text>
              <div className="error-screen-buttons-container">
                <Link to="configurator">
                  <OverviewActionButton
                    action={() => {}}
                    text="Naar de conifgurator"
                    color="var(--green-gradient-500)"
                    disabled={false}
                  />
                </Link>
                <Link to="/">
                  <OverviewActionButton
                    action={() => {}}
                    text="Naar de homepage"
                    color="var(--ocean-gradient-500)"
                    disabled={false}
                  />
                </Link>
              </div>
              <Socials />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ErrorScreen;
