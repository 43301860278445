import React, { useEffect, useState } from "react";
import TextInput from "./TextInput";
import {
  DimensionsDependant,
  PricePerUnitOptions,
  ProductCategory,
  VolumeDependant,
} from "../../@types/product";
import { Text } from "../atoms/StyledComponents";
import TextArea from "./TextArea";
import NumberInput from "./NumberInput";
import RadioSelectionMenu from "./RadioSelectionMenu";
import { unitOptions } from "../../data/products";
import CurrencyInput from "react-currency-input-field";
import IconToggle from "./IconToggle";
import placeholderImage from "../../images/textures/placeholder.jpg";
import { CaretLeft } from "@phosphor-icons/react";
import SecondaryButton from "../atoms/SecondaryButton";
import PrimaryButton from "../atoms/PrimaryButton";
import SelectProductCategoryMenu from "./SelectProductCategoryMenu";

type Props = { productCategory: ProductCategory };

function AddProduct({ productCategory }: Props) {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  // const [category, setCategory] = useState<ProductCategory>(productCategory);
  const [price, setPrice] = useState<number>();
  const [discountReseller1, setDiscountReseller1] = useState<number>(35);
  const [discountReseller2, setDiscountReseller2] = useState<number>(40);
  const [pricePerUnit, setPricePerUnit] = useState<PricePerUnitOptions>();
  const [isRecommanded, setIsRecommanded] = useState<boolean>(false);
  const [recommandedText, setRecommandedText] = useState<string>("");
  const [image, setImage] = useState<any>(placeholderImage);

  const [showDependenciesMenu, setShowDependenciesMenu] =
    useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<
    ProductCategory[]
  >([productCategory]);

  const [minLengthDepenency, setMinLengthDependency] = useState<number | null>(
    null
  );
  const [maxLengthDepenency, setMaxLengthDependency] = useState<number | null>(
    null
  );
  const [minWidthDepenency, setMinWidthDependency] = useState<number | null>(
    null
  );
  const [maxWidthDepenency, setMaxWidthDependency] = useState<number | null>(
    null
  );
  const [minDepthDepenency, setMinDepthDependency] = useState<number | null>(
    null
  );
  const [maxDepthDepenency, setMaxDepthDependency] = useState<number | null>(
    null
  );
  const [minVolumeDepenency, setMinVolumeDependency] = useState<number | null>(
    null
  );
  const [maxVolumeDepenency, setMaxVolumeDependency] = useState<number | null>(
    null
  );

  function handleChange(e: any) {
    setImage(URL.createObjectURL(e.target.files[0]));
  }

  function handleSaveItem() {
    //TODO DAAN: hier moet alle data worden opgeslagen in de database
    //Het json bestand ziet eruit zoals het hele ProductData type
  }

  return (
    <form className="add-product-modal-container">
      <Text weight={700}>Voeg nieuw toe</Text>
      <div className="add-product-form-container">
        <div className="add-product-column">
          <div className="add-product-category-container">
            <div
              className="text-input-label"
              style={{
                color: "var(--neutral-100)",
                gap: "var(--spacing-2xs)",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                margin: 0,
              }}
            >
              categorie
            </div>
            <SelectProductCategoryMenu
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
          </div>
          <TextInput
            title="id"
            value={id}
            setValue={setId}
            column
            color="var(--neutral-100)"
          />
          <TextInput
            title="naam"
            value={name}
            setValue={setName}
            column
            color="var(--neutral-100)"
          />
          <TextArea
            title="beschrijving"
            value={description}
            setValue={setDescription}
            column
            color="var(--neutral-100)"
          />
        </div>
        <div className="add-product-column">
          <div className="add-product-price-container add-product-price-parent">
            <label
              className="text-input-label"
              htmlFor="product-price-input"
              style={{
                color: "var(--neutral-100)",
                gap: "var(--spacing-2xs)",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              prijs
              <CurrencyInput
                id="product-price-input"
                name="product-price-input"
                placeholder="Vul een prijs in"
                className="text-input"
                value={price}
                decimalsLimit={2}
                onValueChange={(value, name, values) =>
                  values !== undefined && values?.float !== null
                    ? setPrice(values.float)
                    : ""
                }
                prefix="€"
                allowNegativeValue={false}
              />
            </label>
            <RadioSelectionMenu
              title="eenheid"
              value={pricePerUnit}
              setValue={setPricePerUnit}
              options={unitOptions}
              column
              color="var(--neutral-100)"
            />
          </div>
          <div className="add-product-price-container">
            <label
              className="text-input-label"
              htmlFor="product-price-input"
              style={{
                color: "var(--neutral-100)",
                gap: "var(--spacing-2xs)",
                display: "flex",
                flexDirection: "column",
                width: "50%",
                marginRight: "unset",
              }}
            >
              korting reseller 1
              <CurrencyInput
                id="product-price-input"
                name="product-price-input"
                placeholder="Vul een prijs in"
                className="text-input"
                value={discountReseller1}
                decimalsLimit={0}
                onValueChange={(value, name, values) =>
                  values !== undefined && values?.float !== null
                    ? setDiscountReseller1(values.float)
                    : ""
                }
                suffix="%"
                allowNegativeValue={false}
                maxLength={2}
              />
            </label>
            <div className="add-product-unit">
              {price
                ? "(€" +
                  (price * (1 - discountReseller1 / 100))
                    .toFixed(2)
                    .replace(".", ",") +
                  ") "
                : ""}
              {pricePerUnit
                ? unitOptions.map((option) =>
                    option.value === pricePerUnit ? option.title : ""
                  )
                : "selecteer"}
            </div>
          </div>

          <div className="add-product-price-container">
            <label
              className="text-input-label"
              htmlFor="product-price-input"
              style={{
                color: "var(--neutral-100)",
                gap: "var(--spacing-2xs)",
                display: "flex",
                flexDirection: "column",
                width: "50%",
                marginRight: "unset",
              }}
            >
              korting reseller 2
              <CurrencyInput
                id="product-price-input"
                name="product-price-input"
                placeholder="Vul een prijs in"
                className="text-input"
                value={discountReseller2}
                decimalsLimit={0}
                onValueChange={(value, name, values) =>
                  values !== undefined && values?.float !== null
                    ? setDiscountReseller2(values.float)
                    : ""
                }
                suffix="%"
                allowNegativeValue={false}
                maxLength={2}
              />
            </label>
            <div className="add-product-unit">
              {price
                ? "(€" +
                  (price * (1 - discountReseller2 / 100))
                    .toFixed(2)
                    .replace(".", ",") +
                  ") "
                : ""}
              {pricePerUnit
                ? unitOptions.map((option) =>
                    option.value === pricePerUnit ? option.title : ""
                  )
                : "selecteer"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "var(--spacing-xs)",
            }}
          >
            <IconToggle
              text="aanrader?"
              checked={isRecommanded}
              setValue={setIsRecommanded}
              color="var(--neutral-100)"
            />
          </div>
        </div>
        <div className="add-product-column">
          <TextArea
            title="tekst aanrader"
            value={recommandedText}
            setValue={setRecommandedText}
            column
            color="var(--neutral-100)"
          />

          <div className="add-product-select-image-container">
            <label
              htmlFor="image-input"
              className="text-input-label"
              style={{ color: "var(--neutral-100)" }}
            >
              afbeelding
            </label>
            <div className="add-product-image-buttons">
              <figure>
                <img src={image} />
              </figure>
              <input
                type="file"
                onChange={(e) => handleChange(e)}
                id="image-input"
              />
            </div>
          </div>

          <div className="dependencies-container">
            <div
              className="dependencies-container-header"
              onClick={() => setShowDependenciesMenu(!showDependenciesMenu)}
            >
              <div>afhankelijkheden</div>
              <CaretLeft
                color="var(--neutral-100)"
                size={24}
                weight="bold"
                style={{ rotate: showDependenciesMenu ? "z -90deg" : "z 0deg" }}
              />
            </div>
            {showDependenciesMenu && (
              <>
                <div className="dependency-container">
                  <h4 className="dependency-title">lengte afhankelijk (cm)</h4>
                  <div className="dependency-input-container">
                    <div>min:</div>
                    <label htmlFor="min-length-dependant-input">
                      <CurrencyInput
                        id="min-length-dependant-input"
                        name="min-length-dependant-input"
                        placeholder="min. lengte"
                        className="text-input"
                        value={
                          minLengthDepenency === null ? "" : minLengthDepenency
                        }
                        decimalsLimit={0}
                        onValueChange={(value, name, values) =>
                          setMinLengthDependency(
                            values?.float !== null && values !== undefined
                              ? Math.ceil(values.float)
                              : null
                          )
                        }
                        allowNegativeValue={false}
                        maxLength={3}
                      />
                    </label>
                    <div>max:</div>
                    <label htmlFor="max-length-dependant-input">
                      <CurrencyInput
                        id="max-length-dependant-input"
                        name="max-length-dependant-input"
                        placeholder="max. lengte"
                        className="text-input"
                        value={
                          maxLengthDepenency === null ? "" : maxLengthDepenency
                        }
                        decimalsLimit={0}
                        onValueChange={(value, name, values) =>
                          setMaxLengthDependency(
                            values?.float !== null && values !== undefined
                              ? Math.ceil(values.float)
                              : null
                          )
                        }
                        allowNegativeValue={false}
                        maxLength={3}
                      />
                    </label>
                  </div>
                </div>
                <div className="dependency-container">
                  <h4 className="dependency-title">breedte afhankelijk (cm)</h4>
                  <div className="dependency-input-container">
                    <div>min:</div>
                    <label htmlFor="min-width-dependant-input">
                      <CurrencyInput
                        id="min-width-dependant-input"
                        name="min-width-dependant-input"
                        placeholder="min. breedte"
                        className="text-input"
                        value={
                          minWidthDepenency === null ? "" : minWidthDepenency
                        }
                        decimalsLimit={0}
                        onValueChange={(value, name, values) =>
                          setMinWidthDependency(
                            values?.float !== null && values !== undefined
                              ? Math.ceil(values.float)
                              : null
                          )
                        }
                        allowNegativeValue={false}
                        maxLength={3}
                      />
                    </label>
                    <div>max:</div>
                    <label htmlFor="max-width-dependant-input">
                      <CurrencyInput
                        id="max-width-dependant-input"
                        name="max-width-dependant-input"
                        placeholder="max. breedte"
                        className="text-input"
                        value={
                          maxWidthDepenency === null ? "" : maxWidthDepenency
                        }
                        decimalsLimit={0}
                        onValueChange={(value, name, values) =>
                          setMaxWidthDependency(
                            values?.float !== null && values !== undefined
                              ? Math.ceil(values.float)
                              : null
                          )
                        }
                        allowNegativeValue={false}
                        maxLength={3}
                      />
                    </label>
                  </div>
                </div>
                <div className="dependency-container">
                  <h4 className="dependency-title">diepte afhankelijk (cm)</h4>
                  <div className="dependency-input-container">
                    <div>min:</div>
                    <label htmlFor="min-depth-dependant-input">
                      <CurrencyInput
                        id="min-depth-dependant-input"
                        name="min-depth-dependant-input"
                        placeholder="min. diepte"
                        className="text-input"
                        value={
                          minDepthDepenency === null ? "" : minDepthDepenency
                        }
                        decimalsLimit={0}
                        onValueChange={(value, name, values) =>
                          setMinDepthDependency(
                            values?.float !== null && values !== undefined
                              ? Math.ceil(values.float)
                              : null
                          )
                        }
                        allowNegativeValue={false}
                        maxLength={3}
                      />
                    </label>
                    <div>max:</div>
                    <label htmlFor="max-depth-dependant-input">
                      <CurrencyInput
                        id="max-depth-dependant-input"
                        name="max-depth-dependant-input"
                        placeholder="max. diepte"
                        className="text-input"
                        value={
                          maxDepthDepenency === null ? "" : maxDepthDepenency
                        }
                        decimalsLimit={0}
                        onValueChange={(value, name, values) =>
                          setMaxDepthDependency(
                            values?.float !== null && values !== undefined
                              ? Math.ceil(values.float)
                              : null
                          )
                        }
                        allowNegativeValue={false}
                        maxLength={3}
                      />
                    </label>
                  </div>
                </div>
                <div className="dependency-container">
                  <h4 className="dependency-title">
                    volume afhankelijk (liter)
                  </h4>
                  <div className="dependency-input-container">
                    <div>min:</div>
                    <label htmlFor="min-volume-dependant-input">
                      <CurrencyInput
                        id="min-volume-dependant-input"
                        name="min-volume-dependant-input"
                        placeholder="min. volume"
                        className="text-input"
                        value={
                          minVolumeDepenency === null ? "" : minVolumeDepenency
                        }
                        decimalsLimit={0}
                        onValueChange={(value, name, values) =>
                          setMinVolumeDependency(
                            values?.float !== null && values !== undefined
                              ? Math.ceil(values.float)
                              : null
                          )
                        }
                        allowNegativeValue={false}
                        maxLength={3}
                      />
                    </label>
                    <div>max:</div>
                    <label htmlFor="max-volume-dependant-input">
                      <CurrencyInput
                        id="max-volume-dependant-input"
                        name="max-volume-dependant-input"
                        placeholder="max. volume"
                        className="text-input"
                        value={
                          maxVolumeDepenency === null ? "" : maxVolumeDepenency
                        }
                        decimalsLimit={0}
                        onValueChange={(value, name, values) =>
                          setMaxVolumeDependency(
                            values?.float !== null && values !== undefined
                              ? Math.ceil(values.float)
                              : null
                          )
                        }
                        allowNegativeValue={false}
                        maxLength={3}
                      />
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="add-product-buttons-container">
            <SecondaryButton title="annuleren" action={() => {}} />
            <PrimaryButton title="opslaan" action={() => handleSaveItem} />
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddProduct;
