import { AquariumType } from "../@types/aquarium";
import { ImageOption } from "../@types/configurator";
import rimlessImg from "../images/aquariumtypes/rimless.png";
import lightcoverImg from "../images/aquariumtypes/lightcover.png";
import fullglassImg from "../images/aquariumtypes/fullglass.png";
import turtleImg from "../images/aquariumtypes/turtles.png";

export const aquariumTypeOptions: Array<ImageOption> = [
  {
    id: AquariumType.rimless,
    img: rimlessImg,
    title: "Rimless",
  },
  {
    id: AquariumType.lightCover,
    img: lightcoverImg,
    title: "Lichtkap",
  },
  {
    id: AquariumType.fullGlass,
    img: fullglassImg,
    title: "Vol glas",
  },
  {
    id: AquariumType.turtle,
    img: turtleImg,
    title: "Schildpadden",
  },
];
