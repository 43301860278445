import { StepInfo, StepStatus, Steps } from "../@types/configurator";

export const defaultStepInfoList: StepInfo[] = [
  {
    title: Steps.dimensions,
    status: StepStatus.toDo,
    disabled: false,
    opened: false,
  },
  {
    title: Steps.aquariumType,
    status: StepStatus.toDo,
    disabled: false,
    opened: false,
  },
  {
    title: Steps.windowPaint,
    status: StepStatus.toDo,
    disabled: false,
    opened: false,
  },
  {
    title: Steps.filter,
    status: StepStatus.toDo,
    disabled: true,
    opened: false,
  },
  {
    title: Steps.furniture,
    status: StepStatus.toDo,
    disabled: true,
    opened: false,
  },
  {
    title: Steps.technics,
    status: StepStatus.toDo,
    disabled: true,
    opened: false,
  },
];

export const defaultStepItemInfoOverview = {
  dimensions: {
    aquariumDimensions: {
      hasIssue: false,
    },
    sharpenGlass: {
      hasIssue: false,
    },
  },

  aquariumType: {
    waterType: {
      hasIssue: false,
    },
    aquariumType: {
      hasIssue: false,
    },
    aquariumTexture: {
      hasIssue: false,
    },
    coverPanels: {
      hasIssue: false,
    },
    fullGlassCoverPanels: {
      hasIssue: false,
    },
    turtleIsland: {
      hasIssue: false,
    },
    lightCover: {
      hasIssue: false,
    },
  },

  windows: {
    windowPaintColor: {
      hasIssue: false,
    },
    paintWindows: {
      hasIssue: false,
    },
    optiWhiteWindows: {
      hasIssue: false,
    },
  },

  filter: {
    filterType: {
      hasIssue: false,
    },
    filterLocation: {
      hasIssue: false,
    },
    externalDryroom: {
      hasIssue: false,
    },
    bioCoverPanels: {
      hasIssue: false,
    },
    osmoseCompartiment: {
      hasIssue: false,
    },
    boreholes: {
      hasIssue: false,
    },
  },

  furniture: {
    furnitureType: {
      hasIssue: false,
    },
    dimensions: {
      hasIssue: false,
    },
    extraOptions: {
      hasIssue: false,
    },
    doorType: {
      hasIssue: false,
    },
    electraRuimte: {
      hasIssue: false,
    },
    removableSides: {
      hasIssue: false,
    },
  },
};
